import _ from 'lodash';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';

import DragAndDrop from '../../../../components/bulkImport/DragAndDrop';
import ImportCaption from '../../../../components/bulkImport/ImportCaption';
import OneClickButton from '../../../../components/form/button';
import addNotification from '../../../../components/notification';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../../components/panel/panel';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { setJsonContent } from '../../../../app/store/actions/bulkImport';

import OrderTypeConfigHelpers from '../../../../helpers/orderTypeConfig';

import {
  bulkImportConfigs,
  deleteConfig,
  getConfigsList,
  updateStatusConfig,
} from '../../../../app/store/actions/orderTypeConfig';

class OrderTypeConfigList extends React.Component {
  static filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      value={(filter && filter.value) || ''}
      onChange={(e) => onChange(e.target.value)}
      autoComplete="nope"
    />
  );

  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      showModalStatus: false,
      showBulkImport: false,
      orderTypeConfigUpdate: null,
    };

    this.defaultSorted = [
      {
        id: 'orderType',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => {
          const { status } = rows.row;
          return (
            <div style={{ textAlign: 'center' }}>
              <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
              <UncontrolledButtonDropdown>
                <DropdownToggle color="default" caret className="btn-xs" />
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => this.showOrderTypeConfigDetail(rows.row)}
                  >
                    {I18n.t('BEE56' /* Detalhes */)}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.showCopyOrderTypeConfig(rows.row)}
                  >
                    {I18n.t('BEE80' /* Copiar */)}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.showDeleteOrderTypeConfig(rows.row)}
                  >
                    {I18n.t('BEE81' /* Eliminar */)}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.showStatusOrderTypeConfig(rows.row)}
                  >
                    {status === 1 ? I18n.t('BEE2491' /* Desativar */) : I18n.t('BEE914' /* Ativar */)}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          );
        },
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        Cell: (row) => (
          <span>
            <i
              className="fa fa-circle f-s-7 fa-fw m-r-10 m-t-5"
              style={{
                color: row.value === 0 ? '#ff5b57'
                  : '#00acac',
                transition: 'all .3s ease',
              }}
            />
            {' '}
            {row.value || row.value === 0 ? OrderTypeConfigHelpers.statusConfig(row.value) : ''}
          </span>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {OrderTypeConfigHelpers.statusConfig().map((element, index) => (
              <option key={index} value={element.value}>
                {element.label}
              </option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE2997' /* Tipo de Documento */),
        accessor: 'orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: OrderTypeConfigList.filterColumn,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: OrderTypeConfigList.filterColumn,
      }, {
        Header: I18n.t('BEE3531' /* Tipo Movimentação */),
        accessor: 'movementType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{row.value ? OrderTypeConfigHelpers.movementType(row.value) : ''}</span>
          </div>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {OrderTypeConfigHelpers.movementType().map((element, index) => (
              <option key={index} value={element.value}>
                {element.label}
              </option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'depositCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 90,
        Filter: OrderTypeConfigList.filterColumn,
      }, {
        Header: I18n.t('BEE4074' /* Cliente/Fornecedor */),
        accessor: 'customerSupplierCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: OrderTypeConfigList.filterColumn,
      }, {
        Header: I18n.t('BEE301' /* Transportadora */),
        accessor: 'carrierCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: OrderTypeConfigList.filterColumn,
      }, {
        Header: I18n.t('BEE1098' /* Tipo de Entrega */),
        accessor: 'deliveryType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{row ? OrderTypeConfigHelpers.deliveryType(row.value) : ''}</span>
          </div>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {OrderTypeConfigHelpers.deliveryType().map((element, index) => (
              <option key={index} value={element.value}>
                {element.label}
              </option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE4009' /* Grupo de Estoque */),
        accessor: 'stockGroupCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: OrderTypeConfigList.filterColumn,
      }, {
        Header: I18n.t('BEE4073' /* Operação Fiscal */),
        accessor: 'cfop',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        Filter: OrderTypeConfigList.filterColumn,
      },
    ];

    this.csvPreviewTable = [
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        sortable: false,
      }, {
        Header: I18n.t('BEE3531' /* Tipo Movimentação */),
        accessor: 'movementType',
        style: { alignSelf: 'center', textAlign: 'center' },
        sortable: false,
        minWidth: 150,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{row.value ? OrderTypeConfigHelpers.movementType(parseInt(row.value, 10)) : ''}</span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'depositCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 90,
        sortable: false,
      }, {
        Header: I18n.t('BEE4074' /* Cliente/Fornecedor */),
        accessor: 'customerSupplierCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        sortable: false,
      }, {
        Header: I18n.t('BEE301' /* Transportadora */),
        accessor: 'carrierCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        sortable: false,
      }, {
        Header: I18n.t('BEE1098' /* Tipo de Entrega */),
        accessor: 'deliveryType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>
              {
              row.value || row.value === 0
                ? OrderTypeConfigHelpers.deliveryType(parseInt(row.value, 10))
                : ''
              }
            </span>
          </div>
        ),
        sortable: false,
      }, {
        Header: I18n.t('BEE4009' /* Grupo de Estoque */),
        accessor: 'stockGroupCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        sortable: false,
      }, {
        Header: I18n.t('BEE4073' /* Operação Fiscal */),
        accessor: 'cfop',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        sortable: false,
      }, {
        Header: I18n.t('BEE2997' /* Tipo de Documento */),
        accessor: 'orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        sortable: false,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        sortable: false,
        Cell: (row) => (
          <span>
            {(row.value || row.value === 0) && (
            <i
              className="fa fa-circle f-s-7 fa-fw m-r-10 m-t-5"
              style={{
                color: row.value === 0 ? '#ff5b57'
                  : '#00acac',
                transition: 'all .3s ease',
              }}
            />
            )}
            {' '}
            {row.value || row.value === 0 ? OrderTypeConfigHelpers.statusConfig(parseInt(row.value, 10)) : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'error.error',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        sortable: false,
        Cell: (row) => (
          <span>
            {this.renderStatusValue(row.original)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE463' /* Linha */),
        accessor: 'error.line',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        sortable: false,
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'error.message',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
        sortable: false,
      },
    ];

    this.renderStatusValue = (row) => {
      if (row?.error?.error === '') {
        return (
          <span style={{ color: 'green', fontWeight: '700' }}>
            {I18n.t('BEE2523' /* Processado com sucesso */)}
          </span>
        );
      }
      if (row?.error?.error) {
        return <span style={{ color: 'red', fontWeight: '700' }}>{I18n.t('BEE2520' /* Processado com erro */)}</span>;
      }
      if (!row.error) {
        return (
          <span style={{ color: '#F17B1E', fontWeight: '700' }}>
            {I18n.t('BEE2524' /* Aguardando processamento */)}
          </span>
        );
      }
    };
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
    }
    await this.getOrderTypeConfigs();
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      showModalStatus: false,
      orderTypeConfigUpdate: null,
    });
  };

  getOrderTypeConfigs = async () => {
    await this.props.getConfigsList();
  };

  deleteConfig = async (orderTypeConfig) => {
    const result = await this.props.deleteConfig(orderTypeConfig._original.id);
    this.setInitState();
    if (result) {
      addNotification(
        'success',
        I18n.t('BEE4075' /* Eliminar Regra de Tipo de Documento */),
        I18n.t('BEE4076' /* Regra de Tipo de Documento eliminada com sucesso! */),
        'top-right',
      );
    } else {
      addNotification(
        'danger',
        I18n.t('BEE4075' /* Eliminar Regra de Tipo de Documento */),
        result.message,
        'top-right',
      );
    }
    await this.getOrderTypeConfigs();
  };

  updateStatusConfig = async (orderTypeConfig) => {
    const status = orderTypeConfig.status === 1 ? 0 : 1;

    const result = await this.props.updateStatusConfig(orderTypeConfig._original.id, status);
    this.setInitState();

    if (result) {
      addNotification(
        'success',
        I18n.t('BEE4077' /* Status da Regra do Tipo de Documento */),
        status === 1
          ? I18n.t('BEE4078' /* Regra de Tipo de Documento ativada com sucesso! */)
          : I18n.t('BEE4079' /* Regra de Tipo de Documento desativada com sucesso! */),
        'top-right',
      );
    } else {
      addNotification(
        'danger',
        I18n.t('BEE4077' /* Status da Regra do Tipo de Documento */),
        result.message,
        'top-right',
      );
    }
    await this.getOrderTypeConfigs();
  };

  showOrderTypeConfigDetail = (orderTypeConfig) => {
    this.props.history.push({
      pathname: ROUTES.ORDER_TYPE_CONFIG_DETAIL,
      state: {
        orderTypeConfig: orderTypeConfig._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyOrderTypeConfig = (orderTypeConfig) => {
    this.props.history.push({
      pathname: ROUTES.ORDER_TYPE_CONFIG_COPY,
      state: {
        orderTypeConfig: orderTypeConfig._original,
      },
    });
  };

  showDeleteOrderTypeConfig = (orderTypeConfig) => {
    this.setState({ showModalDelete: true, orderTypeConfigUpdate: orderTypeConfig });
  };

  showStatusOrderTypeConfig = (orderTypeConfig) => {
    this.setState({ showModalStatus: true, orderTypeConfigUpdate: orderTypeConfig });
  };

  validProcessFile = async () => {
    if (this.props.jsonCsv === null) {
      addNotification(
        'danger',
        I18n.t('BEE2509' /* Importação em massa */),
        I18n.t('BEE2511' /* Nenhum arquivo selecionado */),
        'top-right',
      );
    } else await this.processFile();
  };

  processFile = async () => {
    const { jsonCsv } = this.props;
    const orderTypeConfigErrors = await this.props.generateBulkImport(jsonCsv);
    await this.props.setJsonContent(orderTypeConfigErrors);
  };

  cancelBulkImport = () => {
    this.setState({ showBulkImport: false });
    this.props.setJsonContent(null);
  };

  render() {
    const {
      orderTypeConfigUpdate, showModalDelete, showModalStatus, page, showBulkImport,
    } = this.state;
    const { orderTypeConfigsList = [], jsonCsv = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE4069' /* Configurador de Tipos de Documentos */)}</li>
          </ol>
        </div>
        {
          showBulkImport ? (
            <>
              <h1 className="page-header mb-0">{I18n.t('BEE4080' /* Novas Regras em Lote */)}</h1>
              <div className="slideUpTransition">
                <div className="d-flex align-items-center mb-md-3 mb-2" />
                <div className="row">
                  <div className="col-xl-12">
                    <Panel>
                      <PanelHeader noButton />
                      <PanelBody>
                        <div className="row">
                          <div
                            style={{
                              justifyContent: 'space-evenly',
                              display: 'flex',
                              flex: 1,
                            }}
                          >
                            <ImportCaption
                              headers={[
                                { label: 'movementType', key: 'movementType' }, // 1 - Entrada / 2 - Saída
                                { label: 'branchCode', key: 'branchCode' },
                                { label: 'depositCode', key: 'depositCode' },
                                { label: 'customerSupplierCode', key: 'customerSupplierCode' },
                                { label: 'carrierCode', key: 'carrierCode' },
                                { label: 'deliveryType', key: 'deliveryType' }, // 0 - Ambos / 1 - CIF / 2 - FOB
                                { label: 'stockGroupCode', key: 'stockGroupCode' },
                                { label: 'cfop', key: 'cfop' },
                                { label: 'orderType', key: 'orderType' },
                                { label: 'status', key: 'status' }, // 0 - Desativado / 1 - Ativado
                                { label: 'note', key: 'note' },
                              ]}
                              data={[
                                {
                                  movementType: 1,
                                  branchCode: '00001',
                                  depositCode: 'DP1',
                                  customerSupplierCode: '100',
                                  carrierCode: '99',
                                  deliveryType: 1,
                                  stockGroupCode: '01',
                                  cfop: '5102',
                                  orderType: 'NFE',
                                  status: 1,
                                  note: 'Teste Entrada',
                                },
                                {
                                  movementType: 2,
                                  branchCode: '00001',
                                  depositCode: 'DP1',
                                  customerSupplierCode: '*',
                                  carrierCode: '*',
                                  deliveryType: 0,
                                  stockGroupCode: '*',
                                  cfop: '*',
                                  orderType: 'NFS',
                                  status: 1,
                                  note: 'Teste Saida',
                                },
                              ]}
                              filename="import_regra_tipo_docto.csv"
                              legend={[
                                {
                                  nameField: 'movementType',
                                  // eslint-disable-next-line max-len
                                  observationField: I18n.t('BEE4081' /* Tipo de Movimentação (1 - Entrada / 2 - Saída) */),
                                }, {
                                  nameField: 'branchCode',
                                  observationField: I18n.t('BEE1994' /* Código Filial */),
                                }, {
                                  nameField: 'depositCode',
                                  observationField: I18n.t('BEE431' /* Código do Depósito */),
                                }, {
                                  nameField: 'customerSupplierCode',
                                  // eslint-disable-next-line max-len
                                  observationField: I18n.t('BEE4082' /* Código do Cliente/Fornecedor (Informar * para todos) */),
                                }, {
                                  nameField: 'carrierCode',
                                  // eslint-disable-next-line max-len
                                  observationField: I18n.t('BEE4083' /* Código da Transportadora (Informe * para todos) */),
                                }, {
                                  nameField: 'deliveryType',
                                  // eslint-disable-next-line max-len
                                  observationField: I18n.t('BEE4084' /* Tipo de Entrega (0 - Ambos / 1 - CIF / 2 - FOB) */),
                                }, {
                                  nameField: 'stockGroupCode',
                                  observationField: I18n.t('BEE4085' /* Grupo Estoque (Informar * para todos). */),
                                }, {
                                  nameField: 'cfop',
                                  observationField: I18n.t('BEE4086' /* Operação Fiscal (Informar * para todos). */),
                                }, {
                                  nameField: 'orderType',
                                  observationField: I18n.t('BEE2997' /* Tipo de Documento */),
                                }, {
                                  nameField: 'status',
                                  observationField: I18n.t('BEE4087' /* Status (0 - Desativado / 1 - Ativado) */),
                                }, {
                                  nameField: 'note',
                                  observationField: I18n.t('BEE135' /* Observação */),
                                },
                              ]}
                            />
                            <DragAndDrop />
                          </div>
                        </div>
                      </PanelBody>
                      <PanelFooter className="text-right">
                        <OneClickButton
                          type="submit"
                          className="btn btn-white m-5"
                          style={{ paddingRight: '25px', paddingLeft: '25px' }}
                          onClick={this.cancelBulkImport}
                        >
                          {I18n.t('BEE99' /* Cancelar */)}
                        </OneClickButton>
                        <OneClickButton
                          type="submit"
                          style={{ paddingRight: '25px', paddingLeft: '25px' }}
                          className="btn btn-primary m-5"
                          onClick={() => this.validProcessFile()}
                        >
                          {I18n.t('BEE2496' /* Processar Arquivo */)}
                        </OneClickButton>
                      </PanelFooter>
                    </Panel>
                  </div>
                </div>
              </div>
              <div>
                <Table
                  downloadCSV
                  filterable
                  data={jsonCsv}
                  columns={this.csvPreviewTable}
                  expander
                  defaultPageSize={10}
                  defaultSorted={this.defaultSorted}
                  page={(page) || ((!this.state.firstRun
                     && this.props.location.state && this.props.location.state.dice
                     && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
                  onPageChange={(Page) => ((Page >= 0) ? this.setState({ page: Page }) : this.setState({ page: 0 }))}
                  defaultFilterMethod={(filter, row) => {
                    const input = _.lowerCase(filter.value);
                    const value = _.lowerCase(row[filter.id]);
                    if (_.includes(value, input)) {
                      return true;
                    }
                  }}
                />
              </div>
            </>
          ) : (
            <Table
              downloadCSV
              wikiHelp={ROUTES.ORDER_TYPE_CONFIG_HELP}
              headerTitle={I18n.t('BEE4088' /* Regras para Tipos de Documento */)}
              actionButtons={(
                <div className="ml-auto d-flex">
                  <div>
                    <button
                      type="button"
                      className="btn btn-success btn-sm btn-rounded pl-2"
                      onClick={() => this.setState({ showBulkImport: true })}
                    >
                      <i className="fa fa-link mr-1" />
                      {I18n.t('BEE4089' /* Criar novas regras em lote */)}
                    </button>
                  </div>
                  <Link
                    to={ROUTES.ORDER_TYPE_CONFIG_CREATE}
                    className="btn btn-success btn-sm btn-rounded pl-2 pr-3 ml-1"
                  >
                    <i className="fa fa-plus mr-1" />
                    {' '}
                    {I18n.t('BEE4090' /* Incluir Nova Regra */)}
                  </Link>
                </div>
          )}
              panelHeaderProps={{
                onClickReload: this.getOrderTypeConfigs,
                children: I18n.t('BEE4091' /* Regras */),
              }}
              filterable
              data={orderTypeConfigsList}
              columns={this.tableColumns}
              expander
              defaultPageSize={10}
              defaultSorted={this.defaultSorted}
              page={(page) || (
                (!this.state.firstRun && this.props.location.state
           && this.props.location.state.dice && this.props.location.state.dice.page)
                  ? this.props.location.state.dice.page : page)}
              onPageChange={(Page) => ((Page >= 0)
                ? this.setState({ page: Page }) : this.setState({ page: 0 }))}
              defaultFilterMethod={(filter, row) => {
                const input = filter && filter.value !== '*' ? _.lowerCase(filter.value) : filter.value;
                const value = row[filter.id] !== '*' ? _.lowerCase(row[filter.id]) : row[filter.id];
                if (_.includes(value, input)) {
                  return true;
                }
              }}
            />
          )
        }
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteConfig(orderTypeConfigUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE4092' /* A regra de tipo de documento será eliminada! */)}
          </SweetAlert>
          )
        )}
        {(showModalStatus
          && (
          <SweetAlert
            success={orderTypeConfigUpdate.status === 0}
            danger={orderTypeConfigUpdate.status === 1}
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle={orderTypeConfigUpdate.status === 1 ? 'danger' : 'success'}
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.updateStatusConfig(orderTypeConfigUpdate)}
            onCancel={() => this.setInitState()}
          >
            {orderTypeConfigUpdate.status === 1
              ? I18n.t('BEE4093' /* A regra de tipo de documento será desativada! */)
              : I18n.t('BEE4094' /* A regra de tipo de documento será ativada! */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orderTypeConfigsList: state.orderTypeConfig && state.orderTypeConfig.configsList,
  jsonCsv: state.bulkImport.jsonCsv || [],
  fileCsv: state.bulkImport.fileCsv,
});

const mapDispatchToProps = (dispatch) => ({
  getConfigsList: () => dispatch(getConfigsList()),
  deleteConfig: (configId) => dispatch(deleteConfig(configId)),
  updateStatusConfig: (configId, status) => dispatch(updateStatusConfig(configId, status)),
  generateBulkImport: (jsonCsv) => dispatch(bulkImportConfigs(jsonCsv)),
  setJsonContent: (jsonCsv) => dispatch(setJsonContent(jsonCsv)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderTypeConfigList));

import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { I18n } from 'react-redux-i18n';
import 'react-table/react-table.css';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,

  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import OneClickButton from '../../../components/form/button';

import Permissions from '../../../app/services/permissions';
import addNotification from '../../../components/notification';
import UserConfirmationModal from '../../../components/pages/userConfirmationModal';
import Table from '../../../components/table/Table';
import ROUTES from '../../../config/routes';
import FormatHelpers from '../../../helpers/format';
import InboundOrderHelpers from '../../../helpers/inboundOrders';
import ProductsHelpers from '../../../helpers/products';
import BaptismPartialConference from './baptismPartialConference';

import Form from '../../../components/form/form';

export default class ProductTable extends React.Component {
  constructor(props) {
    super(props);

    this.tableProductsColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">
              {I18n.t('BEE55' /* Ações */)}
            </button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                {(!!rows.original.productPartial) && (
                  <DropdownItem
                    onClick={() => this.setModalPartialBaptism()}
                  >
                    {I18n.t('BEE2312' /* Batismo Parcial */)}
                  </DropdownItem>
                )}
                {((rows.original.status < 7)
                  && (
                    <DropdownItem
                      onClick={() => this.modalNoteOpening(rows.row)}
                    >
                      {I18n.t('BEE135' /* Observação */)}
                    </DropdownItem>
                  )
                )}
                {(!rows.original.integrated && (rows.original.status === 3 || rows.original.status === 4)
                  && (
                    <DropdownItem
                      onClick={() => this.syncStorage(rows.row._original)}
                    >
                      {I18n.t('BEE3205' /* Integrar ERP */)}
                    </DropdownItem>
                  )
                )}
                {(!Permissions.checkPermission(ROUTES.P_INSPECT_INBOUND_ORDER_PRODUCT)
                  && (rows.original.status !== 9)
                  && (rows.original.inspection)
                  && (rows.original.inspected === 0)
                  && (
                    <DropdownItem
                      onClick={() => this.setInspectProductModal(rows.row)}
                    >
                      {I18n.t('BEE4124' /* Inspecionar */)}
                    </DropdownItem>
                  )
                )}
                {(!Permissions.checkPermission(ROUTES.P_UNDO_INBOUND_CONFERENCE)
                  && ((rows.original.status === 2) || (rows.original.status === 1 && rows.original.quantityCheck > 0))
                  && (
                    <>
                      <DropdownItem divider />
                      <DropdownItem
                        onClick={() => this.undoInboundProductCheck(rows.row)}
                      >
                        {I18n.t('BEE565' /* Desfazer Conferência Física */)}
                      </DropdownItem>
                    </>
                  )
                )}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      },
      {
        Header: I18n.t('BEE463' /* Linha */),
        accessor: 'lineNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'productName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 400,
      }, {
        Header: I18n.t('BEE384' /* Unidade */),
        accessor: 'unitMeasure',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE425' /* Batismo */),
        accessor: 'baptismLabel',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE441' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE464' /* Conferida */),
        accessor: 'quantityCheck',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE4148' /* Status Inspeção */),
        accessor: 'inspected',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>
            {(() => {
              if (row.original.inspection) {
                if (row.value === 0) {
                  return I18n.t('BEE470' /* Pendente */);
                } if (row.value === 1) {
                  return I18n.t('BEE4128' /* Rejeitado */);
                } if (row.value === 2) {
                  return I18n.t('BEE2119' /* Aprovado */);
                }
                return null;
              }
              return I18n.t('BEE4169' /* Não inspeciona */);
            })()}
          </span>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            <option value="0">{I18n.t('BEE470' /* Pendente */)}</option>
            <option value="1">{I18n.t('BEE4128' /* Rejeitado */)}</option>
            <option value="2">{I18n.t('BEE2119' /* Aprovado */)}</option>
          </select>
        ),
      }, {
        Header: I18n.t('BEE4171' /* Data/Hora Inspeção */),
        accessor: 'inspectedAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE4172' /* Usuário Inspeção */),
        accessor: 'inspectionUser',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE4173' /* Obs. Inspeção */),
        accessor: 'inspectionNotes',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE3701' /* Pend. Armazenar */),
        accessor: 'pending',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE3196' /* Armazenada */),
        accessor: 'quantityStorage',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE3702' /* Avariada */),
        accessor: 'quantityDamage',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE176' /* Divergência */),
        accessor: 'quantityDivergence',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{InboundOrderHelpers.inboundOrderProductStatus(row.value)}</span>
          </div>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option
              value=""
            >
              {I18n.t('BEE793' /* Todos */)}
            </option>
            {InboundOrderHelpers.inboundOrderProductStatus().map((element) => (
              <option
                key={element.value}
                value={element.value}
              >
                {element.label}
              </option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE3789' /* Ordem de Compra */),
        accessor: 'purchaseOrder',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 145,
      }, {
        Header: I18n.t('BEE701' /* Controlar Vencimento */),
        accessor: 'controlExpirationDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{(row.value) ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}</span>
          </div>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            <option value>{I18n.t('BEE172' /* Sim */)}</option>
            <option value={false}>{I18n.t('BEE173' /* Não */)}</option>
          </select>
        ),
      }, {
        Header: I18n.t('BEE708' /* Tipo de Controle de Estoque */),
        accessor: 'stockControlType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{ProductsHelpers.stockControlType(row.value)}</span>
          </div>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {ProductsHelpers.stockControlType().map((element) => (
              <option key={element.value} value={element.value}>{element.label}</option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE466' /* Usuário Conferência */),
        accessor: 'checkUserName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE467' /* Data Conferência */),
        accessor: 'checkProductAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE468' /* Usuário Armazenagem */),
        accessor: 'storageUserName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE469' /* Data Armazenagem */),
        accessor: 'storageProductAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
      },
    ];

    this.state = {
      showUndoCheckModal: false,
      selectedInboundOrderProductId: null,
      showModalNote: false,
      noteIndex: '',
      note: '',
      openModal: false,
      openModalPartialBaptism: false,
      listInboundOrderProductPartial: [],
      modalInspectProduct: false,
      inspectionNotes: null,
      inboundOrderProductId: null,
    };
  }

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  createTextArea = (value, attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group p-2 mt-4">
      <p>{label}</p>
      <div className="col">
        <textarea
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  getListInboundOrderProductPartial = async () => {
    const listInboundOrderProductPartial = await this.props.getListInboundOrderProductPartial();
    this.setState({ listInboundOrderProductPartial: [...listInboundOrderProductPartial] });
  };

  setModalPartialBaptism = async () => {
    const { openModalPartialBaptism } = this.state;

    if (!openModalPartialBaptism) await this.getListInboundOrderProductPartial();
    else { this.setState({ listInboundOrderProductPartial: [...[]] }); }

    this.setState({ openModalPartialBaptism: !openModalPartialBaptism });
  };

  syncStorage = async (inboundOrderProduct) => {
    try {
      const result = await this.props.syncStorage(inboundOrderProduct.id);

      if (result && !result.length) {
        addNotification(
          'danger',
          I18n.t('BEE440' /* Monitor de Recebimento */),
          I18n.t('BEE3236' /* Erro de Integração ERP */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE440' /* Monitor de Recebimento */),
          I18n.t('BEE3235' /* Integração realizada com sucesso! */),
          'top-right',
        );
        await this.props.getInboundOrders();
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE440' /* Monitor de Recebimento */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE440' /* Monitor de Recebimento */),
            I18n.t('BEE3236' /* Erro de Integração ERP */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE440' /* Monitor de Recebimento */),
          I18n.t('BEE3236' /* Erro de Integração ERP */),
          'top-right',
        );
      }
    }
  };

  modalNoteOpening = (row) => {
    this.setState({
      showModalNote: true,
      noteIndex: row._index,
      note: this.props.inboundOrdersProductsPanel[row._index].note,
    });
  };

  modalNoteClosing = () => {
    this.setState({
      showModalNote: false,
      noteIndex: '',
      note: '',
    });
  };

  updateNoteField = async () => {
    const { note, noteIndex } = this.state;
    const { inboundOrdersProductsPanel } = this.props;
    if (note && note === inboundOrdersProductsPanel[noteIndex].note) {
      this.modalNoteClosing();
    } else {
      try {
        const result = await this.props.updateNote(inboundOrdersProductsPanel[noteIndex].id, note);
        if (result && !result.length) {
          addNotification(
            'danger',
            I18n.t('BEE440' /* Monitor de Recebimento */),
            I18n.t('BEE1788' /* erro ao atualizar a observação */),
            'top-right',
          );
          this.modalNoteClosing();
        } else {
          addNotification(
            'success',
            I18n.t('BEE440' /* Monitor de Recebimento */),
            I18n.t('BEE1789' /* Atualizado com sucesso! */),
            'top-right',
          );
          this.modalNoteClosing();
          await this.props.getInboundOrders();
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          const { error } = err.response.data;

          if (error.details || error.message) {
            addNotification(
              'danger',
              I18n.t('BEE440' /* Monitor de Recebimento */),
              `${error.code} - ${error.details || error.message}`,
              'top-right',
            );
          } else {
            addNotification(
              'danger',
              I18n.t('BEE440' /* Monitor de Recebimento */),
              I18n.t('BEE1788' /* erro ao atualizar a observação */),
              'top-right',
            );
          }
        } else {
          addNotification(
            'danger',
            I18n.t('BEE440' /* Monitor de Recebimento */),
            I18n.t('BEE1788' /* erro ao atualizar a observação */),
            'top-right',
          );
        }
        this.modalNoteClosing();
      }
    }
  };

  undoInboundProductCheck = (row) => {
    const inboundOrderProductId = row._original.id;
    this.setState({
      showUndoCheckModal: true,
      selectedInboundOrderProductId: inboundOrderProductId,
    });
  };

  confirmUndoInboundProductCheck = () => {
    this.setState({
      showUndoCheckModal: false,
      openModal: true,
    });
  };

  cancelUndoInboundProductCheck = () => {
    this.setState({
      showUndoCheckModal: false,
      selectedInboundOrderProductId: null,
    });
  };

  userConfirmation = (confirm) => {
    const { selectedInboundOrderProductId } = this.state;
    if (confirm) {
      this.props.undoInboundOrderProductCheck(selectedInboundOrderProductId);
      this.setState({
        openModal: false,
        selectedInboundOrderProductId: null,
      });
    } else {
      this.setState({
        openModal: false,
        selectedInboundOrderProductId: null,
      });
    }
  };

  setInspectProductModal = async (row) => {
    const { modalInspectProduct } = this.state;

    if (modalInspectProduct) {
      this.setState({
        modalInspectProduct: false,
        inboundOrderProductId: null,
        inspectionNotes: '',
      });
    } else {
      this.setState({
        modalInspectProduct: true,
        inboundOrderProductId: row._original.id,
      });
    }
  };

  completeInspection = async (value) => {
    const { inspectionNotes, inboundOrderProductId } = this.state;

    try {
      const result = await this.props.updateProductInspection(inboundOrderProductId, value, inspectionNotes);
      if (result && !result.length) {
        addNotification(
          'danger',
          I18n.t('BEE440' /* Monitor de Recebimento */),
          I18n.t('BEE4130' /* Erro ao efetuar a inspeção */),
          'top-right',
        );
        this.modalNoteClosing();
      } else {
        addNotification(
          'success',
          I18n.t('BEE440' /* Monitor de Recebimento */),
          I18n.t('BEE4131' /* Produto inspecionado com sucesso! */),
          'top-right',
        );
        this.setInspectProductModal();
        await this.props.getInboundOrders();
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE440' /* Monitor de Recebimento */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE440' /* Monitor de Recebimento */),
            I18n.t('BEE4130' /* Erro ao efetuar a inspeção */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE440' /* Monitor de Recebimento */),
          I18n.t('BEE4130' /* Erro ao efetuar a inspeção */),
          'top-right',
        );
      }
      this.setInspectProductModal();
    }
  };

  render() {
    const { inboundOrdersProductsPanel = [], productSelect } = this.props;
    const { showUndoCheckModal, modalInspectProduct, inspectionNotes } = this.state;

    return (
      <>

        <Table
          panelHeaderProps={{
            noButton: true,
            children: I18n.t('BEE27' /* Produtos */).toUpperCase(),
          }}
          filterable
          data={inboundOrdersProductsPanel}
          columns={this.tableProductsColumns}
          expander
          defaultPageSize={5}
          pageSizeOptions={[5, 10]}
          defaultSorted={[{ id: 'lineNumber', desc: false }]}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: () => {
                  this.props.setInboundOrderProductSelect(rowInfo.original);
                },
                style: {
                  background: productSelect && rowInfo.original.id === productSelect.id ? 'silver' : 'white',
                  cursor: 'pointer',
                },
              };
            }
            return {};
          }}
        />
        <UserConfirmationModal
          openModal={this.state.openModal}
          confirm={(confirm) => this.userConfirmation(confirm)}
        />
        {(this.state.showModalNote
          && (
            <SweetAlert
              showCancel
              confirmBtnText={I18n.t('BEE493' /* Salvar */)}
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="danger"
              title={I18n.t('BEE135' /* Observação */)}
              onConfirm={() => this.updateNoteField()}
              onCancel={() => this.modalNoteClosing()}
              btnSize="sm"
            >
              <div>
                {this.createTextArea(this.state.note, 'note', I18n.t('BEE135' /* Observação */), '', 3, true, false)}
              </div>
            </SweetAlert>
          ))}
        {(showUndoCheckModal
          && (
            <SweetAlert
              danger
              showCancel
              cancelBtnText={I18n.t('BEE99')}
              confirmBtnText={I18n.t('BEE100')}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101')}
              onConfirm={() => this.confirmUndoInboundProductCheck()}
              onCancel={() => this.cancelUndoInboundProductCheck()}
            />
          )
        )}

        <BaptismPartialConference
          openModalPartialBaptism={this.state.openModalPartialBaptism}
          listInboundOrderProductPartial={this.state.listInboundOrderProductPartial}
          setModalPartialBaptism={this.setModalPartialBaptism}
        />

        <Modal size="md" isOpen={modalInspectProduct} toggle={() => this.setInspectProductModal()}>
          <ModalHeader
            toggle={() => this.setInspectProductModal()}
          >
            {I18n.t('BEE4123' /* Inspeção de Produtos */)}
          </ModalHeader>
          <ModalBody>
            <Form
              noPanel
              setValue={this.setValue}
              inputs={(formContext) => ([
                formContext.createTextArea(
                  inspectionNotes,
                  'inspectionNotes',
                  `${I18n.t('BEE135' /* Observação */)}:`,
                  '',
                  5,
                  false,
                  false,
                  null,
                  ['form-group row m-b-15', 'col-form-label col-md-2', 'col-md-9'],
                ),
              ])}
            />
          </ModalBody>
          <ModalFooter>
            <div className="row">
              <div className="col text-right">
                <OneClickButton
                  className="btn btn-white btn-120 p-5 m-5"
                  onClick={() => this.setInspectProductModal()}
                >
                  {I18n.t('BEE99' /* Cancelar */)}
                </OneClickButton>
                <OneClickButton
                  className="btn btn-danger btn-120 p-5 m-5"
                  onClick={() => this.completeInspection('rejected')}
                >
                  {I18n.t('BEE4127' /* Rejeitar */)}
                </OneClickButton>
                <OneClickButton
                  className="btn btn-success btn-120 p-5 m-5"
                  onClick={() => this.completeInspection('approved')}
                >
                  {I18n.t('BEE4126' /* Aprovar */)}
                </OneClickButton>
              </div>
            </div>
          </ModalFooter>
        </Modal>

      </>
    );
  }
}

/* eslint-disable no-restricted-syntax */
import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import OneClickButton from '../../../components/form/button';
import { getListIntegrationQueue, resyncQueue, cancelInboundOrder } from '../../../app/store/actions/integration';
import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';
import ModalIntegrationInformation from './modalIntegrationInformation';

import IntegrationHelpers from '../../../helpers/integration';

class IntegrationMonitorList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dataSend: '',
      responseData: '',
      openInformationModal: false,
      showModalInformationQuantity: false,
      showCancelOrderModal: false,
      selectedLine: null,
      selectedIntegrations: {},
      selectedIds: [],
      defaultPageSize: 10,
      listIntegrationQueue: [],
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
      pageName: this.props.isBeeStock
        ? I18n.t('BEE2925' /* Monitor de Integração BeeStock */)
        : I18n.t('BEE2926' /* Monitor de Integração ERP */),
    };

    this.defaultSorted = [{
      id: 'creationDate',
      desc: true,
    }];

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE2222' /* Integrações */) },
      { value: this.state.pageName, active: true },
    ];

    this.tableColumns = [{
      Header: I18n.t('BEE436' /* Selecionar */),
      accessor: 'action',
      style: { overflow: 'visible', alignSelf: 'center' },
      filterable: false,
      sortable: false,
      width: 100,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          {(row.original.status !== 200) && (
            <input
              type="checkbox"
              checked={this.state.selectedIntegrations[row.original.idIntegrationQueue] || false}
              onChange={() => this.singleSelection(row.original)}
            />
          )}
        </div>
      ),
    },
    ...(this.props.isBeeStock ? [] : [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        minWidth: 90,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            {(row.original.status !== 200 && row.original.integrationTable === 'inboundOrder') && (
              <button
                type="button"
                className="btn btn-primary btn-xs"
                onClick={() => this.showCancelModal(row.original)}
              >
                <span className="d-flex align-items-center text-start">
                  {I18n.t('BEE99' /* Cancelar */)}
                </span>
              </button>
            )}
          </div>
        ),
      },
    ]), {
      Header: I18n.t('BEE56' /* Detalhes */),
      accessor: 'action',
      style: { overflow: 'visible', alignSelf: 'center' },
      filterable: false,
      sortable: false,
      minWidth: 90,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <button
            type="button"
            className="btn btn-primary btn-xs"
            onClick={() => this.selectRegistryInformation(row.original)}
          >
            <span className="d-flex align-items-center text-start">
              {I18n.t('BEE56' /* Detalhes */)}
            </span>
          </button>
        </div>
      ),
    }, {
      Header: I18n.t('BEE513' /* Integração */),
      accessor: 'integrationTable',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 200,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <span>
            {this.props.isBeeStock
              ? IntegrationHelpers.integrationCode(row.value)
              : IntegrationHelpers.integrationErpCode(row.value)}
          </span>
        </div>
      ),
      filterMethod: (filter, row) => {
        if (filter.value === row.integrationTable) {
          return true;
        }
      },
      Filter: ({ onChange }) => (
        <select
          type="text"
          style={{ width: '100%' }}
          onChange={(event) => { onChange(event.target.value); }}
        >
          <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
          {this.props.isBeeStock ? IntegrationHelpers.integrationCode().map((elem, index) => (
            <option value={elem.value} key={index}>{elem.label}</option>
          )) : IntegrationHelpers.integrationErpCode().map((elem, index) => (
            <option value={elem.value} key={index}>{elem.label}</option>
          ))}
        </select>
      ),
    }, {
      Header: I18n.t('BEE145' /* Filial */),
      accessor: 'branchCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE227' /* Chave */),
      accessor: 'tableKey',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 250,
    }, {
      Header: I18n.t('BEE578' /* Data Criação */),
      accessor: 'creationDate',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
      filterMethod: (filter, row) => {
        const input = _.lowerCase(filter.value);
        const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
        const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
        if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
          return true;
        }
      },
      Cell: (row) => (
        <div>
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        </div>
      ),
    }, {
      Header: I18n.t('BEE2224' /* Data Integração */),
      accessor: 'integrationDate',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
      filterMethod: (filter, row) => {
        const input = _.lowerCase(filter.value);
        const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
        const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
        if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
          return true;
        }
      },
      Cell: (row) => (
        <div>
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        </div>
      ),
    }, {
      Header: I18n.t('BEE224' /* Status */),
      accessor: 'status',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE2761' /* Mensagem */),
      accessor: 'message',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 250,
    }];
  }

  componentDidMount() {
    this.getListIntegrationQueue();
  }

  selectRegistryInformation = (selectedLine) => {
    this.setState({
      dataSend: selectedLine.body,
      responseData: selectedLine.response,
      openInformationModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      openInformationModal: false,
      responseData: '',
      dataSend: '',
    });
  };

  showCancelModal = (selectedLine) => {
    this.setState({
      showCancelOrderModal: true,
      selectedLine,
    });
  };

  singleSelection = (selection) => {
    const { selectedIntegrations } = this.state;
    selectedIntegrations[selection.idIntegrationQueue] = !selectedIntegrations[selection.idIntegrationQueue];
    this.setState({ selectedIntegrations: { ...selectedIntegrations } });
  };

  multipleSelection = (selectionOption) => {
    const { selectedIntegrations } = this.state;
    const data = this.reactTable.getResolvedState().sortedData;
    if (data && data.length > 0) {
      for (const element of data) {
        if (element.status !== 200 && selectedIntegrations[element._original.idIntegrationQueue] !== selectionOption) {
          selectedIntegrations[element._original.idIntegrationQueue] = selectionOption;
        }
      }
      this.setState({ selectedIntegrations: { ...selectedIntegrations } });
    }
  };

  validChecked = () => {
    const { selectedIntegrations } = this.state;
    if (!Object.keys(selectedIntegrations).length) {
      addNotification(
        'danger',
        I18n.t('BEE2223' /* Monitor de Integração */),
        I18n.t('BEE2236' /* Nenhuma integração selecionada */),
        'top-right',
      );
    } else {
      const selectedIds = [];
      for (const index in selectedIntegrations) {
        if (selectedIntegrations[index]) selectedIds.push(index);
      }
      if (selectedIds.length) {
        this.setState({
          selectedIds: [...selectedIds],
          showModalInformationQuantity: true,
        });
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2223' /* Monitor de Integração */),
          I18n.t('BEE2236' /* Nenhuma integração selecionada */),
          'top-right',
        );
      }
    }
  };

  getListIntegrationQueue = async () => {
    const { startDate, endDate } = this.state;
    const { isBeeStock } = this.props;

    this.setState({
      selectedIds: [],
      selectedIntegrations: {},
    });

    try {
      const listIntegrationQueue = await this.props.getListIntegrationQueue(
        moment(startDate).format(),
        moment(endDate).format(),
        isBeeStock,
      );
      if (listIntegrationQueue.length) this.setState({ listIntegrationQueue: [...listIntegrationQueue] });
      else this.setState({ listIntegrationQueue: [] });
    } catch (err) {
      this.setState({ listIntegrationQueue: [] });
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2223' /* Monitor de Integração */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2223' /* Monitor de Integração */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  handleDateApplyEvent = async (event) => {
    await this.setState({
      startDate: event[0],
      endDate: event[1],
    });
    this.getListIntegrationQueue();
  };

  confirmCancelInboundOrder = async () => {
    const { selectedLine } = this.state;

    try {
      this.setState({
        showCancelOrderModal: false,
      });

      const result = await this.props.cancelInboundOrder(selectedLine.idIntegrationQueue);

      if (result && result.success === false) {
        await addNotification(
          'danger',
          I18n.t('BEE2223' /* Monitor de Integração */),
          this.props.isBeeStock
            ? I18n.t('BEE2762' /* Erro ao reenviar fila de integração! */)
            : I18n.t('BEE4144' /* Erro ao cancelar a integração */),
          'top-right',
        );
      } else {
        await addNotification(
          'success',
          I18n.t('BEE2223' /* Monitor de Integração */),
          this.props.isBeeStock
            ? I18n.t('BEE2763' /* Integração reenviada com sucesso! */)
            : I18n.t('BEE4142' /* Integração cancelada com sucesso! */),
          'top-right',
        );

        this.setState({
          selectedLine: null,
        });

        this.getListIntegrationQueue();
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE2223' /* Monitor de Integração */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE2223' /* Monitor de Integração */),
            this.props.isBeeStock
              ? I18n.t('BEE2762' /* Erro ao reenviar fila de integração! */)
              : I18n.t('BEE4144' /* Erro ao cancelar a integração */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE2223' /* Monitor de Integração */),
          this.props.isBeeStock
            ? I18n.t('BEE2762' /* Erro ao reenviar fila de integração! */)
            : I18n.t('BEE4144' /* Erro ao cancelar a integração */),
          'top-right',
        );
      }
      this.getListIntegrationQueue();
    }
  };

  resyncSubmit = async () => {
    const { selectedIds } = this.state;
    const { isBeeStock } = this.props;

    try {
      this.setState({
        showModalInformationQuantity: false,
      });

      if (selectedIds.length > 0) {
        const result = await this.props.resyncQueue(selectedIds, isBeeStock);

        if (result && result.success === false) {
          await addNotification(
            'danger',
            I18n.t('BEE2223' /* Monitor de Integração */),
            this.props.isBeeStock
              ? I18n.t('BEE2762' /* Erro ao reenviar fila de integração! */)
              : I18n.t('BEE4137' /* Erro ao reprocessar integração! */),
            'top-right',
          );
        } else {
          await addNotification(
            'success',
            I18n.t('BEE2223' /* Monitor de Integração */),
            this.props.isBeeStock
              ? I18n.t('BEE2763' /* Integração reenviada com sucesso! */)
              : I18n.t('BEE4138' /* Integração reprocessada com sucesso! */),
            'top-right',
          );

          this.setState({
            selectedIds: [],
            selectedIntegrations: {},
          });

          this.getListIntegrationQueue();
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE2223' /* Monitor de Integração */),
          I18n.t('BEE2236' /* Nenhuma integração selecionada */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE2223' /* Monitor de Integração */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE2223' /* Monitor de Integração */),
            this.props.isBeeStock
              ? I18n.t('BEE2762' /* Erro ao reenviar fila de integração! */)
              : I18n.t('BEE4137' /* Erro ao reprocessar integração! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE2223' /* Monitor de Integração */),
          this.props.isBeeStock
            ? I18n.t('BEE2762' /* Erro ao reenviar fila de integração! */)
            : I18n.t('BEE4137' /* Erro ao reprocessar integração! */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      selectedIds, defaultPageSize, listIntegrationQueue, showModalInformationQuantity, showCancelOrderModal, pageName,
    } = this.state;

    return (
      <>
        <Table
          expander
          filterable
          downloadCSV
          downButtonPosition
          data={listIntegrationQueue}
          columns={this.tableColumns}
          defaultSorted={this.defaultSorted}
          defaultPageSize={defaultPageSize}
          breadcrumb={this.breadcrumb}
          headerTitle={pageName}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          ref={(r) => {
            this.reactTable = r && r.reactTable;
          }}
          datePicker={{
            handleDateApplyEvent: this.handleDateApplyEvent,
          }}
          panelHeaderProps={{
            onClickReload: this.getListIntegrationQueue,
          }}
          actionButtons={(
            <div className="ml-auto">
              <button
                type="button"
                className="btn btn-120 btn-secondary p-5 m-5"
                onClick={() => this.multipleSelection(true)}
              >
                {I18n.t('BEE1908' /* Marcar Todos */)}
              </button>
              <button
                type="button"
                className="btn btn-120 btn-secondary p-5 m-5"
                onClick={() => this.multipleSelection(false)}
              >
                {I18n.t('BEE1909' /* Desmarcar Todos */)}
              </button>
              <OneClickButton
                type="button"
                className="btn btn-140 btn-primary p-5 m-5"
                onClick={() => this.validChecked()}
              >
                {this.props.isBeeStock
                  ? I18n.t('BEE2235' /* Reenviar Integração */)
                  : I18n.t('BEE4139' /* Reprocessar */)}
              </OneClickButton>
            </div>
          )}
        />
        <ModalIntegrationInformation
          closeModal={this.closeModal}
          dataSend={this.state.dataSend}
          responseData={this.state.responseData}
          openInformationModal={this.state.openInformationModal}
        />
        {(showModalInformationQuantity
          && (
          <SweetAlert
            info
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE1849' /* Continuar */)}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.resyncSubmit()}
            onCancel={() => this.setState({ showModalInformationQuantity: false })}
          >
            {I18n.t('BEE2237', { 0: selectedIds.length } /* Foram selecionada %{0} integrações */)}
          </SweetAlert>
          )
        )}
        {(showCancelOrderModal
          && (
            <SweetAlert
              danger
              showCancel
              cancelBtnText={I18n.t('BEE99')}
              confirmBtnText={I18n.t('BEE100')}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101')}
              onConfirm={() => this.confirmCancelInboundOrder()}
              onCancel={() => this.setState({ showCancelOrderModal: false, selectedLine: null })}
            >
              <div className="my-3">
                <span>{I18n.t('BEE4146' /* A integração do documento será cancelada! */)}</span>
              </div>
            </SweetAlert>
          )
        )}
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getListIntegrationQueue: (filterStartDate, filterEndDate, isBeeStock) => dispatch(getListIntegrationQueue(
    filterStartDate,
    filterEndDate,
    isBeeStock,
  )),
  resyncQueue: (queueIds, isBeeStock) => dispatch(resyncQueue(queueIds, isBeeStock)),
  cancelInboundOrder: (queueId) => dispatch(cancelInboundOrder(queueId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IntegrationMonitorList));

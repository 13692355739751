import OrdersTypeRequests from '../../server/typeOrders';
import { addLoading, removeLoading } from './loading';

export const setOrderType = (ordersTypes) => ({
  type: 'SET_ORDERS_TYPE',
  ordersTypes,
});

export const setOrderTypeIndustry = (orderTypeListIndustry) => ({
  type: 'SET_ORDERS_TYPE_INDUSTRY',
  orderTypeListIndustry,
});

export const getOrdersTypeList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const ordersTypes = await OrdersTypeRequests.getOrdersType();
    dispatch(setOrderType(ordersTypes));
  } finally {
    dispatch(removeLoading());
  }
};

export const getOrdersTypeOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const ordersType = await OrdersTypeRequests.getOrdersTypeOptions();

    dispatch(removeLoading());
    return ordersType;
  } catch (e) {
    dispatch(removeLoading());
  }
};

export const getUsesIndustryProcess = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const orderTypeListIndustry = await OrdersTypeRequests.getUsesIndustryProcess();
    dispatch(setOrderTypeIndustry(orderTypeListIndustry));
  } finally {
    dispatch(removeLoading());
  }
};

export const getGridOptions = (typeOrder) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OrdersTypeRequests.getGridOptions(typeOrder);
  } finally {
    dispatch(removeLoading());
  }
};

export const createTypeOrder = (typeOrder) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OrdersTypeRequests.createTypeOrder(typeOrder);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateTypeOrders = (updateTypeOrder) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OrdersTypeRequests.updateTypeOrders(updateTypeOrder);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteOrder = (order) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OrdersTypeRequests.deleteOrder(order);
  } finally {
    dispatch(removeLoading());
  }
};

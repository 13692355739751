import RestService from '../services/rest';

// GET
async function getListIntegrationQueue(filterStartDate, filterEndDate, isBeeStock) {
  return RestService.getAuthenticated(
    'integration/integrationQueue/list',
    {
      filterStartDate,
      filterEndDate,
      isBeeStock,
    },
  );
}

// PUT
async function resyncQueue(queueIds = [], isBeeStock) {
  return RestService.putAuthenticated('integration/resync', { queueIds, isBeeStock });
}

async function cancelInboundOrder(queueId) {
  return RestService.putAuthenticated('integration/cancelInboundOrder', { queueId });
}

export default {
  getListIntegrationQueue,
  resyncQueue,
  cancelInboundOrder,
};

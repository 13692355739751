import CustomersRequests from '../../server/customers';
import { addLoading, removeLoading } from './loading';

export const setCustomers = (customers) => ({
  type: 'SET_CUSTOMERS',
  customers,
});

export const getCustomersList = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const customers = await CustomersRequests.getCustomers(tableParams);
    dispatch(setCustomers(customers ? customers.rows : []));
    dispatch(removeLoading());
    return customers || 0;
  } catch (e) {
    dispatch(removeLoading());
    return 0;
  }
};

export const createCustomer = (customer) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newCustomer = await CustomersRequests.createCustomer(customer);
    return newCustomer;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateCustomer = (customer) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CustomersRequests.updateCustomer(customer);
  } finally {
    dispatch(removeLoading());
  }
};

export const getCustomer = (customerId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CustomersRequests.getCustomer(customerId);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteCustomer = (customerId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CustomersRequests.deleteCustomer(customerId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getCustomersOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const customersOptions = await CustomersRequests.getCustomersOptions();
    return customersOptions;
  } finally {
    dispatch(removeLoading());
  }
};

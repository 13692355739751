import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import OneClickButton from '../../components/form/button';
import Form from '../../components/form/form';
import { PanelPage } from '../../components/pages/pages';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';
import DualList from '../../components/pages/DualList';

class ReportMovementInboundInspectedItems extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE36' /* Recebimento */) },
      { value: I18n.t('BEE4140' /* Itens Inspecionados */), active: true },
    ];

    this.options = [
      { label: I18n.t('BEE145' /* Filial */), value: 'branchCode' },
      { label: I18n.t('BEE342' /* Fornecedor */), value: 'supplierCode' },
      { label: I18n.t('BEE2390' /* CNPJ do Fornecedor */), value: 'supplierCnpj' },
      { label: I18n.t('BEE443' /* Documento */), value: 'inboundOrder.orderNumber' },
      { label: I18n.t('BEE1028' /* Série do documento */), value: 'inboundOrder.serie' },
      { label: I18n.t('BEE1030' /* CFOP */), value: 'inboundOrder.cfop' },
      { label: I18n.t('BEE2409' /* Data / Hora Integração */), value: 'inboundOrder.createdAt' },
      { label: I18n.t('BEE463' /* Linha */), value: 'lineNumber' },
      { label: I18n.t('BEE378' /* Código do Produto */), value: 'productCode' },
      { label: I18n.t('BEE758' /* Descrição do Produto */), value: 'product.name' },
      { label: I18n.t('BEE1756' /* Unidade de Medida */), value: 'unitMeasure' },
      { label: I18n.t('BEE441' /* Quantidade */), value: 'quantity' },
      { label: I18n.t('BEE4148' /* Status Inspeção */), value: 'inspected' },
      { label: I18n.t('BEE4143' /* Data Inspeção */), value: 'inspectedAt' },
      { label: I18n.t('BEE4172' /* Usuário Inspeção */), value: 'inspectionUser' },
      { label: I18n.t('BEE4174' /* Observação Inspeção */), value: 'inspectionNotes' },
    ];

    this.state = {
      selected: [],
      branchCodeFrom: '',
      branchCodeTo: '',
      supplierCodeFrom: '',
      supplierCodeTo: '',
      orderNumberFrom: '',
      orderNumberTo: '',
      serieFrom: '',
      serieTo: '',
      cfopFrom: '',
      cfopTo: '',
      createdAtFrom: '',
      createdAtTo: '',
      lineNumberFrom: '',
      lineNumberTo: '',
      productCodeFrom: '',
      productCodeTo: '',
      unitMeasureFrom: '',
      unitMeasureTo: '',
      quantityFrom: '',
      quantityTo: '',
      inspected: [0, 1, 2],
      inspectedAtFrom: '',
      inspectedAtTo: '',
      inspectionNotesFrom: '',
      inspectionNotesTo: '',
    };
  }

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  onChangeDualList = (selected) => {
    this.setState({ selected });
  };

  onGenerateSpreadsheet = () => {
    const {
      selected,
      branchCodeFrom,
      branchCodeTo,
      supplierCodeFrom,
      supplierCodeTo,
      orderNumberFrom,
      orderNumberTo,
      serieFrom,
      serieTo,
      cfopFrom,
      cfopTo,
      createdAtFrom,
      createdAtTo,
      lineNumberFrom,
      lineNumberTo,
      productCodeFrom,
      productCodeTo,
      unitMeasureFrom,
      unitMeasureTo,
      quantityFrom,
      quantityTo,
      inspectedAtFrom,
      inspectedAtTo,
      inspectionNotesFrom,
      inspectionNotesTo,
      inspectionUserTo,
      inspectionUserFrom,
      inspected,
    } = this.state;

    const { location } = this.props;

    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));

    const filters = returnFiltersByRules([
      ['branchCode', { type: 'between', value: [branchCodeFrom, branchCodeTo] }],
      ['supplierCode', { type: 'between', value: [supplierCodeFrom, supplierCodeTo] }],
      ['orderNumber', { type: 'between', value: [orderNumberFrom, orderNumberTo] }],
      ['serie', { type: 'between', value: [serieFrom, serieTo] }],
      ['cfop', { type: 'between', value: [cfopFrom, cfopTo] }],
      ['createdAt', { type: 'between', value: [createdAtFrom, createdAtTo] }],
      ['lineNumber', { type: 'between', value: [lineNumberFrom, lineNumberTo] }],
      ['productCode', { type: 'between', value: [productCodeFrom, productCodeTo] }],
      ['unitMeasure', { type: 'between', value: [unitMeasureFrom, unitMeasureTo] }],
      ['quantity', { type: 'between', value: [quantityFrom, quantityTo] }],
      ['inspected', { type: 'in', value: inspected }],
      ['inspectedAt', { type: 'between', value: [inspectedAtFrom, inspectedAtTo] }],
      ['inspectionUser', { type: 'between', value: [inspectionUserFrom, inspectionUserTo] }],
      ['inspectionNotes', { type: 'between', value: [inspectionNotesFrom, inspectionNotesTo] }],
    ]);

    this.props.generateReport(
      I18n.t('BEE4140' /* Itens Inspecionados */).toLowerCase(),
      'xlsx',
      location.pathname,
      headers,
      filters,
    );
  };

  onSelectFilter = (newState) => {
    this.setState({ ...newState });
  };

  render() {
    const {
      selected,
      branchCodeFrom,
      branchCodeTo,
      supplierCodeFrom,
      supplierCodeTo,
      orderNumberFrom,
      orderNumberTo,
      productCodeFrom,
      productCodeTo,
      inspectedAtFrom,
      inspectedAtTo,
      inspected,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={I18n.t('BEE4141', { 0: I18n.t('BEE4140' /* Itens Inspecionados */) } /* Relatório - %{0} */)}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <DualList
              id="dualselect"
              options={this.options}
              selected={selected}
              onChange={this.onChangeDualList}
              preserveSelectOrder
              showOrderButtons
              showHeaderLabels
            />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: supplierCodeFrom, to: supplierCodeTo },
                    { from: 'supplierCodeFrom', to: 'supplierCodeTo' },
                    `${I18n.t('BEE342' /* Fornecedor */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: orderNumberFrom, to: orderNumberTo },
                    { from: 'orderNumberFrom', to: 'orderNumberTo' },
                    `${I18n.t('BEE443' /* Documento */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: productCodeFrom, to: productCodeTo },
                    { from: 'productCodeFrom', to: 'productCodeTo' },
                    `${I18n.t('BEE378' /* Código do Produto */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createCheckboxGroup(
                    [
                      { label: I18n.t('BEE2119' /* Aprovado */), value: 2 },
                      { label: I18n.t('BEE2201' /* Reprovado */), value: 1 },
                      { label: I18n.t('BEE470' /* Pendente */), value: 0 },
                    ],
                    inspected,
                    'inspected',
                    `${I18n.t('BEE4148' /* Status Inspeção */)}:`,
                  ),
                  formContext.createDateInputRange(
                    { from: inspectedAtFrom, to: inspectedAtTo },
                    { from: 'inspectedAtFrom', to: 'inspectedAtTo' },
                    `${I18n.t('BEE4143' /* Data/Hora - Inspeção */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
                )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
                )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport:
  (nameDoc, type, route, columns, filters) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportMovementInboundInspectedItems);

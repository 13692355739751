import { I18n } from 'react-redux-i18n';

function integrationCode(code) {
  if (code || code === '') {
    switch (code) {
      case 'cancelInboundOrder':
        return I18n.t('BEE2247' /* Cancela Entrada */);
      case 'cancelOutboundOrder':
        return I18n.t('BEE2248' /* Cancela Saída */);
      case 'confirmDevolutionPicking':
        return I18n.t('BEE2320' /* Confirma Devolução */);
      case 'confirmPicking':
        return I18n.t('BEE2249' /* Confirma Separação */);
      case 'confirmPickingProduction':
        return I18n.t('BEE2921' /* Confirma Separação OP */);
      case 'inboundProductStorage':
        return I18n.t('BEE2250' /* Confirma Recebimento */);
      case 'productsMeasures':
        return I18n.t('BEE2251' /* Medidas Produto */);
      case 'waybills':
        return I18n.t('BEE907' /* Romaneio */);
      case 'productionOrders':
        return I18n.t('BEE2770' /* Ordem de Produção */);
      case 'serialGroups':
        return I18n.t('BEE1515' /* Etiqueta Agrupadora */);
      case 'stockAdjustmentInv':
        return I18n.t('BEE2816' /* Ajuste Inventário */);
      case 'stockAdjustment':
        return I18n.t('BEE2815' /* 'Ajuste Estoque'  */);
      case 'approveSerialGroups':
        return I18n.t('BEE2837' /* Aprovação Etiqueta */);
      case 'industryInvoice':
        return I18n.t('BEE2874' /* Documento de Saída Industria */);
      case 'manualRequest':
        return I18n.t('BEE2923' /* Requisição Manual */);
      case 'serialLabel':
        return I18n.t('BEE2760' /* Etiqueta Seriada */);
      default:
        return code;
    }
  } else if (!code) {
    return [
      { value: 'stockAdjustment', label: I18n.t('BEE2815' /* 'Ajuste Estoque'  */) },
      { value: 'stockAdjustmentInv', label: I18n.t('BEE2816' /* Ajuste Inventário */) },
      { value: 'approveSerialGroups', label: I18n.t('BEE2837' /* Aprovação Etiqueta */) },
      { value: 'cancelInboundOrder', label: I18n.t('BEE2247' /* Cancela Entrada */) },
      { value: 'cancelOutboundOrder', label: I18n.t('BEE2248' /* Cancela Saída */) },
      { value: 'confirmDevolutionPicking', label: I18n.t('BEE2320' /* Confirma Devolução */) },
      { value: 'confirmPicking', label: I18n.t('BEE2249' /* Confirma Separação */) },
      { value: 'confirmPickingProduction', label: I18n.t('BEE2921' /* Confirma Separação OP */) },
      { value: 'inboundProductStorage', label: I18n.t('BEE2250' /* Confirma Recebimento */) },
      { value: 'serialGroups', label: I18n.t('BEE1515' /* Etiqueta Agrupadora */) },
      { value: 'productsMeasures', label: I18n.t('BEE2251' /* Medidas Produto */) },
      { value: 'productionOrders', label: I18n.t('BEE2770' /* Ordem de Produção */) },
      { value: 'waybills', label: I18n.t('BEE907' /* Romaneio */) },
      { value: 'industryInvoice', label: I18n.t('BEE2874' /* Documento de Saída Industria */) },
      { value: 'manualRequest', label: I18n.t('BEE2923' /* Requisição Manual */) },
      { value: 'serialLabel', label: I18n.t('BEE2760' /* Etiqueta Seriada */) },
    ];
  }
}

function integrationErpCode(code) {
  if (code || code === '') {
    switch (code) {
      case 'inboundOrder':
        return I18n.t('BEE438' /* Documentos de Entrada */);
      case 'outboundOrder':
        return I18n.t('BEE1087' /* Documentos de Saída */);
      default:
        return code;
    }
  } else if (!code) {
    return [
      { value: 'inboundOrder', label: I18n.t('BEE438' /* Documentos de Entrada */) },
      { value: 'outboundOrder', label: I18n.t('BEE1087' /* Documentos de Saída */) },
    ];
  }
}

function integrationType(type) {
  if (type || type === 0) {
    switch (type) {
      case 1:
        return 'BeeStock';
      case 2:
        return 'ERP';
      default:
        return type;
    }
  } else if (!type) {
    return [
      { value: 1, label: 'BeeStock' },
      { value: 2, label: 'ERP' },
    ];
  }
}

export default {
  integrationCode,
  integrationErpCode,
  integrationType,
};

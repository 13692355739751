import OrderTypeConfigRequests from '../../server/orderTypeConfig';
import { addLoading, removeLoading } from './loading';

export const setOrderTypeConfigs = (configs) => ({
  type: 'SET_ORDER_TYPE_CONFIGS',
  configs,
});

export const openBulkImportOrderTypeConfigs = (showBulkImport) => ({
  type: 'OPEN_BULK_IMPORT_ORDER_TYPE_CONFIGS',
  showBulkImport,
});

export const bulkImportConfigs = (jsonCsv) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OrderTypeConfigRequests.bulkImportConfigs(jsonCsv);
  } finally {
    dispatch(removeLoading());
  }
};

export const createConfig = (orderTypeConfig) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OrderTypeConfigRequests.createOrderTypeConfig(orderTypeConfig);
  } finally {
    dispatch(removeLoading());
  }
};

export const getOptionsForm = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OrderTypeConfigRequests.getOptionsForm();
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteConfig = (configId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OrderTypeConfigRequests.deleteOrderTypeConfig(configId);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateStatusConfig = (configId, status) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OrderTypeConfigRequests.updateStatusConfig(configId, status);
  } finally {
    dispatch(removeLoading());
  }
};

export const getConfigsList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const configs = await OrderTypeConfigRequests.getOrderTypeConfigs();
    dispatch(setOrderTypeConfigs(configs));
  } finally {
    dispatch(removeLoading());
  }
};

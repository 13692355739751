import IntegrationRequests from '../../server/integration';
import { addLoading, removeLoading } from './loading';

export const getListIntegrationQueue = (filterStartDate, filterEndDate, isBeeStock) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await IntegrationRequests.getListIntegrationQueue(filterStartDate, filterEndDate, isBeeStock);
  } finally {
    dispatch(removeLoading());
  }
};

export const resyncQueue = (queueIds, isBeeStock) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await IntegrationRequests.resyncQueue(queueIds, isBeeStock);
  } finally {
    dispatch(removeLoading());
  }
};

export const cancelInboundOrder = (queueId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await IntegrationRequests.cancelInboundOrder(queueId);
  } finally {
    dispatch(removeLoading());
  }
};

/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { getEmptyStorageAddresses } from '../../../app/store/actions/inboundCheck';
import {
  getInboundStorageProductByEan,
  getInboundStorageProducts, putConfirmStorage, getSerialGroup,
} from '../../../app/store/actions/inboundStorage';
import OneClickButton from '../../../components/form/button';
import addNotification from '../../../components/notification';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../components/panel/panel';
import Table from '../../../components/table/Table';
import ProductsList from './productsList';

import WikiHelp from '../../../components/tooltip/wikiHelp';
import ROUTES from '../../../config/routes';
import { CustomNumberFormat } from '../../../components/form/input';
import Select from '../../../components/form/select';

class InboundStorageForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      baptism: '',
      branchCode: '',
      baptismData: [],
      eanValid: null,
      selectedProduct: {},
      productsTotal: 0,
      productsStored: 0,
      ean: '',
      productCode: '',
      unitMeasure: '',
      description: '',
      defaultAddress: '',
      addressSelected: '',
      depositCode: '',
      depositName: '',
      deposits: [],
      quantity: 0,
      lotNumber: '',
      sequence: 0,
      stockControlType: 0,
      showForm: false,
      showDivergenceModal: false,
      divergenceNote: '',
      expirationDate: null,
      controlExpirationDate: false,
      showPartialStorage: false,
      codLote: '',
      dtValidLote: null,
      serialGroupId: null,
    };
  }

  setValueDrop = (attr, value) => {
    if (attr === 'deposit') {
      if (value) {
        const { deposits } = this.state;
        const selectDeposit = deposits.find((element) => element.value === value.value);

        if (selectDeposit) {
          this.setState({
            depositCode: selectDeposit.value,
            depositName: selectDeposit.label,
          });
        }
      } else {
        this.setState({
          depositCode: '',
          depositName: '',
        });
      }
    }
  };

  createInput = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
  ) => (
    <div className="form-group p-2">
      <span>{label}</span>
      <div className="input-group">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        {!disabled && attr !== 'ean' && (
          <div className="input-group-append">
            <button type="button" className="btn btn-secondary" onClick={() => this.setValue(attr, '')}>
              <i className="fa fa-times" />
            </button>
          </div>
        )}
        {attr === 'ean' && (
          <div className="input-group-append">
            <button type="button" className="btn btn-secondary" onClick={() => this.resetProduct()}>
              <i className="fa fa-times" />
            </button>
          </div>
        )}
      </div>
    </div>
  );

  createInputButton = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    buttonLabel,
    buttonFunction,
    keypressFunction = undefined,
    id = null,
  ) => (
    <div className="form-group p-2">
      <span>{label}</span>
      <div className="input-group">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        <div className="input-group-append">
          <button type="button" className="btn btn-secondary" onClick={() => this.setValue(attr, '')}>
            <i className="fa fa-times" />
          </button>
          <button type="button" className="btn btn-primary" onClick={() => buttonFunction()}>
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  );

  createNumberInputButton = (
    value,
    attr,
    label,
    placeholder,
    disabled,
    buttonLabel,
    buttonFunction,
    keypressFunction = undefined,
    id = null,
  ) => (
    <div className="form-group p-2">
      <span>{label}</span>
      <div className="input-group mt-1">
        <NumberFormat
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          className="form-control"
          value={value || ''}
          onValueChange={(values) => {
            this.setValue(attr, values.formattedValue);
          }}
          defaultValue={0}
          placeholder={disabled ? '' : placeholder}
          allowNegative={attr === 'baptism' && false}
        />
        <div className="input-group-append">
          <button type="button" className="btn btn-secondary" onClick={() => this.setValue(attr, '')}>
            <i className="fa fa-times" />
          </button>
          <button type="button" className="btn btn-primary" onClick={() => buttonFunction()}>
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  );

  createNumberFormat = (value, attr, label, decimals, keypressFunction = undefined, id = null, disabled = false) => (
    <div className="form-group p-2">
      <span>{label}</span>
      <div className="input-group">
        <CustomNumberFormat
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          className="form-control"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={(!this.props.fractionalQuantity) ? 0 : decimals}
          value={value || ''}
          onValueChange={(values) => {
            this.setValue(attr, values);
          }}
          fixedDecimalScale
          defaultValue={0}
          disabled={disabled}
          allowEmptyFormatting
        />
        {!disabled && (
          <div className="input-group-append">
            <button type="button" className="btn btn-secondary" onClick={() => this.setValue(attr, 0)}>
              <i className="fa fa-times" />
            </button>
          </div>
        )}
      </div>
    </div>
  );

  createTextArea = (value, attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group p-2 mt-4">
      <span>{label}</span>
      <div className="col">
        <textarea
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  customDateStyle = ({ className, children }) => (
    <div>
      <div className="bg-primary rounded p-2">
        <h5 className="text-light text-center pt-2">
          Bee
          <span className="text-dark">Stock</span>
        </h5>
      </div>
      <div className={className}>
        <div>{children}</div>
      </div>
    </div>
  );

  createDateInput = (value, attr, label, disabled, keypressFunction = undefined, id = null, isClearable = true) => (
    <div className="form-group p-2 text-left">
      <span>{label}</span>
      <div className="add_display_flex">
        <DatePicker
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          isClearable={isClearable}
          selected={value && (typeof value === 'string' ? new Date(value) : value)}
          className="form-control"
          dateFormat="dd/MM/yyyy"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e)}
          calendarContainer={this.customDateStyle}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createSelectDropDown = (
    value,
    attr,
    label,
    items,
    disabled,
    tooltip,
    className = ['', ''],
    keypressFunction,
    id = null,
    isClearable,
    required,
  ) => (
    <div key={attr} className="form-group p-2" style={{ height: 'auto' }}>
      <span className={`col-form-label ${this.props.leftType ? className[0] : ''}`}>
        <span {...tooltip ? { 'data-tip': true, 'data-for': tooltip.id } : {}}>
          {label}
          {' '}
          {(required && (!disabled && this.props.mode !== 'detail')) && <span style={{ color: 'red' }}>*</span>}
          {!!tooltip && (
            <>
              {' '}
              {' '}
              <i className="fas fa-info-circle" />
            </>
          )}
        </span>
      </span>
      {!!tooltip && !!tooltip.content && (
        <ReactTooltip
          id={tooltip.id}
          type={tooltip.type || 'dark'}
          effect={tooltip.effect || 'solid'}
          place={tooltip.place || 'top'}
        >
          <span>{tooltip.content}</span>
        </ReactTooltip>
      )}
      <div className={className[1]}>
        <Select
          id={id && id}
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          value={value.label ? value : ''}
          onChange={(e) => this.setValueDrop(attr, e)}
          options={items}
          isClearable={isClearable !== undefined ? isClearable : true}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
          isDisabled={disabled}
          required={required}
          styles={{
            control: (defaultStyles) => ({
              ...defaultStyles,
              maxHeight: '34px',
              minHeight: '34px',
            }),
            dropdownIndicator: (defaultStyles) => ({
              ...defaultStyles,
              paddingTop: 0,
              paddingBottom: 0,
              color: '#ccc',
            }),
            clearIndicator: (defaultStyles) => ({
              ...defaultStyles,
              paddingTop: 0,
              paddingBottom: 0,
            }),
          }}
        />
      </div>
      {this.props.leftType && <div className="col-md-4" />}
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  resetForm = () => {
    this.setState({
      baptism: '',
      branchCode: '',
      baptismData: null,
      eanValid: false,
      selectedProduct: {},
      productsTotal: 0,
      productsStored: 0,
      ean: '',
      productCode: '',
      unitMeasure: '',
      description: '',
      defaultAddress: '',
      addressSelected: '',
      depositCode: '',
      depositName: '',
      deposits: [],
      quantity: 0,
      lotNumber: '',
      expirationDate: '',
      sequence: 0,
      stockControlType: 0,
      controlExpirationDate: false,
      showForm: false,
      divergenceNote: '',
    });
  };

  getBaptism = async () => {
    const { baptism } = this.state;

    try {
      if (baptism === '') {
        addNotification(
          'danger',
          I18n.t('BEE38' /* Armazenamento */),
          I18n.t('BEE982' /* É necessário informar o batismo */),
          'top-right',
        );
        return;
      }
      const baptismData = await this.props.getInboundStorageProducts(baptism);

      if (!baptismData.length) {
        addNotification(
          'danger',
          I18n.t('BEE38' /* Armazenamento */),
          I18n.t('BEE1069', { 0: baptism } /* Batismo %{0} não encontrado */),
          'top-right',
        );
        return;
      }

      this.setState({
        baptismData,
        showForm: true,
      });

      const product0 = baptismData.find((p) => p.status === 3 || p.status === 2);

      if (product0) this.getStorableProduct(product0);

      document.getElementById(0).focus();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE38' /* Armazenamento */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE38' /* Armazenamento */),
            I18n.t('BEE1626' /* Erro Armazenamento */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE38' /* Armazenamento */),
          I18n.t('BEE1626' /* Erro Armazenamento */),
          'top-right',
        );
      }
    }
  };

  getSelectedProductList = async (product) => {
    await this.resetProduct();
    await this.getStorableProduct(product);
  };

  getStorableProduct = async (product) => {
    const { baptismData } = this.state;

    if (product.status !== 2 && product.status !== 3) {
      addNotification(
        'danger',
        I18n.t('BEE38' /* Armazenamento */),
        I18n.t('BEE1070' /* Este produto já foi, ou ainda não pode ser armazenado */),
        'top-right',
      );
      return;
    }

    const productsStored = baptismData.filter((p) => p.status === 4);

    this.setState({
      productsTotal: baptismData.length > 0 ? baptismData.length : '0',
      productsStored: productsStored.length > 0 ? productsStored.length : '0',
      productCode: product.productCode || '',
      sequence: product.sequence || 0,
      unitMeasure: product.unitMeasure || '',
      description: product.name || '',
      defaultAddress: product.defaultAddress || '',
      deposits: product.deposits.map((deposit)=>{
        return {
          ...deposit,
          label: `${deposit.value} - ${deposit.label}`
        }
        }) || [],
      depositCode: (product.deposits || []).length === 1 ? product.deposits[0].value : '',
      depositName: (product.deposits || []).length === 1 ? `${product.deposits[0].value} - ${product.deposits[0].label}` : '',
      quantity: 0,
      stockControlType: product.stockControlType,
      controlExpirationDate: product.controlExpirationDate,
      branchCode: product.branchCode,
      selectedProduct: { ...product },
    });
  };

  getProductByEan = async () => {
    const {
      ean, baptismData, selectedProduct, baptism,
    } = this.state;

    let serialGroupCod = null;
    let vCodEan = ean;
    let serialGroupQuantity = 0;
    let codLote = '';
    let dtValid = '';

    try {
      // ETIQUETA SERIADA
      if (this.props.serialControlDeposit) {
        const serialGroup = await this.props.getSerialGroup(vCodEan, selectedProduct.inboundOrderId, baptism);

        if (!serialGroup) return;

        serialGroupCod = vCodEan;
        vCodEan = serialGroup.codEan;
        serialGroupQuantity = parseFloat(serialGroup.quantity);
        codLote = serialGroup.lotNumber;
        dtValid = serialGroup.expirationDate;

        this.setState({
          codLote: serialGroup.lotNumber,
          dtValidLote: serialGroup.expirationDate,
          serialGroupId: serialGroup.serialGroupId,
        });
      }

      if (Object.keys(selectedProduct).length && selectedProduct.eans.find((elem) => elem === vCodEan)) {
        const quantity = selectedProduct.grouped && selectedProduct.grouped.length > 0
          ? selectedProduct.grouped.reduce((acc, item) => acc + parseFloat(item.quantityPending), 0)
          : parseFloat(selectedProduct.quantityPending);

        // ETIQUETA SERIADA
        if (!this.props.serialControlDeposit) {
          codLote = selectedProduct.lotNumber;
          dtValid = selectedProduct.expirationDate;
        }

        this.setState({
          eanValid: true,
          quantity: this.props.serialControlDeposit ? serialGroupQuantity : quantity,
          lotNumber: (selectedProduct.stockControlType === 3) ? codLote : '',
          expirationDate: (selectedProduct.controlExpirationDate && dtValid)
            ? moment(dtValid).toDate() : '',
          defaultAddress: selectedProduct.defaultAddress,
          addressSelected: selectedProduct.storageAddress,
        });

        document.getElementById(1).focus();
      } else {
        const productEan = baptismData.find((elem) => {
          if ((elem.status === 2 || elem.status === 3) && elem.eans.length) {
            for (const prodEan of elem.eans) {
              if (prodEan === vCodEan) return true;
            }
          }
          return false;
        });

        if (!productEan) {
          addNotification(
            'danger',
            I18n.t('BEE38' /* Armazenamento */),
            I18n.t(
              'BEE1984',
              { 0: serialGroupCod || vCodEan }, /* O produto com o EAN %{0} não foi encontrado,
              não esta totalmente conferido ou já se encontra armazenado! */
            ),
            'top-right',
          );

          await this.resetProduct();
        } else {
          const quantity = productEan.grouped && productEan.grouped.length > 0
            ? productEan.grouped.reduce((acc, item) => acc + parseFloat(item.quantityPending), 0)
            : parseFloat(productEan.quantityPending);

          this.setState({
            eanValid: true,
            productCode: productEan.productCode,
            sequence: productEan.sequence,
            unitMeasure: productEan.unitMeasure,
            description: productEan.name,
            deposits: productEan.deposits || [],
            depositCode: (productEan.deposits || []).length === 1 ? productEan.deposits[0].value : '',
            depositName: (productEan.deposits || []).length === 1 ? productEan.deposits[0].label : '',
            quantity,
            lotNumber: (productEan.stockControlType === 3) ? productEan.lotNumber : '',
            expirationDate: (productEan.controlExpirationDate)
              ? moment(productEan.expirationDate).toDate() : '',
            defaultAddress: productEan.defaultAddress || '',
            addressSelected: productEan.storageAddress,
            stockControlType: productEan.stockControlType,
            controlExpirationDate: productEan.controlExpirationDate,
            selectedProduct: productEan,
          });

          document.getElementById(1).focus();
        }
      }
    } catch (error) {
      addNotification(
        'danger',
        I18n.t('BEE38' /* Armazenamento */),
        I18n.t('BEE979', { 0: serialGroupCod || vCodEan } /* O produto com o EAN %{0} não foi encontrado ! */),
        'top-right',
      );
      await this.resetProduct();
    }
  };

  scanEan = async () => {
    const { ean } = this.state;

    if (ean === '') {
      addNotification(
        'danger',
        I18n.t('BEE38' /* Armazenamento */),
        I18n.t('BEE980' /* Código EAN deve ser informado */),
        'top-right',
      );
      await this.resetProduct();
    } else {
      this.getProductByEan(ean);
    }
  };

  goToElement = (e, id, attr) => {
    if (e.keyCode === 13) {
      if (attr === 'ean') { this.scanEan(); }
      if (attr === 'addressSelected' && this.state.stockControlType !== 3) id += 1;
      if (attr === 'expirationDate' && this.state.controlExpirationDate) id += 1;

      document.getElementById(id + 1).focus();
      document.getElementById(id + 1).select();
    }
  };

  keypressConfirmStorage = (e) => {
    if (e.keyCode === 13) this.confirmStorage();
  };

  resetProduct = async () => {
    await this.setState({
      ean: '',
      productCode: '',
      unitMeasure: '',
      description: '',
      defaultAddress: '',
      addressSelected: '',
      depositCode: '',
      depositName: '',
      deposits: [],
      quantity: 0,
      lotNumber: '',
      expirationDate: '',
      sequence: 0,
      stockControlType: 0,
      controlExpirationDate: false,
      eanValid: false,
      selectedProduct: {},
      divergenceNote: '',
    });
  };

  confirmDivergence = () => {
    this.setState({
      showDivergenceModal: false,
    });
    this.confirmStorageSubmit(true);
  };

  confirmPartialStorage = () => {
    this.setState({ showPartialStorage: false });
    this.confirmStorageSubmit();
  };

  confirmStorage = () => {
    const {
      quantity, selectedProduct, addressSelected, lotNumber, expirationDate,
      depositCode,
    } = this.state;

    if (!selectedProduct) {
      addNotification(
        'danger',
        I18n.t('BEE38' /* Armazenamento */),
        I18n.t('BEE1072' /* Nenhum produto selecionado ! */),
        'top-right',
      );
      return;
    }

    if (!quantity) {
      addNotification(
        'danger',
        I18n.t('BEE38' /* Armazenamento */),
        I18n.t('BEE1039' /* É necessário informar a quantidade */),
        'top-right',
      );
      return;
    }

    if (!addressSelected) {
      addNotification(
        'danger',
        I18n.t('BEE38' /* Armazenamento */),
        I18n.t('BEE1073' /* Endereço selecionado deve ser informado ! */),
        'top-right',
      );
      return;
    }

    if (!depositCode) {
      addNotification(
        'danger',
        I18n.t('BEE38' /* Armazenamento */),
        I18n.t('BEE1330' /* Depósito deve ser informado ! */),
        'top-right',
      );
      return;
    }

    if (!this.props.serialControlDeposit && selectedProduct.stockControlType === 3) {
      if (!lotNumber) {
        addNotification(
          'danger',
          I18n.t('BEE38' /* Armazenamento */),
          I18n.t('BEE1074' /* Lote deve ser informado ! */),
          'top-right',
        );
        return;
      } if (lotNumber !== selectedProduct.lotNumber) {
        addNotification(
          'danger',
          I18n.t('BEE38' /* Armazenamento */),
          I18n.t(
            'BEE1075',
            {
              0: lotNumber,
              1: selectedProduct.lotNumber,
            }, /* Lote %{0} diferente do Lote informado na Conferência %{1} ! */
          ),
          'top-right',
        );
        return;
      }
    }

    if (!this.props.serialControlDeposit && selectedProduct.controlExpirationDate) {
      if (!expirationDate) {
        addNotification(
          'danger',
          I18n.t('BEE38' /* Armazenamento */),
          I18n.t('BEE2292' /* Informe a data de validade */),
          'top-right',
        );
        return;
      } if (!moment(expirationDate).isSame(selectedProduct.expirationDate, 'year')) {
        addNotification(
          'danger',
          I18n.t('BEE38' /* Armazenamento */),
          I18n.t(
            'BEE2246',
            { 0: moment(expirationDate).format('L') }, /* Data de validade informada %{0} esta diferente */
          ),
          'top-right',
        );
        return;
      }
    }

    if (parseFloat(quantity) > parseFloat(selectedProduct.quantityPending)) {
      const content = (
        <div className="notification-content notification-danger">
          <div className="notification-title">{I18n.t('BEE38' /* Armazenamento */)}</div>
          <div className="notification-message">
            <h4>Divergência nas quantidades:</h4>
            <p>
              {
                I18n.t('BEE3213', { 0: parseFloat(selectedProduct.quantityCheck) } /* Quantidade Conferida = %{0} */)
              }
            </p>
            <p>
              {
                I18n.t('BEE3214', { 0: parseFloat(selectedProduct.quantityPending) } /* Quantidade Pendente = %{0} */)
              }
            </p>
            <p>
              {
                I18n.t('BEE3215', { 0: parseFloat(quantity) } /* Quantidade Informada = %{0} */)
              }
            </p>
          </div>
        </div>
      );

      addNotification(
        'danger',
        I18n.t('BEE38' /* Armazenamento */),
        '',
        'top-right',
        content,
      );
      return;
    } if (!this.props.serialControlDeposit && parseFloat(quantity) < parseFloat(selectedProduct.quantityPending)) {
      this.setState({ showPartialStorage: true });
      return;
    }

    this.confirmStorageSubmit();
  };

  confirmStorageSubmit = async (divergenceStorage = false) => {
    const {
      selectedProduct, quantity, lotNumber, expirationDate,
      sequence, addressSelected, baptism, divergenceNote,
      codLote, dtValidLote, serialGroupId, depositCode,
    } = this.state;

    try {
      const { id, productLotId, productPartialId } = selectedProduct;

      const ids = selectedProduct.grouped && selectedProduct.grouped.length
        ? selectedProduct.grouped.map((item) => item.id)
        : [];

      const productLotIds = selectedProduct.grouped && selectedProduct.grouped.length
        ? selectedProduct.grouped.map((item) => item.productLotId)
        : [];

      const productPartialIds = selectedProduct.grouped && selectedProduct.grouped.length
        ? selectedProduct.grouped.map((item) => item.productPartialId)
        : [];

      let nExpirationDate = null;

      if (this.props.serialControlDeposit && dtValidLote) {
        nExpirationDate = moment(dtValidLote).format();
      } else if (expirationDate) {
        nExpirationDate = moment(expirationDate).format();
      }

      const result = selectedProduct.grouped && selectedProduct.grouped.length
        ? await this.props.putConfirmStorageGroup(
          baptism,
          ids,
          addressSelected.toLocaleUpperCase(),
          quantity,
          this.props.serialControlDeposit ? codLote : lotNumber,
          sequence,
          productLotIds,
          productPartialIds,
          divergenceNote,
          divergenceStorage,
          nExpirationDate || '',
          serialGroupId,
          depositCode,
        )
        : await this.props.putConfirmStorage(
          baptism,
          id,
          addressSelected.toLocaleUpperCase(),
          quantity,
          this.props.serialControlDeposit ? codLote : lotNumber,
          sequence,
          productLotId,
          productPartialId,
          divergenceNote,
          divergenceStorage,
          nExpirationDate || '',
          serialGroupId,
          depositCode,
        );

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE38' /* Armazenamento */),
          I18n.t('BEE3232' /* Ocorreu um erro ao tentar armazenar o produto */),
          'top-right',
        );
      } else {
        this.resetProduct();

        await this.getBaptism();

        addNotification(
          'success',
          I18n.t('BEE38' /* Armazenamento */),
          I18n.t('BEE2297' /* Produto armazenado com sucesso! */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE38' /* Armazenamento */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE38' /* Armazenamento */),
            I18n.t('BEE3233' /* Erro ao armazenar o produto */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE38' /* Armazenamento */),
          I18n.t('BEE3233' /* Erro ao armazenar o produto */),
          'top-right',
        );
      }
    }
  };

  showAddressSelect = async () => {
    const { branchCode, productCode } = this.state;

    try {
      const emptyAddressesOptions = await this.props.getEmptyStorageAddresses(branchCode, productCode);

      if (emptyAddressesOptions && emptyAddressesOptions.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE1753' /* Endereços Vazios */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      } else {
        this.setState({
          emptyAddressesOptions,
          showAddressSelect: true,
        });
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification('danger', I18n.t('BEE1753' /* Endereços Vazios */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1753' /* Endereços Vazios */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  selectSuggestedAddress = async (suggestedAddress) => {
    this.setState({
      addressSelected: suggestedAddress.code,
      showAddressSelect: false,
      emptyAddressesOptions: [],
    });
  };

  addressSelectForm = () => {
    const { emptyAddressesOptions } = this.state;
    const defaultSorted = [{ id: 'code', desc: false }];
    const tableColumns = [
      {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
      {
        Header: I18n.t('BEE355' /* Curva */),
        accessor: 'curve',
        style: { alignSelf: 'center', textAlign: 'center' },
      },
      {
        Header: I18n.t('BEE1855' /* Tamanho */),
        accessor: 'size',
        style: { alignSelf: 'center', textAlign: 'center' },
      },
    ];

    return (
      <Table
        panelHeaderProps={{
          noButton: true,
          children: I18n.t('BEE1753' /* Endereços Vazios */),
        }}
        filterable
        data={emptyAddressesOptions}
        columns={tableColumns}
        expander
        defaultPageSize={10}
        defaultSorted={defaultSorted}
        defaultFilterMethod={(filter, row) => {
          const input = _.lowerCase(filter.value);
          const value = _.lowerCase(row[filter.id]);
          if (_.includes(value, input)) {
            return true;
          }
        }}
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              onClick: async () => {
                await this.selectSuggestedAddress(rowInfo.original);
              },
              style: {
                cursor: 'pointer',
              },
            };
          }
          return {};
        }}
      />
    );
  };

  render() {
    const {
      baptism, productsTotal, productsStored, ean, productCode, unitMeasure, description,
      defaultAddress, addressSelected, deposits, quantity, lotNumber, eanValid, stockControlType,
      showDivergenceModal, divergenceNote, showAddressSelect, expirationDate, controlExpirationDate, showPartialStorage,
      depositCode, depositName,
    } = this.state;

    const {
      baptismData, showForm,
    } = this.state;

    const title = I18n.t('BEE38' /* Armazenamento */);

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE36' /* Recebimento */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE38' /* Armazenamento */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
          <WikiHelp wikiPath={ROUTES.INBOUND_STORAGE_HELP} />
        </div>
        <div className="row">
          <div className="col-xl-12">
            <form onSubmit={(e) => e.preventDefault()}>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  <div className="row">
                    <div className="col-md-4">
                      {showForm
                        ? this.createInput(
                          baptism,
                          'baptism',
                          I18n.t('BEE425' /* Batismo */),
                          '',
                          'number',
                          true,
                          showForm,
                        )
                        : this.createNumberInputButton(
                          baptism,
                          'baptism',
                          I18n.t('BEE425' /* Batismo */),
                          I18n.t('BEE1076' /* Informe o batismo */),
                          false,
                          <i className="fa fa-search" />,
                          this.getBaptism,
                        )}
                    </div>
                    <div className="col-md-4">
                      {showForm && this.createInput(
                        ean,
                        'ean',
                        this.props.serialControlDeposit
                          ? I18n.t('BEE2978' /* Agrupadora */) : I18n.t('BEE377' /* EAN */),
                        '',
                        'text',
                        false,
                        !!eanValid,
                        this.goToElement,
                        0,
                      )}
                    </div>
                    <div className="col-md-2">
                      {showForm && this.createInput(
                        productsTotal,
                        'total',
                        I18n.t('BEE420' /* Total */),
                        '',
                        'text',
                        false,
                        true,
                      )}
                    </div>
                    <div className="col-md-2">
                      {showForm && this.createInput(
                        productsStored,
                        'stored',
                        I18n.t('BEE427' /* Armazenado */),
                        '',
                        'text',
                        false,
                        true,
                      )}
                    </div>
                  </div>
                  {showForm && (
                    <div className="row">
                      <div className="col-md-3">
                        {this.createInput(
                          productCode,
                          'productCode',
                          I18n.t('BEE378' /* Código do Produto */),
                          '',
                          'text',
                          false,
                          true,
                        )}
                      </div>
                      <div className="col-md-3">
                        {this.createInput(
                          unitMeasure,
                          'unitMeasure',
                          I18n.t('BEE29' /* Unidades de Medida */),
                          '',
                          'text',
                          false,
                          true,
                        )}
                      </div>
                      <div className="col-md-6">
                        {this.createInput(
                          defaultAddress,
                          'defaultAddress',
                          I18n.t('BEE423' /* Endereço Padrão */),
                          '',
                          'text',
                          false,
                          true,
                        )}
                      </div>
                    </div>
                  )}
                  {showForm && (
                    <div className="row">
                      <div className="col-md-12">
                        {this.createInput(
                          description,
                          'description',
                          I18n.t('BEE277' /* Descrição */),
                          '',
                          'text',
                          false,
                          true,
                        )}
                      </div>
                    </div>
                  )}
                  {showForm && (
                    <div className="row">
                      <div className="col-md-3">
                        {this.createSelectDropDown(
                          { value: depositCode, label: depositName },
                          'deposit',
                          `${I18n.t('BEE182' /* Depósito */)}:`,
                          deposits,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          true,
                        )}
                      </div>
                      <div className="col-md-3">
                        {eanValid
                          ? this.createInputButton(
                            addressSelected.toLocaleUpperCase(),
                            'addressSelected',
                            I18n.t('BEE490' /* Endereço Selecionado */),
                            '',
                            'text',
                            false,
                            false,
                            <i className="fa fa-list-alt" />,
                            this.showAddressSelect,
                            this.goToElement,
                            1,
                          )
                          : this.createInput(
                            addressSelected,
                            'addressSelected',
                            I18n.t('BEE490' /* Endereço Selecionado */),
                            '',
                            'text',
                            false,
                            true,
                            this.goToElement,
                            1,
                          )}
                      </div>
                      <div className="col-md-2">
                        {this.createInput(
                          lotNumber,
                          'lotNumber',
                          I18n.t('BEE428' /* Lote */),
                          '',
                          'text',
                          false,
                          this.props.serialControlDeposit
                            ? true : (eanValid ? stockControlType !== 3 : true),
                          this.goToElement,
                          2,
                        )}
                      </div>
                      <div className="col-md-2">
                        {this.createDateInput(
                          expirationDate,
                          'expirationDate',
                          I18n.t('BEE434' /* Data de Validade */),
                          this.props.serialControlDeposit ? true : !controlExpirationDate,
                          this.goToElement,
                          3,
                          false,
                        )}
                      </div>
                      <div className="col-md-2">
                        {this.createNumberFormat(
                          quantity,
                          'quantity',
                          I18n.t('BEE441' /* Quantidade */),
                          3,
                          this.keypressConfirmStorage,
                          4,
                          this.props.serialControlDeposit ? true : !eanValid,
                        )}
                      </div>
                    </div>
                  )}
                </PanelBody>
                <PanelFooter>
                  <div className="row">
                    <div className="col text-right">
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-white p-5 m-5"
                        onClick={() => this.resetForm()}
                      >
                        {I18n.t('BEE99' /* Cancelar */)}
                      </OneClickButton>
                      {(!showForm
                        && (
                          <OneClickButton
                            type="submit"
                            className="btn btn-120 btn-primary p-5 m-5"
                            onClick={() => this.getBaptism()}
                          >
                            {I18n.t('BEE407' /* Buscar */)}

                          </OneClickButton>
                        )
                      )}
                      {(showForm
                        && (
                          <OneClickButton
                            type="button"
                            className="btn btn-120 btn-primary p-5 m-5"
                            onClick={() => this.confirmStorage()}
                          >
                            {I18n.t('BEE100' /* Confirmar */)}
                          </OneClickButton>
                        )
                      )}
                    </div>
                  </div>
                </PanelFooter>
              </Panel>
              {showForm && (
                <Panel>
                  <PanelBody>
                    <ProductsList
                      products={baptismData}
                      getStorableProduct={this.getSelectedProductList}
                    />
                  </PanelBody>
                </Panel>
              )}
              <Modal size="md" isOpen={showAddressSelect} toggle={() => this.setState({ showAddressSelect: false })}>
                <ModalHeader
                  toggle={() => this.setState({ showAddressSelect: false })}
                >
                  {I18n.t('BEE566' /* Endereço Sugerido */)}
                </ModalHeader>
                <ModalBody>
                  {this.addressSelectForm()}
                </ModalBody>
              </Modal>
            </form>
            {(showDivergenceModal
              && (
                <SweetAlert
                  danger
                  showCancel
                  cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
                  confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
                  confirmBtnBsStyle="danger"
                  cancelBtnBsStyle="default"
                  title={I18n.t('BEE101' /* Você tem certeza? */)}
                  onConfirm={() => this.confirmDivergence()}
                  onCancel={() => this.setState({ showDivergenceModal: false })}
                >
                  <div>
                    <span>{I18n.t('BEE993' /* Divergência de quantidades ! */)}</span>
                    {this.createTextArea(
                      divergenceNote,
                      'divergenceNote',
                      `${I18n.t('BEE724' /* Motivo */)}:`,
                      I18n.t('BEE991' /* Informe o motivo da divergência */),
                      3,
                      true,
                      false,
                    )}
                  </div>
                </SweetAlert>
              )
            )}
            {(showPartialStorage
              && (
                <SweetAlert
                  warning
                  showCancel
                  cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
                  confirmBtnText={I18n.t('BEE1800' /* Prosseguir */)}
                  confirmBtnBsStyle="primary"
                  cancelBtnBsStyle="danger"
                  title={I18n.t('BEE2318' /* Armazenamento Parcial */)}
                  onConfirm={() => this.confirmPartialStorage()}
                  onCancel={() => this.setState({ showDivergenceModal: true, showPartialStorage: false })}
                >
                  <h5>
                    {
                      I18n.t(
                        'BEE2319',
                        { 0: quantity }, /* Deseja prosseguir com o armazenamento parcial?
                         A quantidade informada e %{0} */
                      )
                    }
                  </h5>
                </SweetAlert>
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  fractionalQuantity: state.app.permissionsCompany && state.app.permissionsCompany.fractionalQuantity,
  serialControlDeposit: state.app.permissionsCompany
    && state.app.permissionsCompany.serialControlDeposit
    && state.app.permissionsCompany.serialDeposits.depInbound
    && state.app.permissionsCompany.serialDeposits.depResale,
});

const mapDispatchToProps = (dispatch) => ({
  getInboundStorageProducts: (baptism) => dispatch(getInboundStorageProducts(baptism)),
  getInboundStorageProductByEan: (
    ean,
    sequence,
    baptism,
  ) => dispatch(getInboundStorageProductByEan(ean, sequence, baptism)),
  putConfirmStorage: (
    baptism,
    inboundOrderProductId,
    addressSelected,
    quantity,
    lotNumber,
    sequence,
    productLotId,
    productPartialId,
    divergenceNote,
    divergenceStorage,
    expirationDate,
    serialGroupId,
    depositCode,
  ) => dispatch(putConfirmStorage(
    baptism,
    inboundOrderProductId,
    addressSelected,
    quantity,
    lotNumber,
    sequence,
    productLotId,
    productPartialId,
    divergenceNote,
    divergenceStorage,
    expirationDate,
    serialGroupId,
    depositCode,
  )),
  putConfirmStorageGroup: (
    baptism,
    inboundOrderProductIds,
    addressSelected,
    quantity,
    lotNumber,
    sequence,
    productLotIds,
    productPartialIds,
    divergenceNote,
    divergenceStorage,
    expirationDate,
    serialGroupId,
    depositCode,
  ) => dispatch(putConfirmStorage(
    baptism,
    inboundOrderProductIds,
    addressSelected,
    quantity,
    lotNumber,
    sequence,
    productLotIds,
    productPartialIds,
    divergenceNote,
    divergenceStorage,
    expirationDate,
    serialGroupId,
    depositCode,
  )),
  getEmptyStorageAddresses: (branchCode, productCode) => dispatch(getEmptyStorageAddresses(branchCode, productCode)),
  getSerialGroup: (barCode, inboundOrderId, uz) => dispatch(getSerialGroup(barCode, inboundOrderId, uz)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InboundStorageForm));

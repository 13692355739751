import { I18n } from 'react-redux-i18n';

function movementType(type) {
  if (type || type === 0) {
    switch (type) {
      case 1:
        return I18n.t('BEE201' /* Entrada */);
      case 2:
        return I18n.t('BEE202' /* Saída */);
      default:
        return type;
    }
  } else if (!type) {
    return [
      { value: 1, label: I18n.t('BEE201' /* Entrada */) },
      { value: 2, label: I18n.t('BEE202' /* Saída */) },
    ];
  }
}

function deliveryType(type) {
  if (type || type === 0) {
    switch (type) {
      case 0:
        return I18n.t('BEE203' /* Ambos */);
      case 1:
        return I18n.t('BEE669' /* CIF */);
      case 2:
        return I18n.t('BEE670' /* FOB */);
      default:
        return type;
    }
  } else if (!type) {
    return [
      { value: 0, label: I18n.t('BEE203' /* Ambos */) },
      { value: 1, label: I18n.t('BEE669' /* CIF */) },
      { value: 2, label: I18n.t('BEE670' /* FOB */) },
    ];
  }
}

function statusConfig(status) {
  if (status || status === 0) {
    switch (status) {
      case 0:
        return I18n.t('BEE2492' /* Desativado */);
      case 1:
        return I18n.t('BEE4046' /* Ativado */);
      default:
        return status;
    }
  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE4046' /* Ativado */) },
      { value: 0, label: I18n.t('BEE2492' /* Desativado */) },
    ];
  }
}

export default {
  movementType,
  deliveryType,
  statusConfig,
};

import RestService from '../services/rest';

// GET
async function getProductsGrouping(tableParams) {
  return RestService.postAuthenticated('productsGrouping/list/all', tableParams);
}

async function getProductGrouping(productGroupingId) {
  return RestService.getAuthenticated('productsGrouping/detail', { productGroupingId });
}

async function getProductByCode(productCode) {
  return RestService.getAuthenticated('productsGrouping/detail/getProductByCode', {
    productCode,
  });
}

async function getProductGroupingOptions() {
  return RestService.getAuthenticated('productsGrouping/list/options');
}

// POST
async function createProductGrouping(productGrouping) {
  return RestService.postAuthenticated('productsGrouping/create', productGrouping);
}

async function deleteProductGrouping(productGroupingId) {
  return RestService.postAuthenticated('productsGrouping/delete', { productGroupingId });
}

// PUT
async function updateProductGrouping(productGrouping) {
  return RestService.putAuthenticated('productsGrouping/update', productGrouping);
}

export default {
  getProductsGrouping,
  getProductGroupingOptions,
  createProductGrouping,
  updateProductGrouping,
  getProductGrouping,
  deleteProductGrouping,
  getProductByCode,
};

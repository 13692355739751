import ProductsGroupingRequests from '../../server/productsGrouping';
import { addLoading, removeLoading } from './loading';

export const setProductsGrouping = (productsGrouping) => ({
  type: 'SET_PRODUCTS_GROUPING',
  productsGrouping,
});

export const getProductsGroupingList = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const productsGrouping = await ProductsGroupingRequests.getProductsGrouping(tableParams);
    dispatch(setProductsGrouping(productsGrouping ? productsGrouping.rows : []));
    return productsGrouping || 0;
  } finally {
    dispatch(removeLoading());
  }
};

export const createProductGrouping = (productGrouping) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newProductGrouping = await ProductsGroupingRequests.createProductGrouping(productGrouping);
    return newProductGrouping;
  } finally {
    dispatch(removeLoading());
  }
};

export const getProductByCode = (productCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ProductsRequests.getProductByCode(productCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateProductGrouping = (productGrouping) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ProductsGroupingRequests.updateProductGrouping(productGrouping);
  } finally {
    dispatch(removeLoading());
  }
};

export const getProductGrouping = (productGroupingId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ProductsGroupingRequests.getProductGrouping(productGroupingId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getProductGroupingOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ProductsGroupingRequests.getProductGroupingOptions();
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteProductGrouping = (productGroupingId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ProductsGroupingRequests.deleteProductGrouping(productGroupingId);
  } finally {
    dispatch(removeLoading());
  }
};

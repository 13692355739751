const INITIAL_STATE = {
  configsList: [],
  showBulkImport: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_ORDER_TYPE_CONFIGS': {
      return { ...state, configsList: action.configs };
    }
    case 'OPEN_BULK_IMPORT_ORDER_TYPE_CONFIGS': {
      return { ...state, showBulkImport: action.showBulkImport };
    }
    default:
      return state;
  }
};

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { getProductsGroupingList, deleteProductGrouping } from '../../../../app/store/actions/productsGrouping';

class ProductGroupingList extends React.Component {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.state = {
      showModalDelete: false,
      pages: 0,
      totalDataLength: 0,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showProductGroupingDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showEditProductGrouping(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showCopyProductGrouping(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showDeleteProductGrouping(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1853' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 400,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'description',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 180,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE3998' /* Sequência de Agrupamento */),
        accessor: 'sequence',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      },
    ];
  }

  async componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({ page: this.props.location.state.dice.page });
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
    });
  };

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        const { value } = event.target;
        const { keyCode, which } = event;

        if (value !== '' && (keyCode === 13 || which === 13)) {
          onChange(value);
        }
      }}
      onKeyUp={(event) => {
        const { value } = event.target;
        const { keyCode, which } = event;

        if (value === '' && (keyCode === 8 || which === 8)) {
          onChange(value);
        }
      }}
    />
  );

  getProductsGrouping = async () => {
    const data = await this.props.getProductsGroupingList({
      page: this.page,
      pageSize: this.pageSize,
      sorted: this.sorted,
      filtered: this.filtered,
    });

    this.setState({ pages: data.pages, firstRun: true, totalDataLength: data.totalDataLength });
  };

  deleteProductGrouping = async (productGrouping) => {
    const result = await this.props.deleteProductGrouping(productGrouping._original.id);

    this.setInitState();
    if (result) {
      addNotification(
        'success',
        I18n.t('BEE3999' /* Eliminar Agrupamento de Produtos */),
        I18n.t('BEE4000' /* Agrupamento de produto eliminado com sucesso. */),
        'top-right',
      );
    }
    await this.getProductsGrouping();
  };

  showProductGroupingDetail = (productGrouping) => {
    this.props.history.push({
      pathname: ROUTES.PRODUCT_GROUPING_DETAIL,
      state: {
        productGrouping: productGrouping._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditProductGrouping = (productGrouping) => {
    this.props.history.push({
      pathname: ROUTES.PRODUCT_GROUPING_EDIT,
      state: {
        productGrouping: productGrouping._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyProductGrouping = (productGrouping) => {
    this.props.history.push({
      pathname: ROUTES.PRODUCT_GROUPING_COPY,
      state: {
        productGrouping: productGrouping._original,
      },
    });
  };

  showDeleteProductGrouping = (productGrouping) => {
    this.setState({ showModalDelete: true, productGroupingUpdate: productGrouping });
  };

  render() {
    const { productsGroupingList = [] } = this.props;
    const {
      productGroupingUpdate, showModalDelete, isLoading, pages, page, totalDataLength,
    } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE3997' /* Agrupamento de Produtos */)}</li>
          </ol>
        </div>
        <Table
          wikiHelp={ROUTES.PRODUCT_GROUPING_HELP}
          downloadCSV
          headerTitle={I18n.t('BEE3997' /* Agrupamento de Produtos */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.PRODUCT_GROUPING_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE4001' /* Incluir Agrupamento de Produtos */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getProductsGrouping,
          }}
          loading={isLoading}
          filterable
          data={productsGroupingList}
          totalDataLength={totalDataLength}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          manual // Server - Side Table
          pages={pages}
          page={(page) || ((!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          onFetchData={(state, instance) => {
            this.page = (state.page) ? state.page : (!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : state.page;
            this.pageSize = state.pageSize;
            this.sorted = state.sorted;
            this.filtered = state.filtered;

            this.getProductsGrouping();
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteProductGrouping(productGroupingUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE4030' /* O Agrupamento de Produtos será eliminado. */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productsGroupingList: state.productsGrouping && state.productsGrouping.productsGroupingList,
});

const mapDispatchToProps = (dispatch) => ({
  getProductsGroupingList: (tableParams) => dispatch(getProductsGroupingList(tableParams)),
  deleteProductGrouping: (productGroupingId) => dispatch(deleteProductGrouping(productGroupingId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductGroupingList));

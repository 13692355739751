import 'react-table/react-table.css';
import React from 'react';
import { I18n } from 'react-redux-i18n';

export default class ProductsList extends React.PureComponent {
  render() {
    const { products } = this.props;

    return (
      <div className="table-responsive">
        <table className="table table-bordered widget-table widget-table-rounded">
          <thead>
            <tr className="text-center f-s-12">
              <th width="5%">{I18n.t('BEE463' /* Linha */)}</th>
              <th width="35%">{I18n.t('BEE225' /* Produto */)}</th>
              <th width="10%">{I18n.t('BEE384' /* Unidade */)}</th>
              <th width="25%">{I18n.t('BEE135' /* Observação */)}</th>
              <th width="5%">
                {I18n.t('BEE445' /* Conferido */)}
                ?
              </th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => {
              let textClass = '';

              if (product.status >= 2) {
                textClass = ' text-teal';
              } else if (product.inspection === true && product.inspected === 0) {
                textClass = ' text-danger';
              }

              return (
                <tr className="text-center" key={product.lineNumber}>
                  <td>
                    <h5 className={textClass}>{product.lineNumber}</h5>
                    <p>
                      { product.status === 1 && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.props.getSelectedProduct(product)}
                      >
                        {' '}
                        <i className="fa fa-search" />
                      </button>
                      )}
                    </p>
                  </td>
                  <td className="text-left">
                    <h4 className={`widget-table-title${textClass}`}>{product.productCode}</h4>
                    <p className={`widget-table-desc m-b-15${textClass}`}>{product.product.name}</p>
                    <p className={`widget-table-desc m-b-15${textClass}`}>{product.product.fullName}</p>
                    <div className={`clearfix f-s-10${textClass}`}>
                      {`${I18n.t('BEE224' /* Status */)}:`}
                      <b className={textClass}>
                        {' '}
                        { product.status === 1 ? I18n.t('BEE470' /* Pendente */) : I18n.t('BEE445' /* Conferido */)}
                      </b>
                    </div>
                  </td>
                  <td className={textClass}>
                    {product.unitMeasure}
                  </td>
                  <td className={textClass}>
                    {product.note}
                  </td>
                  <td>
                    { product.status >= 2
                      ? <i className="fas fa-check-circle fa-2x text-teal" />
                      : <i className="fas fa-times-circle fa-2x text-danger" /> }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

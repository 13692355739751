import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import OneClickButton from '../../../../components/form/button';
import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../../components/panel/panel';
import addNotification from '../../../../components/notification';
import Select from '../../../../components/form/select';

import ROUTES from '../../../../config/routes';

import {
  createProductGrouping, getProductGrouping, updateProductGrouping,
} from '../../../../app/store/actions/productsGrouping';
import Form from '../../../../components/form/form';

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, {
    isDisabled, isSelected,
  }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    },
  }),
};

class ProductGroupingForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      code: '',
      name: '',
      description: '',
      sequence: '',
      disableCode: true,
      disableName: true,
      disableDescription: true,
      disableSequence: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getProductGroupingSelected();
    }
  }

  getProductGroupingSelected = async () => {
    if (this.props.location.state.productGrouping) {
      const { productGrouping } = this.props.location.state;

      const productGroupingSelected = await this.props.getProductGrouping(productGrouping.id);

      if (productGroupingSelected) {
        this.setState({
          productGroupingId: productGrouping.id,
          code: productGroupingSelected.code,
          name: productGroupingSelected.name,
          description: productGroupingSelected.description,
          sequence: productGroupingSelected.sequence,
        });
      }
    }
  };

  setMode = () => {
    let mode;

    if (this.props.isCreate) {
      mode = 'create';
    } else if (this.props.isCopy) {
      mode = 'copy';
    } else if (this.props.isEdit) {
      mode = 'edit';
    } else if (this.props.isDetail) {
      mode = 'detail';
    }

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableDescription: false,
        disableSequence: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableDescription: false,
        disableSequence: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  };

  createProductGroupingSubmit = async () => {
    const {
      code, name, description, sequence,
    } = this.state;

    try {
      const newProductGrouping = await this.props.createProductGrouping({
        code,
        name,
        description,
        sequence,
      });

      if (newProductGrouping && newProductGrouping.success === false) {
        await addNotification(
          'danger',
          I18n.t('BEE4004' /* Novo Agrupamento de Produtos */),
          I18n.t('BEE4023' /* Erro ao incluir Agrupamento de Produtos */),
          'top-right',
        );
      } else {
        await addNotification(
          'success',
          I18n.t('BEE4004' /* Novo Agrupamento de Produtos */),
          I18n.t('BEE4022' /* Agrupamento de produtos criado com sucesso. */),
          'top-right',
        );

        this.props.history.push(ROUTES.PRODUCT_GROUPING_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE4004' /* Novo Agrupamento de Produtos */),
            `${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE4004' /* Novo Agrupamento de Produtos */),
            I18n.t('BEE4023' /* Erro ao incluir Agrupamento de Produtos */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE4004' /* Novo Agrupamento de Produtos */),
          I18n.t('BEE4023' /* Erro ao incluir Agrupamento de Produtos */),
          'top-right',
        );
      }
    }
  };

  updateProductGroupingSubmit = async () => {
    const {
      productGroupingId,
      code,
      name,
      description,
      sequence,
    } = this.state;

    try {
      const updProductGrouping = await this.props.updateProductGrouping({
        productGroupingId,
        code,
        name,
        description,
        sequence,
      });

      if (updProductGrouping) {
        await addNotification(
          'success',
          I18n.t('BEE4027' /* Atualizar Agrupamento de Produtos */),
          I18n.t('BEE4028' /* Agrupamento de Produtos atualizado com sucesso. */),
          'top-right',
        );

        this.props.history.push(ROUTES.PRODUCT_GROUPING_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE4027' /* Atualizar Agrupamento de Produtos */),
            `${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE4027' /* Atualizar Agrupamento de Produtos */),
            I18n.t('BEE4029' /* Erro ao atualizar o Agrupamento de Produtos */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE4027' /* Atualizar Agrupamento de Produtos */),
          I18n.t('BEE4029' /* Erro ao atualizar o Agrupamento de Produtos */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      code,
      name,
      description,
      sequence,
    } = this.state;

    const {
      mode,
      disableCode,
      disableName,
      disableDescription,
      disableSequence,
      showCreate, showUpdate, showCancel,
    } = this.state;

    let title = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE4004' /* Novo Agrupamento de Produtos */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE4005' /* Editar Agrupamento de Produtos */);
    } else if (mode === 'detail') {
      title = I18n.t('BEE4006' /* Detalhes do Agrupamento de Produtos */);
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE3997' /* Agrupamento de Produtos */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  <Form
                    noPanel
                    setValue={this.setValue}
                    setValueDrop={this.setValueDrop}
                    inputs={(formContext) => ([
                      formContext.createInput(
                        code,
                        'code',
                        `${I18n.t('BEE82' /* Código */)}:`,
                        I18n.t('BEE3686' /* Informe o código */),
                        'text',
                        true,
                        disableCode,
                      ),
                      formContext.createInput(
                        name,
                        'name',
                        `${I18n.t('BEE1853' /* Nome */)}:`,
                        I18n.t('BEE3718' /* Informe o nome */),
                        'text',
                        true,
                        disableName,
                      ),
                      formContext.createInput(
                        description,
                        'description',
                        `${I18n.t('BEE277' /* Descrição */)}:`,
                        I18n.t('BEE3477' /* Informe a descrição */),
                        'text',
                        true,
                        disableDescription,
                      ),
                      formContext.createInput(
                        sequence,
                        'sequence',
                        `${I18n.t('BEE3998' /* Sequência de Agrupamento */)}:`,
                        I18n.t('BEE3865' /* Informe a sequência */),
                        'number',
                        true,
                        disableSequence,
                      ),
                    ])}
                  />
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.PRODUCT_GROUPING_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? (
                        <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                          {I18n.t('BEE99' /* Cancelar */)}
                        </OneClickButton>
                      )
                      : (
                        <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                          {I18n.t('BEE137' /* Voltar */)}
                        </OneClickButton>
                      ))}
                  </Link>
                  {(showCreate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createProductGroupingSubmit()}
                    >
                      {I18n.t('BEE138' /* Criar */)}
                    </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateProductGroupingSubmit()}
                    >
                      {I18n.t('BEE139' /* Atualizar */)}
                    </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  getProductGrouping: (productGroupingId) => dispatch(getProductGrouping(productGroupingId)),
  createProductGrouping: (productGrouping) => dispatch(createProductGrouping(productGrouping)),
  updateProductGrouping: (productGrouping) => dispatch(updateProductGrouping(productGrouping)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductGroupingForm));

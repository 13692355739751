import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { createConfig, getOptionsForm } from '../../../../app/store/actions/orderTypeConfig';

import OneClickButton from '../../../../components/form/button';
import Form from '../../../../components/form/form';
import addNotification from '../../../../components/notification';
import {
  Panel,
  PanelBody, PanelFooter,
  PanelHeader,
} from '../../../../components/panel/panel';

import ROUTES from '../../../../config/routes';

import OrderTypeConfigHelpers from '../../../../helpers/orderTypeConfig';

class OrderTypeConfigForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      showCreate: false,
      showCancel: false,
      disableBranchCode: true,
      branchName: '',
      branchCode: '',
      disableDepositCode: true,
      depositName: '',
      depositCode: '',
      disableMovementType: true,
      movementType: 1,
      movementTypeName: '',
      disableCustomerSupplierCode: true,
      customerSupplierCode: '',
      customerSupplierName: '',
      disableCarrierCode: true,
      carrierCode: '',
      carrierName: '',
      disableDeliveryType: true,
      deliveryType: 0,
      deliveryTypeName: '',
      disableStockGroupCode: true,
      stockGroupCode: '',
      stockGroupName: '',
      disableCfop: true,
      cfop: '',
      disableOrderType: true,
      orderTypeCode: '',
      orderTypeName: '',
      disableNote: true,
      note: '',
      disableStatus: true,
      status: 0,
      listBranch: [],
      listDeposit: [],
      listType: [],
      listDelivery: [],
      listStockGroup: [],
      listOrderType: [],
      listStatus: [],
      listCarrier: [],
    };
  }

  async componentDidMount() {
    await this.getOptions();
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getOrderTypeSelected(false);
    }
  }

  getOptions = async () => {
    const {
      listBranches = [], listDeposits = [], listCarriers = [],
      listSuppliers = [], listCustomers = [], listOrderTypes = [], listStockGroups = [],
    } = await this.props.getOptionsForm();

    const branchCodeUser = await this.props.userLogged.mainBranch;
    const selectBranch = listBranches.find((element) => element.value === branchCodeUser);

    if (selectBranch) {
      this.setState({
        branchCode: selectBranch.value,
        branchName: selectBranch.label,
      });
    }

    this.setState({
      listBranch: listBranches,
      listDeposit: listDeposits,
      listCarrier: [{ value: '*', label: I18n.t('BEE793' /* Todos */) }, ...listCarriers],
      listSupplier: [{ value: '*', label: I18n.t('BEE793' /* Todos */) }, ...listSuppliers],
      listCustomer: [{ value: '*', label: I18n.t('BEE793' /* Todos */) }, ...listCustomers],
      listOrderType: listOrderTypes,
      listType: OrderTypeConfigHelpers.movementType(),
      movementTypeName: OrderTypeConfigHelpers.movementType(1),
      listDelivery: OrderTypeConfigHelpers.deliveryType(),
      deliveryTypeName: OrderTypeConfigHelpers.deliveryType(0),
      listStatus: OrderTypeConfigHelpers.statusConfig(),
      statusName: OrderTypeConfigHelpers.statusConfig(0),
      listStockGroup: [{ value: '*', label: I18n.t('BEE793' /* Todos */) }, ...listStockGroups],
    });
  };

  setBranch = (branchCode) => {
    const { listBranch } = this.state;
    const selectBranch = listBranch.find((element) => element.value === branchCode);

    if (selectBranch) {
      this.setState({
        branchCode: selectBranch.value,
        branchName: selectBranch.label,
      });
    }
  };

  setDeposit = (depositCode) => {
    const { listDeposit } = this.state;
    const selectDeposit = listDeposit.find((element) => element.value === depositCode);

    if (selectDeposit) {
      this.setState({
        depositCode: selectDeposit.value,
        depositName: selectDeposit.label,
      });
    }
  };

  setCarrier = (carrierCode) => {
    const { listCarrier } = this.state;
    const selectCarrier = listCarrier.find((element) => element.value === carrierCode);

    if (selectCarrier) {
      this.setState({
        carrierCode: selectCarrier.value,
        carrierName: selectCarrier.label,
      });
    }
  };

  setOrderType = (orderTypeCode) => {
    const { listOrderType } = this.state;
    const selectOrderType = listOrderType.find((element) => element.value === orderTypeCode);

    if (selectOrderType) {
      this.setState({
        orderTypeCode: selectOrderType.value,
        orderTypeName: selectOrderType.label,
      });
    }
  };

  setCustomerSupplier = (customerSupplierCode) => {
    const { movementType } = this.state;
    const { listCustomer, listSupplier } = this.state;
    const list = movementType === 2 ? listCustomer : listSupplier;
    const selectCustomerSupplier = list.find((element) => element.value === customerSupplierCode);

    if (selectCustomerSupplier) {
      this.setState({
        customerSupplierCode: selectCustomerSupplier.value,
        customerSupplierName: selectCustomerSupplier.label,
      });
    }
  };

  setStockGroup = (stockGroupCode) => {
    const { listStockGroup } = this.state;
    const selectStockGroup = listStockGroup.find((element) => element.value === stockGroupCode);

    if (selectStockGroup) {
      this.setState({
        stockGroupCode: selectStockGroup.value,
        stockGroupName: selectStockGroup.label,
      });
    }
  };

  setCfop = (cfop) => {
    const { mode } = this.state;

    this.setState({
      cfop,
      disableCfop: mode === 'detail' || cfop === '*',
    });
  };

  getOrderTypeSelected = async () => {
    if (this.props.location.state.orderTypeConfig) {
      const { orderTypeConfig } = this.props.location.state;
      const orderConfig = await orderTypeConfig;

      if (orderConfig) {
        this.setBranch(orderConfig.branchCode);
        this.setDeposit(orderConfig.depositCode);
        this.setCarrier(orderConfig.carrierCode);
        this.setOrderType(orderConfig.orderType);
        this.setCustomerSupplier(orderConfig.customerSupplierCode);
        this.setStockGroup(orderConfig.stockGroupCode);
        this.setCfop(orderConfig.cfop);

        this.setState({
          movementType: orderConfig.movementType,
          movementTypeName: OrderTypeConfigHelpers.movementType(orderConfig.movementType),
          deliveryType: orderConfig.deliveryType,
          deliveryTypeName: OrderTypeConfigHelpers.deliveryType(orderConfig.deliveryType),
          status: orderConfig.status,
          statusName: OrderTypeConfigHelpers.statusConfig(orderConfig.status),
          note: orderConfig.note,
        });
      }
    }
  };

  setMode = () => {
    let mode;
    if (this.props.isCreate) mode = 'create';
    else if (this.props.isCopy) mode = 'copy';
    else if (this.props.isEdit) mode = 'edit';
    else if (this.props.isDetail) mode = 'detail';
    else mode = '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableMovementType: false,
        disableDepositCode: false,
        disableBranchCode: false,
        disableCustomerSupplierCode: false,
        disableCarrierCode: false,
        disableDeliveryType: false,
        disableStockGroupCode: false,
        disableCfop: false,
        disableOrderType: false,
        disableStatus: false,
        disableNote: false,
        showCreate: true,
        showCancel: true,
      });
    }
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  setValueDrop = async (attr, value) => {
    if (attr === 'deliveryType' || attr === 'movementType') {
      await this.setState({
        [`${attr}`]: value ? value.value : '',
        [`${attr}Name`]: value ? value.label : '',
      });

      if (attr === 'movementType') {
        await this.setState({
          customerSupplierCode: '',
          customerSupplierName: '',
        });
      }
    }

    await this.setState({
      [`${attr}Code`]: value ? value.value : '',
      [`${attr}Name`]: value ? value.label : '',
    });
  };

  createInputButton = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    tooltip,
    inputSize = 5,
    keypressFunction = undefined,
    id = null,
    maxLength = 524288,
    className = [],
    buttonLabel,
    buttonFunction,
  ) => (
    // eslint-disable-next-line max-len
    <div key={attr} style={{ height: 'auto' }} className={className.length > 0 ? className[0] : 'form-group row m-b-15 m'}>
      {label
        && (
          <label
            data-required-indicator={required}
            className={
            (className.length > 1 && className[1])
            || `col-form-label ${this.props.leftType ? 'col-md-2' : 'col-md-4'}`
          }
            htmlFor={attr}
          >
            <span {...tooltip ? { 'data-tip': true, 'data-for': tooltip.id } : {}}>
              {label}
              {' '}
              {(required && (this.props.mode !== 'detail')) && <span style={{ color: 'red' }}>*</span>}
              {!!tooltip && (
                <>
                  {' '}
                  {' '}
                  <i className="fas fa-info-circle" />
                </>
              )}
            </span>
          </label>
        )}
      {!!tooltip && !!tooltip.content && (
        <ReactTooltip
          id={tooltip.id}
          type={tooltip.type || 'dark'}
          effect={tooltip.effect || 'solid'}
          place={tooltip.place || 'top'}
        >
          <span>{tooltip.content}</span>
        </ReactTooltip>
      )}
      <div className={`${className.length > 2 ? className[2] : `col-md-${inputSize}`} input-group`}>
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
        />
        {buttonFunction && buttonLabel && (
          <div className="input-group-prepend">
            <button
              type="button"
              className="btn btn-primary form-control"
              onClick={() => buttonFunction()}
            >
              {buttonLabel}
            </button>
          </div>
        )}
      </div>
    </div>
  );

  clickCfopAll = () => {
    if (this.state.cfop === '*') {
      this.setState({
        cfop: '',
        disableCfop: false,
      });
    } else {
      this.setState({
        cfop: '*',
        disableCfop: true,
      });
    }
  };

  createConfig = async () => {
    const {
      branchCode, movementType, depositCode, customerSupplierCode, carrierCode, deliveryType,
      stockGroupCode, cfop, orderTypeCode, status, note,
    } = this.state;

    try {
      const newOrder = await this.props.createConfigSubmit({
        movementType,
        branchCode,
        depositCode,
        customerSupplierCode,
        carrierCode,
        deliveryType,
        stockGroupCode,
        cfop,
        orderTypeCode,
        status,
        note,
      });

      if (newOrder) {
        if (newOrder.success === false) {
          await addNotification(
            'danger',
            I18n.t('BEE4070' /* Nova Regra Tipo de Documento */),
            I18n.t('BEE4071' /* Erro ao incluir a regra! */),
            'top-right',
          );
        } else {
          await addNotification(
            'success',
            I18n.t('BEE4070' /* Nova Regra Tipo de Documento */),
            I18n.t('BEE4072' /* Regra incluída com sucesso! */),
            'top-right',
          );
          this.props.history.push(ROUTES.ORDER_TYPE_CONFIG_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;
        await addNotification(
          'danger',
          I18n.t('BEE4070' /* Nova Regra Tipo de Documento */),
          `${error.code} - ${error.message || error.details}`,
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      mode, showCancel, showCreate, listBranch, listDeposit, listType, listDelivery,
      listStockGroup, listOrderType, listStatus, listCarrier, listSupplier, listCustomer,
    } = this.state;

    const {
      movementType, depositCode, depositName, branchCode, branchName, movementTypeName, note,
      customerSupplierCode, customerSupplierName, carrierCode, carrierName, deliveryType, deliveryTypeName,
      stockGroupCode, stockGroupName, cfop, orderTypeCode, orderTypeName, status, statusName,
    } = this.state;

    const {
      disableBranchCode, disableMovementType, disableDepositCode, disableCustomerSupplierCode,
      disableCarrierCode, disableDeliveryType, disableStockGroupCode, disableCfop, disableOrderType,
      disableStatus, disableNote,
    } = this.state;

    let title = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE2682' /* Nova Regra */);
    } else if (mode === 'detail') {
      title = I18n.t('BEE2681' /* Detalhes da Regra */);
    }

    let showButtonAll = false;

    if (mode === 'create' || mode === 'copy') {
      showButtonAll = true;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE4069' /* Configurador de Tipos de Documentos */)}</li>
            <li className="breadcrumb-item active">{title}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <Panel className="panel-with-tabs">
              <PanelHeader noButton />
              <PanelBody>
                <Form
                  noPanel
                  setValue={this.setValue}
                  setValueDrop={this.setValueDrop}
                  inputs={(formContext) => ([
                    formContext.createSelectDropDown(
                      { value: branchCode, label: branchName },
                      'branch',
                      `${I18n.t('BEE145' /* Filial */)}:`,
                      listBranch,
                      disableBranchCode,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      true,
                    ),
                    formContext.createSelectDropDown(
                      { value: movementType, label: movementTypeName },
                      'movementType',
                      `${I18n.t('BEE3531' /* Tipo Movimentação */)}:`,
                      listType,
                      disableMovementType,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      true,
                    ),
                    formContext.createSelectDropDown(
                      { value: depositCode, label: depositName },
                      'deposit',
                      `${I18n.t('BEE182' /* Depósito */)}:`,
                      listDeposit,
                      disableDepositCode,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      true,
                    ),
                    formContext.createSelectDropDown(
                      { value: customerSupplierCode, label: customerSupplierName },
                      'customerSupplier',
                      movementType === 2
                        ? `${I18n.t('BEE50' /* Cliente */)}:`
                        : `${I18n.t('BEE342' /* Fornecedor */)}:`,
                      movementType === 2 ? listCustomer : listSupplier,
                      disableCustomerSupplierCode,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      true,
                    ),
                    formContext.createSelectDropDown(
                      { value: carrierCode, label: carrierName },
                      'carrier',
                      `${I18n.t('BEE301' /* Transportadora */)}:`,
                      listCarrier,
                      disableCarrierCode,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      true,
                    ),
                    formContext.createSelectDropDown(
                      { value: deliveryType, label: deliveryTypeName },
                      'deliveryType',
                      `${I18n.t('BEE1098' /* Tipo de Entrega */)}:`,
                      listDelivery,
                      disableDeliveryType,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      true,
                    ),
                    formContext.createSelectDropDown(
                      { value: stockGroupCode, label: stockGroupName },
                      'stockGroup',
                      `${I18n.t('BEE4009' /* Grupo de Estoque */)}:`,
                      listStockGroup,
                      disableStockGroupCode,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      true,
                    ),
                    this.createInputButton(
                      cfop,
                      'cfop',
                      `${I18n.t('BEE4073' /* Operação Fiscal */)}:`,
                      '',
                      'text',
                      mode !== 'detail',
                      disableCfop,
                      null,
                      5,
                      undefined,
                      null,
                      undefined,
                      undefined,
                      cfop === '*' ? 'Limpar' : 'Todos',
                      showButtonAll && this.clickCfopAll,
                    ),
                    formContext.createSelectDropDown(
                      { value: orderTypeCode, label: orderTypeName },
                      'orderType',
                      `${I18n.t('BEE2997' /* Tipo de Documento */)}:`,
                      listOrderType,
                      disableOrderType,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      true,
                    ),
                    formContext.createSelectDropDown(
                      { value: status, label: statusName },
                      'status',
                      `${I18n.t('BEE224' /* Status */)}:`,
                      listStatus,
                      disableStatus,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      true,
                    ),
                    formContext.createTextArea(
                      note,
                      'note',
                      `${I18n.t('BEE135' /* Observação */)}:`,
                      '',
                      4,
                      false,
                      disableNote,
                    ),
                  ])}
                />
              </PanelBody>
              <PanelFooter>
                <Link to={{ pathname: ROUTES.ORDER_TYPE_CONFIG_LIST, state: { dice: this.props.location.dice } }}>
                  {(showCancel
                    ? (
                      <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                        {I18n.t('BEE99' /* Cancelar */)}
                      </OneClickButton>
                    )
                    : (
                      <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                        {I18n.t('BEE137' /* Voltar */)}
                      </OneClickButton>
                    ))}
                </Link>
                {(showCreate
                  && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createConfig()}
                    >
                      {I18n.t('BEE138' /* Criar */)}

                    </OneClickButton>
                  )
                )}
              </PanelFooter>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged && state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  createConfigSubmit: (newConfig) => dispatch(createConfig(newConfig)),
  getOptionsForm: () => dispatch(getOptionsForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderTypeConfigForm));

import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import {
  Nav, NavItem, NavLink,
  TabContent, TabPane,
} from 'reactstrap';

import StockGroup from '../../../../app/server/stockGroup';

import { getMaterialsFamilyOptions } from '../../../../app/store/actions/materialsFamily';
import {
  createProduct, getProduct, updateProduct,
} from '../../../../app/store/actions/products';
import { getUnitsMeasureOptions } from '../../../../app/store/actions/unitsMeasure';

import OneClickButton from '../../../../components/form/button';
import { getProductGroupingOptions } from '../../../../app/store/actions/productsGrouping';
import Form from '../../../../components/form/form';
import addNotification from '../../../../components/notification';
import {
  Panel,
  PanelBody, PanelFooter,
  PanelHeader,
} from '../../../../components/panel/panel';

import ROUTES from '../../../../config/routes';

import ProductHelpers from '../../../../helpers/products';

class ProductForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      activeTab: '1',
      mode: 'detail',
      productCode: '',
      name: '',
      fullName: '',
      complementCode: '',
      complementInfo: '',
      status: 1,
      note: '',
      productSelected: null,
      disableProductCode: true,
      disableName: true,
      disableFullName: true,
      disableComplementCode: true,
      disableComplementInfo: true,
      disableStatus: true,
      disableNote: true,
      unitMeasure: '',
      unitMeasureName: '',
      materialFamily: '',
      materialFamilyName: '',
      productGrouping: '',
      productGroupingName: '',
      stockGroup: null,
      stockGroupName: '',
      volume: 0,
      height: 0,
      width: 0,
      length: 0,
      grossWeight: 0,
      netWeight: 0,
      conversionFactor: 0,
      multipleSale: 0,
      productControlType: 1,
      stockControlType: 1,
      origin: 1,
      taxClassification: '',
      businessUnit: '',
      level1: '',
      level2: '',
      level3: '',
      level4: '',
      level5: '',
      controlExpirationDate: false,
      inboundPreExpiration: 0,
      outboundPreExpiration: 0,
      lifeDays: 0,
      overdueDays: 0,
      minimalLeftOver: 0,
      retailLoss: 0,
      disableUnitMeasure: true,
      disableMaterialFamily: true,
      disableProductGrouping: true,
      disableStockGroup: true,
      disableHeight: true,
      disableWidth: true,
      disableLength: true,
      disableGrossWeight: true,
      disableNetWeight: true,
      disableConversionFactor: true,
      disableMultipleSale: true,
      disableProductControlType: true,
      disableStockControlType: true,
      disableOrigin: true,
      disableTaxClassification: true,
      disableBusinessUnit: true,
      disableLevel1: true,
      disableLevel2: true,
      disableLevel3: true,
      disableLevel4: true,
      disableLevel5: true,
      disableControlExpirationDate: true,
      disableInboundPreExpiration: true,
      disableOutboundPreExpiration: true,
      disableLifeDays: true,
      disableOverdueDays: true,
      disableMinimalLeftOver: true,
      disableRetailLoss: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      showChangeStockControl: false,
      listUnitMeasure: '',
      listMaterialFamily: '',
      listProductGrouping: '',
      listStockGroup: '',
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getProductSelected();
    }

    const listUnitMeasure = await this.props.getUnitsMeasureOptions();
    const listMaterialFamily = await this.props.getMaterialsFamilyOptions();
    const listProductGrouping = await this.props.getProductGroupingOptions();
    const listStockGroup = await StockGroup.getStockGroupOptions();

    this.setState({
      listUnitMeasure,
      listMaterialFamily,
      listProductGrouping,
      listStockGroup,
    });
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;

    let value;

    if (target.type === 'checkbox') {
      value = target.checked;
    } else if (target.type === 'radio') {
      value = parseInt(target.value, 10);
    } else {
      value = target.value;
    }

    this.setState({
      [name]: value,
    });
  }

  getProductSelected = async () => {
    if (this.props.location.state.product) {
      const { product } = this.props.location.state;

      const productSelected = await this.props.getProduct(product.id);

      if (productSelected) {
        this.setState({
          productSelected,
          productId: productSelected.id,
          productCode: productSelected.productCode,
          name: productSelected.name,
          fullName: productSelected.fullName,
          complementCode: productSelected.complementCode,
          complementInfo: productSelected.complementInfo,
          status: productSelected.status,
          note: productSelected.note,
          unitMeasure: productSelected.unitMeasure,
          unitMeasureName: productSelected.unitMeasureName,
          materialFamily: productSelected.materialFamily,
          materialFamilyName: productSelected.materialFamilyName,
          productGrouping: productSelected.productGrouping,
          productGroupingName: productSelected.productGroupingName,
          stockGroup: productSelected.stockGroup,
          stockGroupName: productSelected.stockGroupName,
          volume: parseFloat(productSelected.volume),
          height: parseFloat(productSelected.height),
          width: parseFloat(productSelected.width),
          length: parseFloat(productSelected.length),
          grossWeight: parseFloat(productSelected.grossWeight),
          netWeight: parseFloat(productSelected.netWeight),
          conversionFactor: parseFloat(productSelected.conversionFactor),
          multipleSale: parseFloat(productSelected.multipleSale),
          productControlType: productSelected.productControlType,
          stockControlType: productSelected.stockControlType,
          origin: productSelected.origin,
          taxClassification: productSelected.taxClassification,
          businessUnit: productSelected.businessUnit,
          level1: productSelected.level1,
          level2: productSelected.level2,
          level3: productSelected.level3,
          level4: productSelected.level4,
          level5: productSelected.level5,
          controlExpirationDate: productSelected.controlExpirationDate,
          inboundPreExpiration: productSelected.inboundPreExpiration,
          outboundPreExpiration: productSelected.outboundPreExpiration,
          lifeDays: productSelected.lifeDays,
          overdueDays: productSelected.overdueDays,
          minimalLeftOver: parseFloat(productSelected.minimalLeftOver),
          retailLoss: parseFloat(productSelected.retailLoss),
        });
      }
    }
  };

  setMode = () => {
    let mode;

    if (this.props.isCreate) {
      mode = 'create';
    } else if (this.props.isCopy) {
      mode = 'copy';
    } else if (this.props.isEdit) {
      mode = 'edit';
    } else if (this.props.isDetail) {
      mode = 'detail';
    } else {
      mode = '';
    }

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableProductCode: false,
        disableName: false,
        disableFullName: false,
        disableComplementCode: false,
        disableComplementInfo: false,
        disableStatus: false,
        disableNote: false,
        disableUnitMeasure: false,
        disableMaterialFamily: false,
        disableProductGrouping: false,
        disableStockGroup: false,
        disableHeight: false,
        disableWidth: false,
        disableLength: false,
        disableGrossWeight: false,
        disableNetWeight: false,
        disableConversionFactor: false,
        disableMultipleSale: false,
        disableProductControlType: false,
        disableStockControlType: this.props.stockControlType !== 1,
        disableOrigin: false,
        disableTaxClassification: false,
        disableBusinessUnit: false,
        disableLevel1: false,
        disableLevel2: false,
        disableLevel3: false,
        disableLevel4: false,
        disableLevel5: false,
        disableControlExpirationDate: false,
        disableInboundPreExpiration: false,
        disableOutboundPreExpiration: false,
        disableLifeDays: false,
        disableOverdueDays: false,
        disableMinimalLeftOver: false,
        disableRetailLoss: false,
        showCreate: true,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableProductCode: true,
        disableName: true,
        disableFullName: true,
        disableComplementCode: true,
        disableComplementInfo: true,
        disableStatus: false,
        disableNote: false,
        disableUnitMeasure: true,
        disableMaterialFamily: true,
        disableProductGrouping: false,
        disableStockGroup: false,
        disableHeight: false,
        disableWidth: false,
        disableLength: false,
        disableGrossWeight: false,
        disableNetWeight: false,
        disableConversionFactor: true,
        disableMultipleSale: true,
        disableProductControlType: true,
        disableStockControlType: this.props.stockControlType !== 1,
        disableOrigin: true,
        disableTaxClassification: true,
        disableBusinessUnit: true,
        disableLevel1: true,
        disableLevel2: true,
        disableLevel3: true,
        disableLevel4: true,
        disableLevel5: true,
        disableControlExpirationDate: false,
        disableInboundPreExpiration: false,
        disableOutboundPreExpiration: false,
        disableLifeDays: false,
        disableOverdueDays: false,
        disableMinimalLeftOver: false,
        disableRetailLoss: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  createToggleBox = (item, attr, label, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={attr} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <div className="row" style={{ marginTop: '8px', marginLeft: '0px' }}>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id={`customCheck${attr}`}
              className="custom-control-input"
              disabled={disabled}
              checked={item}
              name={attr}
              onChange={this.handleInputChange}
            />
            <label className="custom-control-label" htmlFor={`customCheck${attr}`}>{}</label>
          </div>
        </div>
      </div>
    </div>
  );

  createRadioSet = (value, attr, label, items, disabled) => (
    <div className="form-group row m-b-15">
      <label htmlFor={attr} className="col-md-4 col-form-label">{label}</label>
      <div className="col-md-5">
        {items.map((item, index) => (
          <div className="radio radio-css" key={index} style={{ textAlign: 'left' }}>
            <input
              type="radio"
              id={`cssRadio${attr}${item.value}`}
              value={item.value}
              name={attr}
              checked={item.value === value}
              disabled={disabled}
              onChange={this.handleInputChange}
            />
            <label htmlFor={`cssRadio${attr}${item.value}`}>{item.label}</label>
          </div>
        ))}
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
    if (attr === 'height' || attr === 'width' || attr === 'length') this.volumeCalc();
  };

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  };

  volumeCalc = () => {
    const { height, width, length } = this.state;

    if (parseFloat(height) > 0 && parseFloat(width) > 0 && parseFloat(length) > 0) {
      const volume = parseFloat(height) * parseFloat(width) * parseFloat(length);

      this.setValue('volume', volume);
    } else {
      this.setValue('volume', 0);
    }
  };

  createProductSubmit = async () => {
    const {
      productCode, name, fullName, complementCode,
      complementInfo, status, unitMeasure, materialFamily, productGrouping, productControlType, stockControlType,
      volume, height, width, length, grossWeight, netWeight, origin, taxClassification, stockGroup,
      conversionFactor, multipleSale,
      businessUnit, level1, level2, level3, level4, level5, note, controlExpirationDate,
      inboundPreExpiration, outboundPreExpiration, lifeDays, overdueDays, minimalLeftOver, retailLoss,
    } = this.state;

    try {
      const newProduct = await this.props.createProduct({
        productCode,
        name,
        fullName,
        complementCode,
        complementInfo,
        status,
        unitMeasure,
        materialFamily,
        productGrouping,
        stockGroup,
        productControlType,
        stockControlType,
        volume,
        height,
        width,
        length,
        grossWeight,
        netWeight,
        origin,
        taxClassification,
        conversionFactor,
        multipleSale,
        businessUnit,
        level1,
        level2,
        level3,
        level4,
        level5,
        controlExpirationDate,
        inboundPreExpiration: inboundPreExpiration || 0,
        outboundPreExpiration: outboundPreExpiration || 0,
        lifeDays,
        overdueDays,
        minimalLeftOver,
        retailLoss,
        note,
      });

      if (newProduct) {
        if (newProduct.success && newProduct.success === false) {
          await addNotification(
            'danger',
            I18n.t('BEE362' /* Novo Produto */),
            I18n.t('BEE363', { 0: productCode } /* Erro ao incluir o Produto %{0}! */),
            'top-right',
          );
        } else {
          await addNotification(
            'success',
            I18n.t('BEE362' /* Novo Produto */),
            I18n.t('BEE364', { 0: productCode } /* Novo produto %{0} incluído com sucesso! */),
            'top-right',
          );

          this.props.history.push(ROUTES.PRODUCT_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE362' /* Novo Produto */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE362' /* Novo Produto */),
            I18n.t('BEE363', { 0: productCode } /* Erro ao incluir o Produto %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE362' /* Novo Produto */),
          I18n.t('BEE363', { 0: productCode } /* Erro ao incluir o Produto %{0}! */),
          'top-right',
        );
      }
    }
  };

  updateProductValid = async () => {
    // Controle Estoque pelo BeeStock
    if (this.props.stockControlType === 1) {
      const { stockControlType, productSelected } = this.state;

      if (productSelected.stockControlType !== stockControlType) {
        this.setState({
          showChangeStockControl: true,
        });
      } else {
        this.updateProductSubmit();
      }
    } else {
      this.updateProductSubmit();
    }
  };

  updateProductSubmit = async () => {
    const {
      productId, productCode, name, fullName, complementCode,
      complementInfo, status, unitMeasure, materialFamily, productGrouping, productControlType, stockControlType,
      volume, height, width, length, grossWeight, netWeight, origin, taxClassification, stockGroup,
      conversionFactor, multipleSale,
      businessUnit, level1, level2, level3, level4, level5, controlExpirationDate,
      inboundPreExpiration, outboundPreExpiration, lifeDays, overdueDays, minimalLeftOver, retailLoss, note,
    } = this.state;

    try {
      const updProduct = await this.props.updateProduct({
        productId,
        productCode,
        name,
        fullName,
        complementCode,
        complementInfo,
        status,
        unitMeasure,
        materialFamily,
        productGrouping,
        stockGroup,
        productControlType,
        stockControlType,
        volume,
        height,
        width,
        length,
        grossWeight,
        netWeight,
        origin,
        taxClassification,
        conversionFactor,
        multipleSale,
        businessUnit,
        level1,
        level2,
        level3,
        level4,
        level5,
        controlExpirationDate,
        inboundPreExpiration: inboundPreExpiration || 0,
        outboundPreExpiration: outboundPreExpiration || 0,
        lifeDays,
        overdueDays: overdueDays || 0,
        minimalLeftOver,
        retailLoss,
        note,
      });

      this.setState({ showChangeStockControl: false });

      if (updProduct) {
        if (updProduct.success && updProduct.success === false) {
          await addNotification(
            'danger',
            I18n.t('BEE365' /* Atualizar Produto */),
            I18n.t('BEE366', { 0: productCode } /* Erro ao atualizar o produto %{0}! */),
            'top-right',
          );
        } else {
          await addNotification(
            'success',
            I18n.t('BEE365' /* Atualizar Produto */),
            I18n.t('BEE367', { 0: productCode } /* Atualização do produto %{0} efetuada com sucesso! */),
            'top-right',
          );

          this.props.history.push(ROUTES.PRODUCT_LIST);
        }
      }
    } catch (err) {
      this.setState({ showChangeStockControl: false });

      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE365' /* Atualizar Produto */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE365' /* Atualizar Produto */),
            I18n.t('BEE366', { 0: productCode } /* Erro ao atualizar o produto %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE365' /* Atualizar Produto */),
          I18n.t('BEE366', { 0: productCode } /* Erro ao atualizar o produto %{0}! */),
          'top-right',
        );
      }
    }
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      productCode, name, fullName, complementCode, complementInfo, status, note,
      disableProductCode, disableName, disableFullName, disableComplementCode,
      disableComplementInfo, disableStatus, disableNote,
    } = this.state;

    const {
      unitMeasure, unitMeasureName, materialFamily, materialFamilyName, stockGroup, stockGroupName,
      disableUnitMeasure, disableMaterialFamily, disableStockGroup, disableHeight, disableWidth, disableLength,
      disableGrossWeight, disableNetWeight, showChangeStockControl, productGrouping, productGroupingName,
      disableProductGrouping,
    } = this.state;

    const {
      productControlType, stockControlType, origin, taxClassification,
      businessUnit, level1, level2, level3, level4, level5,
      disableProductControlType, disableStockControlType, disableOrigin, disableTaxClassification, disableBusinessUnit,
      disableConversionFactor, disableMultipleSale, disableLevel1, disableLevel2, disableLevel3, disableLevel4,
      disableLevel5,
    } = this.state;

    const {
      controlExpirationDate, inboundPreExpiration, outboundPreExpiration, lifeDays, overdueDays,
      disableControlExpirationDate, disableInboundPreExpiration,
      disableOutboundPreExpiration, disableLifeDays, disableOverdueDays, disableMinimalLeftOver, disableRetailLoss,
    } = this.state;

    const {
      mode, showCreate, showUpdate, showCancel, listUnitMeasure, listMaterialFamily,
      listProductGrouping, listStockGroup,
    } = this.state;

    let {
      volume, height, width, length, grossWeight,
      netWeight, conversionFactor, multipleSale,
      minimalLeftOver, retailLoss,
    } = this.state;

    let title = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE362' /* Novo Produto */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE368' /* Editar Produto */);
    } else if (mode === 'detail') {
      title = I18n.t('BEE369' /* Detalhes Produto */);
    }

    if (mode === 'detail') {
      volume = parseFloat(volume).toFixed(3).replace('.', ',');
      height = parseFloat(height).toFixed(3).replace('.', ',');
      width = parseFloat(width).toFixed(3).replace('.', ',');
      length = parseFloat(length).toFixed(3).replace('.', ',');
      grossWeight = parseFloat(grossWeight).toFixed(4).replace('.', ',');
      netWeight = parseFloat(netWeight).toFixed(4).replace('.', ',');
      conversionFactor = parseFloat(conversionFactor).toFixed(5).replace('.', ',');
      multipleSale = parseFloat(multipleSale).toFixed(5).replace('.', ',');
      minimalLeftOver = parseFloat(minimalLeftOver).toFixed(3).replace('.', ',');
      retailLoss = parseFloat(retailLoss).toFixed(3).replace('.', ',');
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE362' /* Novo Produto */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            {/* <form> */}
            <Panel className="panel-with-tabs">
              <PanelHeader noButton />
              <PanelBody>
                <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab === '1' ? 'active' : ''}
                      onClick={() => {
                        this.toggleTab('1');
                      }}
                    >
                      <span className="d-sm-none">Tab 1</span>
                      <span className="d-sm-block d-none">{I18n.t('BEE191' /* Geral */)}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab === '2' ? 'active' : ''}
                      onClick={() => {
                        this.toggleTab('2');
                      }}
                    >
                      <span className="d-sm-none">Tab 2</span>
                      <span className="d-sm-block d-none">{I18n.t('BEE32' /* Estoque */)}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab === '3' ? 'active' : ''}
                      onClick={() => {
                        this.toggleTab('3');
                      }}
                    >
                      <span className="d-sm-none">Tab 3</span>
                      <span className="d-sm-block d-none">{I18n.t('BEE371' /* Fiscal */)}</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div className="row">
                      <div className="col-xl-12">
                        <Form
                          noPanel
                          setValue={this.setValue}
                          setValueDrop={this.setValueDrop}
                          inputs={(formContext) => ([
                            formContext.createInput(
                              productCode,
                              'productCode',
                              `${I18n.t('BEE82' /* Código */)}:`,
                              I18n.t('BEE2454' /* Informe o código do produto */),
                              'text',
                              true,
                              disableProductCode,
                            ),
                            formContext.createInput(
                              name,
                              'name',
                              `${I18n.t('BEE277' /* Descrição */)}:`,
                              I18n.t('BEE3705' /* Informe a descrição do produto */),
                              'text',
                              true,
                              disableName,
                            ),
                            formContext.createTextArea(
                              fullName,
                              'fullName',
                              `${I18n.t('BEE692' /* Descrição Completa */)}:`,
                              I18n.t('BEE3706' /* Informe a descrição completa do produto */),
                              4,
                              true,
                              disableFullName,
                            ),
                            formContext.createInput(
                              complementCode,
                              'complementCode',
                              `${I18n.t('BEE695' /* Código Complementar */)}:`,
                              I18n.t('BEE762' /* Informe o código complementar do produto */),
                              'text',
                              false,
                              disableComplementCode,
                            ),
                            formContext.createInput(
                              complementInfo,
                              'complementInfo',
                              `${I18n.t('BEE696' /* Informação Complementar */)}:`,
                              I18n.t('BEE763' /* Informe a informação complementar do produto */),
                              'text',
                              false,
                              disableComplementInfo,
                            ),
                            disableStatus
                              ? formContext.createInput(
                                ProductHelpers.productStatus(status),
                                'status',
                                `${I18n.t('BEE204' /* Situação */)}:`,
                                '',
                                'text',
                                false,
                                disableStatus,
                              )
                              : formContext.createSelect(
                                status,
                                'status',
                                `${I18n.t('BEE204' /* Situação */)}:`,
                                ProductHelpers.listProductStatus(),
                                disableStatus,
                                undefined,
                                undefined,
                                undefined,
                                true,
                              ),
                            formContext.createTextArea(
                              note || '',
                              'note',
                              `${I18n.t('BEE135' /* Observação */)}:`,
                              I18n.t('BEE764' /* Informe a observação do produto */),
                              3,
                              false,
                              disableNote,
                            ),
                          ])}
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="row">
                      <div className="col-xl-12">
                        <Form
                          noPanel
                          setValue={this.setValue}
                          setValueDrop={this.setValueDrop}
                          inputs={(formContext) => ([
                            disableUnitMeasure
                              ? formContext.createInput(
                                unitMeasureName,
                                'unitMeasure',
                                `${I18n.t('BEE1756' /* Unidade de Medida */)}:`,
                                '',
                                'text',
                                true,
                                disableUnitMeasure,
                              )
                              : formContext.createSelectDropDown(
                                { value: unitMeasure, label: unitMeasureName },
                                'unitMeasure',
                                `${I18n.t('BEE1756' /* Unidade de Medida */)}:`,
                                listUnitMeasure,
                                disableUnitMeasure,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                true,
                              ),
                            disableMaterialFamily
                              ? formContext.createInput(
                                materialFamilyName,
                                'materialFamily',
                                `${I18n.t('BEE2668' /* Famílial Material */)}:`,
                                '',
                                'text',
                                true,
                                disableMaterialFamily,
                              )
                              : formContext.createSelectDropDown(
                                { value: materialFamily, label: materialFamilyName },
                                'materialFamily',
                                `${I18n.t('BEE2668' /* Famílial Material */)}:`,
                                listMaterialFamily,
                                disableMaterialFamily,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                true,
                              ),
                            disableStockGroup
                              ? formContext.createInput(
                                stockGroupName,
                                'stockGroup',
                                `${I18n.t('BEE4009' /* Grupo de Estoque */)}:`,
                                '',
                                'text',
                                false,
                                disableStockGroup,
                              )
                              : formContext.createSelectDropDown(
                                { value: stockGroup, label: stockGroupName },
                                'stockGroup',
                                `${I18n.t('BEE4009' /* Grupo de Estoque */)}:`,
                                listStockGroup,
                                disableStockGroup,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                false,
                              ),
                            formContext.createInput(
                              volume,
                              'volume',
                              `${I18n.t('BEE562' /* Volume */)}:  (${I18n.t('BEE2326' /* cm³ */)})`,
                              '',
                              'text',
                              false,
                              true,
                            ),
                            formContext.createNumberFormat(
                              height,
                              'height',
                              `${I18n.t('BEE386' /* Altura */)}: (${I18n.t('BEE2325' /* CM */)})`,
                              0,
                              disableHeight,
                            ),
                            formContext.createNumberFormat(
                              width,
                              'width',
                              `${I18n.t('BEE387' /* Largura */)}: (${I18n.t('BEE2325' /* CM */)})`,
                              0,
                              disableWidth,
                            ),
                            formContext.createNumberFormat(
                              length,
                              'length',
                              `${I18n.t('BEE388' /* Comprimento */)}: (${I18n.t('BEE2325' /* CM */)})`,
                              0,
                              disableLength,
                            ),
                            formContext.createNumberFormat(
                              grossWeight,
                              'grossWeight',
                              `${I18n.t('BEE564' /* Peso Bruto */)}: (${I18n.t('BEE2327' /* G */)})`,
                              0,
                              disableGrossWeight,
                            ),
                            formContext.createNumberFormat(
                              netWeight,
                              'netWeight',
                              `${I18n.t('BEE563' /* Peso Líquido */)}: (${I18n.t('BEE2327' /* G */)})`,
                              0,
                              disableNetWeight,
                            ),
                            this.createToggleBox(
                              controlExpirationDate,
                              'controlExpirationDate',
                              `${I18n.t('BEE701' /* Controlar Vencimento */)}:`,
                              disableControlExpirationDate,
                            ),
                            formContext.createNumberFormat(
                              inboundPreExpiration,
                              'inboundPreExpiration',
                              `${I18n.t('BEE702' /* Entrada Pré Vencimento */)}:`,
                              0,
                              disableInboundPreExpiration,
                            ),
                            formContext.createNumberFormat(
                              outboundPreExpiration,
                              'outboundPreExpiration',
                              `${I18n.t('BEE703' /* Saída Pré Vencimento */)}:`,
                              0,
                              disableOutboundPreExpiration,
                            ),
                            formContext.createNumberFormat(
                              overdueDays,
                              'overdueDays',
                              `${I18n.t('BEE2018' /* Dias Vencidos */)}:`,
                              0,
                              disableOverdueDays,
                            ),
                            formContext.createNumberFormat(
                              lifeDays,
                              'lifeDays',
                              `${I18n.t('BEE705' /* Dias de Vida */)}:`,
                              0,
                              disableLifeDays,
                            ),
                            formContext.createNumberFormat(
                              minimalLeftOver,
                              'minimalLeftOver',
                              `${I18n.t('BEE706' /* Sobra Mínima */)}:`,
                              0,
                              disableMinimalLeftOver,
                            ),
                            formContext.createNumberFormat(
                              retailLoss,
                              'retailLoss',
                              `${I18n.t('BEE1790' /* Retalho Perda */)}:`,
                              0,
                              disableRetailLoss,
                            ),
                            formContext.createSelectDropDown(
                              { value: productGrouping, label: productGroupingName },
                              'productGrouping',
                              `${I18n.t('BEE3997' /* Agrupamento de Produtos */)}:`,
                              listProductGrouping,
                              disableProductGrouping,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              true,
                            ),
                          ])}
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="3">
                    <div className="row">
                      <div className="col-xl-12">
                        <Form
                          noPanel
                          setValue={this.setValue}
                          inputs={(formContext) => ([
                            this.createRadioSet(
                              productControlType,
                              'productControlType',
                              `${I18n.t('BEE707' /* Tipo de Controle do Produto */)}:`,
                              ProductHelpers.listProductControlType(),
                              disableProductControlType,
                            ),
                            this.createRadioSet(
                              stockControlType,
                              'stockControlType',
                              `${I18n.t('BEE708' /* Tipo de Controle de Estoque */)}:`,
                              ProductHelpers.listStockControlType(),
                              disableStockControlType,
                            ),
                            this.createRadioSet(
                              origin,
                              'origin',
                              `${I18n.t('BEE709' /* Origem */)}:`,
                              ProductHelpers.listProductOrigin(),
                              disableOrigin,
                            ),
                            formContext.createInput(
                              taxClassification,
                              'taxClassification',
                              `${I18n.t('BEE710' /* Classificação Fiscal */)}:`,
                              I18n.t('BEE768' /* Informe a classificação fiscal do produto */),
                              'text',
                              true,
                              disableTaxClassification,
                            ),
                            formContext.createInput(
                              businessUnit,
                              'businessUnit',
                              `${I18n.t('BEE711' /* Unidade de Negócio */)}:`,
                              I18n.t('BEE769' /* Informe a unidade de negócio do produto */),
                              'text',
                              false,
                              disableBusinessUnit,
                            ),
                            disableConversionFactor
                              ? formContext.createInput(
                                conversionFactor,
                                'conversionFactor',
                                `${I18n.t('BEE712' /* Fator Conversão */)}:`,
                                '',
                                'text',
                                false,
                                disableConversionFactor,
                              )
                              : formContext.createNumberFormat(
                                conversionFactor,
                                'conversionFactor',
                                `${I18n.t('BEE712' /* Fator Conversão */)}:`,
                                5,
                                disableConversionFactor,
                              ),
                            disableMultipleSale
                              ? formContext.createInput(
                                multipleSale,
                                'multipleSale',
                                `${I18n.t('BEE713' /* Lote Múltiplo Venda */)}:`,
                                '',
                                'text',
                                false,
                                disableMultipleSale,
                              )
                              : formContext.createNumberFormat(
                                multipleSale,
                                'multipleSale',
                                `${I18n.t('BEE713' /* Lote Múltiplo Venda */)}:`,
                                5,
                                disableMultipleSale,
                              ),
                            formContext.createInput(
                              level1,
                              'level1',
                              `${I18n.t('BEE694', { 0: 1 } /* Nível %{0} */)}:`,
                              I18n.t('BEE770', { 0: 1 } /* Informe o nível %{0} de classificação do produto */),
                              'text',
                              false,
                              disableLevel1,
                            ),
                            formContext.createInput(
                              level2,
                              'level2',
                              `${I18n.t('BEE694', { 0: 2 } /* Nível %{0} */)}:`,
                              I18n.t('BEE770', { 0: 2 } /* Informe o nível %{0} de classificação do produto */),
                              'text',
                              false,
                              disableLevel2,
                            ),
                            formContext.createInput(
                              level3,
                              'level3',
                              `${I18n.t('BEE694', { 0: 3 } /* Nível %{0} */)}:`,
                              I18n.t('BEE770', { 0: 3 } /* Informe o nível %{0} de classificação do produto */),
                              'text',
                              false,
                              disableLevel3,
                            ),
                            formContext.createInput(
                              level4,
                              'level4',
                              `${I18n.t('BEE694', { 0: 4 } /* Nível %{0} */)}:`,
                              I18n.t('BEE770', { 0: 4 } /* Informe o nível %{0} de classificação do produto */),
                              'text',
                              false,
                              disableLevel4,
                            ),
                            formContext.createInput(
                              level5,
                              'level5',
                              `${I18n.t('BEE694', { 0: 5 } /* Nível %{0} */)}:`,
                              I18n.t('BEE770', { 0: 5 } /* Informe o nível %{0} de classificação do produto */),
                              'text',
                              false,
                              disableLevel5,
                            ),
                          ])}
                        />
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </PanelBody>
              <PanelFooter>
                <Link to={{ pathname: ROUTES.PRODUCT_LIST, state: { dice: this.props.location.dice } }}>
                  {(showCancel
                    ? (
                      <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                        {I18n.t('BEE99' /* Cancelar */)}
                      </OneClickButton>
                    )
                    : (
                      <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                        {I18n.t('BEE137' /* Voltar */)}
                      </OneClickButton>
                    ))}
                </Link>
                {(showCreate
                    && (
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-primary p-5 m-5"
                        onClick={() => this.createProductSubmit()}
                      >
                        {I18n.t('BEE138' /* Criar */)}

                      </OneClickButton>
                    )
                  )}
                {(showUpdate
                    && (
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-primary p-5 m-5"
                        onClick={() => this.updateProductValid()}
                      >
                        {I18n.t('BEE139' /* Atualizar */)}

                      </OneClickButton>
                    )
                  )}
              </PanelFooter>
            </Panel>
            {
              (showChangeStockControl
                && (
                  <SweetAlert
                    danger
                    showCancel
                    cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
                    confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={I18n.t('BEE101' /* Você tem certeza? */)}
                    onConfirm={() => this.updateProductSubmit()}
                    onCancel={() => this.setState({ showChangeStockControl: false })}
                  >
                    {I18n.t('BEE4154' /* Deseja alterar o tipo de controle de estoque do produto? */)}
                  </SweetAlert>
                )
              )
            }
            {/* </form> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stockControlType: state.app.permissionsCompany && state.app.permissionsCompany.stockControlType,
});

const mapDispatchToProps = (dispatch) => ({
  getProduct: (productId) => dispatch(getProduct(productId)),
  createProduct: (product) => dispatch(createProduct(product)),
  updateProduct: (product) => dispatch(updateProduct(product)),
  getUnitsMeasureOptions: () => dispatch(getUnitsMeasureOptions()),
  getMaterialsFamilyOptions: () => dispatch(getMaterialsFamilyOptions()),
  getProductGroupingOptions: () => dispatch(getProductGroupingOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductForm));

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';

import OrderTable from './orderTable';
import ProductTable from './productTable';
import LotTable from './lotTable';

import {
  getListInboundOrdersOnly, undoInboundOrderCheck, undoInboundOrderProductCheck,
  assignCheckUser, getBranchUsers, updateNote, updateProductInspection, updateOrderNote, cancelOrder,
  getListInboundOrderProducts, getListInboundOrderProductLots, getListInboundOrderProductPartial,
} from '../../../app/store/actions/inboundOrders';

import { getUserPrinterOptions } from '../../../app/store/actions/printers';
import { printPreConferenceLabel } from '../../../app/store/actions/labels';
import { syncStorage } from '../../../app/store/actions/inboundStorage';

import addNotification from '../../../components/notification';

import WikiHelp from '../../../components/tooltip/wikiHelp';
import ROUTES from '../../../config/routes';

class InboundOrdersPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inboundOrderSelect: {},
      productSelect: {},
      productLotsPanel: [],
      inboundOrdersProductsPanel: [],
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
    };
  }

  componentDidMount() {
    this.getInboundOrders();
  }

  keepStatusOfSelected = async () => {
    const { inboundOrderSelect, productSelect } = this.state;
    if (Object.keys(inboundOrderSelect).length && this.props.inboundOrdersPanel.find(
      (elem) => elem.id === inboundOrderSelect.id,
    )) {
      const newInboundOrderSelect = this.props.inboundOrdersPanel.find((elem) => elem.id === inboundOrderSelect.id);
      await this.setInboundOrderSelect(newInboundOrderSelect, false);

      if (Object.keys(productSelect).length && this.state.inboundOrdersProductsPanel.find(
        (elem) => elem.id === productSelect.id,
      )) {
        const newInboundOrdersProductsPanel = this.state.inboundOrdersProductsPanel.find(
          (elem) => elem.id === productSelect.id,
        );
        await this.setInboundOrderProductSelect(newInboundOrdersProductsPanel, false);
        this.setState({
          inboundOrderSelect: newInboundOrderSelect,
          productSelect: newInboundOrdersProductsPanel,
        });
      } else {
        this.setState({
          inboundOrderSelect: newInboundOrderSelect,
          productSelect: {},
          productLotsPanel: [],
        });
      }
    } else {
      this.setState({
        inboundOrderSelect: {},
        productSelect: {},
        productLotsPanel: [],
        inboundOrdersProductsPanel: [],
      });
    }
  };

  getInboundOrders = async () => {
    const { startDate, endDate } = this.state;

    await this.props.getListInboundOrdersOnly(startDate, endDate);
    await this.keepStatusOfSelected();
  };

  handleDateApplyEvent = async (event) => {
    const startDate = event[0];
    const endDate = event[1];

    await this.setState({
      startDate,
      endDate,
    });

    this.getInboundOrders();
  };

  setInboundOrderSelect = async (inboundOrderSelect, reset = true) => {
    if (!reset || !Object.keys(this.state.inboundOrderSelect).length
    || inboundOrderSelect.id !== this.state.inboundOrderSelect.id) {
      const result = await this.props.getListInboundOrderProducts(inboundOrderSelect.id);
      const resetProduct = reset ? {
        productSelect: {},
        productLotsPanel: [],
      } : {};

      this.setState({
        inboundOrderSelect,
        inboundOrdersProductsPanel: result || [],
        ...resetProduct,
      });
    }
  };

  setInboundOrderProductSelect = async (productSelect, reset = true) => {
    if (!reset || !Object.keys(this.state.productSelect).length || productSelect.id !== this.state.productSelect.id) {
      const result = await this.props.getListInboundOrderProductLots(productSelect.id);
      this.setState({
        productSelect,
        productLotsPanel: result || [],
      });
    }
  };

  cancelOrder = async (inboundOrderId, cancelNote) => {
    try {
      const result = await this.props.cancelOrder(inboundOrderId, cancelNote);

      if (result && result.error) {
        addNotification('danger', I18n.t('BEE440' /* Monitor de Recebimento */), result.error, 'top-right');
      } else {
        addNotification(
          'success',
          I18n.t('BEE440' /* Monitor de Recebimento */),
          'Documento cancelado com sucesso!',
          'top-right',
        );
        await this.getInboundOrders();
        // const orderSelect = this.props.inboundOrdersPanel.find(order => order.id === inboundOrderId)
        // await this.setInboundOrderSelect(orderSelect)
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE440' /* Monitor de Recebimento */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE440' /* Monitor de Recebimento */),
            'Erro ao cancelar documento',
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE440' /* Monitor de Recebimento */),
          'Erro ao cancelar documento',
          'top-right',
        );
      }
    }
  };

  getListInboundOrderProductPartial = async () => {
    const { productSelect } = this.state;

    try {
      const inboundOrderProductPartial = await this.props.getListInboundOrderProductPartial(productSelect.id);
      if (inboundOrderProductPartial) {
        return inboundOrderProductPartial;
      }
      return [];
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification('danger', I18n.t('BEE440' /* Monitor de Recebimento */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE440' /* Monitor de Recebimento */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  undoInboundOrderCheck = async (inboundOrderId) => {
    try {
      const resultado = await this.props.undoInboundOrderCheck(inboundOrderId);

      if (resultado.error) {
        addNotification(
          'danger',
          I18n.t('BEE440' /* Monitor de Recebimento */),
          I18n.t('BEE1062', { 0: resultado.error } /* Houve um erro ao cancelar a conferência. %{0} */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE440' /* Monitor de Recebimento */),
          I18n.t('BEE1064' /* Conferência dos produtos cancelada com sucesso */),
          'top-right',
        );
        await this.getInboundOrders();
        // const orderSelect = this.props.inboundOrdersPanel.find(order => order.id === inboundOrderId)
        // await this.setInboundOrderSelect(orderSelect)
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE440' /* Monitor de Recebimento */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE440' /* Monitor de Recebimento */),
            I18n.t('BEE1062' /* Houve um erro ao cancelar a conferência. %{0} */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE440' /* Monitor de Recebimento */),
          I18n.t('BEE1062' /* Houve um erro ao cancelar a conferência. %{0} */),
          'top-right',
        );
      }
    }
  };

  undoInboundOrderProductCheck = async (inboundOrderProductId) => {
    try {
      const orderStatus = this.state.inboundOrderSelect.status;
      if (orderStatus > 6) {
        addNotification(
          'danger',
          I18n.t('BEE440' /* Monitor de Recebimento */),
          I18n.t('BEE1063' /* O pedido deste produto já teve a armazenagem iniciada */),
          'top-right',
        );
        return;
      }

      const resultado = await this.props.undoInboundOrderProductCheck(inboundOrderProductId);

      if (resultado.error) {
        addNotification(
          'danger',
          I18n.t('BEE440' /* Monitor de Recebimento */),
          I18n.t('BEE1062', { 0: resultado.error } /* Houve um erro ao cancelar a conferência. %{0} */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE440' /* Monitor de Recebimento */),
          I18n.t('BEE1064' /* Conferência dos produtos cancelada com sucesso */),
          'top-right',
        );
        await this.getInboundOrders();
        // const orderSelect = this.props.inboundOrdersPanel.find(order => order.id === inboundOrderId)
        // await this.setInboundOrderSelect(orderSelect)
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE440' /* Monitor de Recebimento */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE440' /* Monitor de Recebimento */),
            I18n.t('BEE1062' /* Houve um erro ao cancelar a conferência. %{0} */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE440' /* Monitor de Recebimento */),
          I18n.t('BEE1062' /* Houve um erro ao cancelar a conferência. %{0} */),
          'top-right',
        );
      }
    }
  };

  assignCheckUser = async (inboundOrderId, userId) => {
    try {
      const result = await this.props.assignCheckUser(inboundOrderId, userId);
      if (result) {
        addNotification(
          'success',
          I18n.t('BEE440' /* Monitor de Recebimento */),
          I18n.t('BEE1065' /* Conferente atribuído com sucesso ! */),
          'top-right',
        );
        await this.getInboundOrders();
        // const orderSelect = this.props.inboundOrdersPanel.find(order => order.id === inboundOrderId)
        // await this.setInboundOrderSelect(orderSelect)
      } else {
        addNotification(
          'danger',
          I18n.t('BEE440' /* Monitor de Recebimento */),
          I18n.t('BEE1066' /* Houve um erro ao fazer a atribuição */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE440' /* Monitor de Recebimento */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE440' /* Monitor de Recebimento */),
            I18n.t('BEE1067' /* Houve um erro ao atribuir o conferente %{0} */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE440' /* Monitor de Recebimento */),
          I18n.t('BEE1067' /* Houve um erro ao atribuir o conferente %{0} */),
          'top-right',
        );
      }
    }
  };

  render() {
    const { inboundOrdersPanel = [] } = this.props;

    const {
      startDate, endDate, currentRange, inboundOrdersProductsPanel = [],
      inboundOrderSelect, productSelect, productLotsPanel = [],
    } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE36' /* Recebimento */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE440' /* Monitor de Recebimento */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {I18n.t('BEE440' /* Monitor de Recebimento */)}
          </h1>
          <WikiHelp wikiPath={ROUTES.INBOUND_ORDER_MONITOR_HELP} />
        </div>
        <OrderTable
          getUserPrinterOptions={() => this.props.getUserPrinterOptions()}
          updateNote={(inboundOrderId, note) => this.props.updateOrderNote(inboundOrderId, note)}
          printLabel={(
            preConferenceLabelId,
            printerCode,
          ) => this.props.printPreConferenceLabel(preConferenceLabelId, printerCode)}
          getInboundOrders={this.getInboundOrders}
          setInboundOrderSelect={this.setInboundOrderSelect}
          inboundOrdersPanel={inboundOrdersPanel}
          handleDateApplyEvent={this.handleDateApplyEvent}
          startDate={startDate}
          endDate={endDate}
          currentRange={currentRange}
          inboundOrderSelect={inboundOrderSelect}
          undoInboundOrderCheck={this.undoInboundOrderCheck}
          assignCheckUser={this.assignCheckUser}
          getBranchUsers={this.props.getBranchUsers}
          cancelOrder={this.cancelOrder}
          getListInboundOrderProducts={this.props.getListInboundOrderProducts}
        />
        <ProductTable
          getInboundOrders={this.getInboundOrders}
          updateNote={(inboundOrderProductId, note) => this.props.updateNote(inboundOrderProductId, note)}
          updateProductInspection={(inboundOrderProductId, status, inspectionNotes) => this.props.updateProductInspection(inboundOrderProductId, status, inspectionNotes)}
          inboundOrdersProductsPanel={inboundOrdersProductsPanel}
          productSelect={productSelect}
          setInboundOrderProductSelect={this.setInboundOrderProductSelect}
          undoInboundOrderProductCheck={this.undoInboundOrderProductCheck}
          syncStorage={this.props.syncStorage}
          getListInboundOrderProductPartial={this.getListInboundOrderProductPartial}
        />
        <LotTable
          productLotsPanel={productLotsPanel}
          productSelect={productSelect}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  inboundOrdersPanel: state.inboundOrders && state.inboundOrders.inboundOrdersList,
});

const mapDispatchToProps = (dispatch) => ({
  getListInboundOrdersOnly: (startDate, endDate) => dispatch(getListInboundOrdersOnly(startDate, endDate)),
  undoInboundOrderCheck: (inboundOrderId) => dispatch(undoInboundOrderCheck(inboundOrderId)),
  undoInboundOrderProductCheck: (inboundOrderProductId) => dispatch(
    undoInboundOrderProductCheck(inboundOrderProductId),
  ),
  getBranchUsers: (branchCode) => dispatch(getBranchUsers(branchCode)),
  assignCheckUser: (inboundOrderId, userId) => dispatch(assignCheckUser(inboundOrderId, userId)),
  updateNote: (inboundOrderProductId, note) => dispatch(updateNote(inboundOrderProductId, note)),
  updateProductInspection: (inboundOrderProductId, status, inspectionNotes) => dispatch(updateProductInspection(inboundOrderProductId, status, inspectionNotes)),
  updateOrderNote: (inboundOrderId, note) => dispatch(updateOrderNote(inboundOrderId, note)),
  syncStorage: (inboundOrderProductId) => dispatch(syncStorage(inboundOrderProductId)),
  getUserPrinterOptions: () => dispatch(getUserPrinterOptions()),
  printPreConferenceLabel: (preConferenceLabelId, printerCode) => dispatch(
    printPreConferenceLabel(preConferenceLabelId, printerCode),
  ),
  cancelOrder: (inboundOrderId, cancelNote) => dispatch(cancelOrder(inboundOrderId, cancelNote)),
  getListInboundOrderProducts: (inboundOrderId) => dispatch(getListInboundOrderProducts(inboundOrderId)),
  getListInboundOrderProductLots: (inboundOrderProductId) => dispatch(
    getListInboundOrderProductLots(inboundOrderProductId),
  ),
  getListInboundOrderProductPartial: (inboundOrderProductId) => dispatch(
    getListInboundOrderProductPartial(inboundOrderProductId),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InboundOrdersPanel));

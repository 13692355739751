import moment from 'moment';

import RestService from '../services/rest';

// GET
async function getAllInboundOrders(startDate, endDate) {
  const sDate = moment(startDate).format();
  const eDate = moment(endDate).format();
  return RestService.getAuthenticated(
    `inboundOrders/list/all?startDate=${sDate}&endDate=${eDate}`,
  );
}

async function getInboundOrder(inboundOrderId) {
  return RestService.getAuthenticated(`inboundOrders/detail?inboundOrderId=${inboundOrderId}`);
}

async function getInboundOrdersDashboardInfo(
  startDate,
  endDate,
  getCharts = '',
  selectedBranches,
  currentRange,
  orderTypesToSearch,
  suppliersToSearch,
) {
  const sDate = moment(startDate).format();
  const eDate = moment(endDate).format();
  return RestService.getAuthenticated(
    'inboundOrders/list/dashboardInfo',
    { startDate: sDate, endDate: eDate, getCharts },
    undefined,
    {
      params: {
        selectedBranches,
        orderTypesToSearch,
        suppliersToSearch,
      },
    },
  );
}

async function getInboundOrdersDashboardTotal(
  startDate,
  endDate,
  selectedBranches,
  orderTypesToSearch,
  suppliersToSearch,
) {
  const sDate = moment(startDate).format();
  const eDate = moment(endDate).format();
  return RestService.getAuthenticated(
    'inboundOrders/list/dashboard/receiptOverview',
    {},
    {},
    {
      params: {
        startDate: sDate,
        endDate: eDate,
        selectedBranches,
        orderTypesToSearch,
        suppliersToSearch,
      },
    },
  );
}

async function getInboundOrdersAverageTimes(
  startDate,
  endDate,
  selectedBranches,
  orderTypesToSearch,
  suppliersToSearch,
) {
  const sDate = moment(startDate).format();
  const eDate = moment(endDate).format();
  return RestService.getAuthenticated(
    'inboundOrders/list/dashboard/AverageTimes',
    {},
    {},
    {
      params: {
        startDate: sDate,
        endDate: eDate,
        selectedBranches,
        orderTypesToSearch,
        suppliersToSearch,
      },
    },
  );
}

async function getInboundOrdersCheckPlate(licensePlate) {
  return RestService.getAuthenticated(`inboundPlateCheck/checkPlate/list?licensePlate=${licensePlate}`);
}

async function getInboundOrdersPanel(startDate, endDate) {
  const sDate = moment(startDate).format();
  const eDate = moment(endDate).format();
  return RestService.getAuthenticated(
    `inboundOrders/panel/list?startDate=${sDate}&endDate=${eDate}`,
  );
}

async function getListInboundOrdersOnly(startDate, endDate) {
  const sDate = moment(startDate).format();
  const eDate = moment(endDate).format();
  return RestService.getAuthenticated(
    `inboundOrders/list/inboundOrdersOnly?startDate=${sDate}&endDate=${eDate}`,
  );
}

async function getListInboundOrderProducts(inboundOrderId) {
  return RestService.getAuthenticated(`inboundOrders/list/inboundOrderProducts?inboundOrderId=${inboundOrderId}`);
}

async function getListInboundOrderProductLots(inboundOrderProductId) {
  return RestService.getAuthenticated(
    `inboundOrders/list/inboundOrderProductLots?inboundOrderProductId=${inboundOrderProductId}`,
  );
}

async function getListInboundOrderProductPartial(inboundOrderProductId) {
  return RestService.getAuthenticated(
    `inboundOrders/list/inboundOrderProductPartial?inboundOrderProductId=${inboundOrderProductId}`,
  );
}

async function listingByDateInboundOrderProducts(filterStartDate, filterEndDate) {
  return RestService.getAuthenticated(
    `inboundOrders/listingByDateInboundOrderProducts?filterStartDate=${filterStartDate}&filterEndDate=${filterEndDate}`,
  );
}

async function getBranchUsers(branchCode) {
  return RestService.getAuthenticated(`inboundOrders/getBranchUsers?branchCode=${branchCode}`);
}

async function getBaptismLabelProducts(baptismLabel) {
  return RestService.getAuthenticated(`inboundOrders/getBaptismLabelProducts?baptismLabel=${baptismLabel}`);
}

// PUT
async function confirmCheckPlate(inboundPlateCheckId, volumesChecked) {
  return RestService.putAuthenticated(
    `inboundPlateCheck/checkPlate/confirm?inboundPlateCheckId=${inboundPlateCheckId}&volumesChecked=${volumesChecked}`,
  );
}

async function assignCheckUser(inboundOrderId, userId) {
  return RestService.putAuthenticated('inboundOrders/panel/assignCheckUser', { inboundOrderId, userId });
}

async function updateNote(inboundOrderProductId, note) {
  return RestService.putAuthenticated(
    `inboundOrders/updateNote?inboundOrderProductId=${inboundOrderProductId}&note=${note}`,
  );
}

async function updateProductInspection(inboundOrderProductId, status, inspectionNotes) {
  return RestService.putAuthenticated('inboundOrders/updateProductInspection', { inboundOrderProductId, status, inspectionNotes });
}

async function updateOrderNote(inboundOrderId, note) {
  return RestService.putAuthenticated(`inboundOrders/updateOrderNote?inboundOrderId=${inboundOrderId}&note=${note}`);
}

async function cancelOrder(inboundOrderId, cancelNote) {
  return RestService.putAuthenticated(`inboundOrders/cancelOrder?inboundOrderId=${inboundOrderId}&note=${cancelNote}`);
}

// DELETE
async function undoInboundOrderCheck(inboundOrderId) {
  return RestService.deleteAuthenticated('inboundOrders/undoCheck/order', { inboundOrderId });
}

async function undoInboundOrderProductCheck(inboundOrderProductId) {
  return RestService.deleteAuthenticated('inboundOrders/undoCheck/product', { inboundOrderProductId });
}

export default {
  getAllInboundOrders,
  getInboundOrder,
  getInboundOrdersCheckPlate,
  getBaptismLabelProducts,
  confirmCheckPlate,
  getInboundOrdersPanel,
  undoInboundOrderCheck,
  undoInboundOrderProductCheck,
  assignCheckUser,
  getBranchUsers,
  updateNote,
  updateOrderNote,
  updateProductInspection,
  cancelOrder,
  listingByDateInboundOrderProducts,
  getListInboundOrdersOnly,
  getListInboundOrderProducts,
  getListInboundOrderProductLots,
  getListInboundOrderProductPartial,
  getInboundOrdersDashboardInfo,
  getInboundOrdersDashboardTotal,
  getInboundOrdersAverageTimes,
};

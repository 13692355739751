import RestService from '../services/rest';

// GET
async function getOrderTypeConfigs() {
  return RestService.getAuthenticated('orderTypeConfig/list/all');
}

async function getOptionsForm() {
  return RestService.getAuthenticated('orderTypeConfig/list/options');
}

// POST
async function createOrderTypeConfig(orderTypeConfig) {
  return RestService.postAuthenticated('orderTypeConfig/create', orderTypeConfig);
}

async function deleteOrderTypeConfig(configId) {
  return RestService.postAuthenticated('orderTypeConfig/delete', { configId });
}

async function bulkImportConfigs(jsonCsv) {
  return RestService.postAuthenticated('orderTypeConfig/bulkImport', { jsonCsv });
}

// PUT
async function updateStatusConfig(configId, status) {
  return RestService.putAuthenticated('orderTypeConfig/update/status', { configId, status });
}

export default {
  createOrderTypeConfig,
  deleteOrderTypeConfig,
  getOrderTypeConfigs,
  bulkImportConfigs,
  getOptionsForm,
  updateStatusConfig,
};

import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import {
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';

import getAddressByCep from '../../../../app/services/viaCep';
import {
  createCompany, getCompany, updateCompany,
} from '../../../../app/store/actions/companies';
import OneClickButton from '../../../../components/form/button';
import Form from '../../../../components/form/form';
import addNotification from '../../../../components/notification';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../../components/panel/panel';
import ROUTES from '../../../../config/routes';

class CompanyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      mode: 'detail',
      code: '',
      corporateName: '',
      companyName: '',
      email: '',
      phone: '',
      cnpj: '',
      stateRegistration: '',
      note: '',
      postalCode: '',
      ibge: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
      country: '',
      logoCompany: '',
      logoCompany64: '',
      logoCompanyLabel: '',
      stockControlType: 0,
      passwordHistoryLimit: 0,
      disablePasswordHistoryLimit: true,
      daysOfPasswordValidity: 30,
      disableCode: true,
      disableCorporateName: true,
      disableCompanyName: true,
      disableEmail: true,
      disablePhone: true,
      disableCNPJ: true,
      disableStateRegistration: true,
      disableNote: true,
      disablePostalCode: true,
      disableStreet: true,
      disableNumber: true,
      disableComplement: true,
      disableDistrict: true,
      disableCity: true,
      disableState: true,
      disableCountry: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      fractionalQuantity: false,
      formIntegrations: false,
      disableFractionalQuantity: true,
      disableFormIntegrations: true,
      quantityAttempt: 0,
      daysWithoutUsing: 0,
      disableDaysWithoutUsing: true,
      disableQuantityAttempt: true,
      disableLogoCompanyLabel: true,
      disableStockControlType: true,
      disableLogoCompany: true,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getCompanySelected();
    }
  }

  getCompanySelected = async () => {
    if (this.props.location.state.company) {
      const { company } = this.props.location.state;

      const companySelected = await this.props.getCompany(company.id);

      if (companySelected) {
        this.setState({
          // companySelected,
          companyId: companySelected.id,
          code: companySelected.code,
          corporateName: companySelected.corporateName,
          companyName: companySelected.companyName,
          email: companySelected.email,
          phone: companySelected.phone,
          cnpj: companySelected.cnpj,
          stateRegistration: companySelected.stateRegistration,
          note: companySelected.note,
          postalCode: companySelected.postalCode,
          ibge: companySelected.ibge,
          street: companySelected.street,
          number: companySelected.number,
          complement: companySelected.complement,
          district: companySelected.district,
          city: companySelected.city,
          state: companySelected.state,
          country: companySelected.country,
          fractionalQuantity: companySelected.fractionalQuantity === 1,
          formIntegrations: companySelected.formIntegrations === 1,
          daysOfPasswordValidity: companySelected.daysOfPasswordValidity,
          daysWithoutUsing: companySelected.daysWithoutUsing,
          quantityAttempt: companySelected.quantityAttempt,
          passwordHistoryLimit: companySelected.passwordHistoryLimit,
          logoCompany64: companySelected.logoCompany,
          logoCompanyLabel: companySelected.logoCompanyLabel,
          stockControlType: companySelected.stockControlType || 0,
        });
      }
    }
  };

  setMode = () => {
    let mode;

    if (this.props.isCreate) {
      mode = 'create';
    } else if (this.props.isCopy) {
      mode = 'copy';
    } else if (this.props.isEdit) {
      mode = 'edit';
    } else if (this.props.isDetail) {
      mode = 'detail';
    }

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableCorporateName: false,
        disableCompanyName: false,
        disableEmail: false,
        disablePhone: false,
        disableCNPJ: false,
        disableStateRegistration: false,
        disableNote: false,
        disablePostalCode: false,
        disableStreet: false,
        disableNumber: false,
        disableComplement: false,
        disableDaysWithoutUsing: false,
        disableQuantityAttempt: false,
        disablePasswordHistoryLimit: false,
        disableDistrict: false,
        disableCity: false,
        disableState: false,
        disableCountry: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
        disableFractionalQuantity: false,
        disableFormIntegrations: false,
        disableLogoCompanyLabel: false,
        disableLogoCompany: false,
        disableStockControlType: false,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableCorporateName: false,
        disableCompanyName: false,
        disableEmail: false,
        disablePhone: false,
        disableCNPJ: false,
        disableStateRegistration: false,
        disableNote: false,
        disablePostalCode: false,
        disableStreet: false,
        disableNumber: false,
        disableComplement: false,
        disableDistrict: false,
        disableCity: false,
        disableState: false,
        disableCountry: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
        disableFractionalQuantity: false,
        disableFormIntegrations: false,
        disableDaysWithoutUsing: false,
        disableQuantityAttempt: false,
        disablePasswordHistoryLimit: false,
        disableLogoCompanyLabel: false,
        disableLogoCompany: false,
        disableStockControlType: false,
      });
    }
  };

  setValue = async (attr, value) => {
    if (attr === 'postalCode') {
      const newPostalCode = value;
      const { postalCode } = this.state;

      if (newPostalCode !== postalCode) {
        await this.setState({
          [`${attr}`]: value,
        });

        const re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;

        if (re.test(newPostalCode)) {
          await this.searchViaCep(newPostalCode);
        } else {
          await this.cleanAddress();
        }
      }
    } else if (attr === 'daysOfPasswordValidity') {
      let newValue = 0;
      if (value < 30) {
        newValue = 30;
      } else if (`${value}`.length > 3) {
        newValue = `${`${value}`.slice(0, 3)}`;
      } else {
        newValue = value;
      }

      await this.setState({
        [`${attr}`]: newValue,
      });
    } else if (attr === 'logoCompany') {
      const reader = new FileReader();
      const file = value.files[0];

      if (file && file.type === 'image/png') {
        reader.onloadend = async () => {
          await this.setState({
            logoCompany64: reader.result,
          });
        };

        reader.readAsDataURL(file);
      }
    } else {
      await this.setState({
        [`${attr}`]: value,
      });
    }
  };

  cleanAddress = async () => {
    await this.setState({
      ibge: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
      country: '',
    });
  };

  searchViaCep = async (postalCode) => {
    const keepOnlyNumber = (value) => value.replace(/\D/g, '');
    const address = await getAddressByCep(keepOnlyNumber(postalCode));

    if (address.erro) {
      await this.cleanAddress();
    } else {
      await this.setState({
        street: address.logradouro,
        ibge: address.ibge,
        number: '',
        complement: '',
        district: address.bairro,
        city: address.localidade,
        state: address.uf,
        country: I18n.t('BEE103' /* Brasil */),
      });
    }
  };

  createCompanySubmit = async () => {
    const {
      code, corporateName, companyName, email, phone, cnpj, logoCompany64, logoCompanyLabel,
      stateRegistration, note, postalCode, ibge, street, fractionalQuantity, formIntegrations,
      number, complement, district, city, state, country, daysOfPasswordValidity,
      daysWithoutUsing, quantityAttempt, passwordHistoryLimit, stockControlType,
    } = this.state;

    try {
      const newCompany = await this.props.createCompany({
        code,
        corporateName,
        companyName,
        email,
        phone: phone ? phone.replace(/\D/g, '') : '',
        cnpj: cnpj ? cnpj.replace(/\D/g, '') : '',
        stateRegistration,
        note,
        postalCode: postalCode ? postalCode.replace(/\D/g, '') : '',
        ibge,
        street,
        number,
        complement,
        district,
        city,
        state,
        country,
        fractionalQuantity,
        formIntegrations,
        daysOfPasswordValidity,
        daysWithoutUsing,
        quantityAttempt,
        passwordHistoryLimit,
        logoCompany: logoCompany64,
        logoCompanyLabel,
        stockControlType,
      });

      if (newCompany) {
        if (newCompany.success && newCompany.success === false) {
          await addNotification(
            'danger',
            I18n.t('BEE104' /* Nova Empresa */),
            I18n.t(
              'BEE105',
              { 0: code }, /* Erro ao incluir a Empresa %{0}! */
            ),
            'top-right',
          );
        } else {
          await addNotification(
            'success',
            I18n.t('BEE104' /* Nova Empresa */),
            I18n.t(
              'BEE106',
              { 0: code }, /* Nova Empresa %{0} incluída com sucesso! */
            ),
            'top-right',
          );

          this.props.history.push(ROUTES.COMPANY_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE104' /* Nova Empresa */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE104' /* Nova Empresa */),
            I18n.t('BEE105', { 0: code } /* Erro ao incluir a Empresa %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE104' /* Nova Empresa */),
          I18n.t('BEE105', { 0: code } /* Erro ao incluir a Empresa %{0}! */),
          'top-right',
        );
      }
    }
  };

  updateCompanySubmit = async () => {
    const {
      corporateName, companyName, email, phone, cnpj, logoCompany64,
      stateRegistration, note, postalCode, ibge, street, logoCompanyLabel,
      number, complement, district, city, state, country,
      companyId, fractionalQuantity, formIntegrations, daysOfPasswordValidity,
      daysWithoutUsing, quantityAttempt, passwordHistoryLimit, stockControlType,
    } = this.state;

    try {
      const updCompany = await this.props.updateCompany({
        companyId,
        corporateName,
        companyName,
        email,
        phone: phone ? phone.replace(/\D/g, '') : '',
        cnpj: cnpj ? cnpj.replace(/\D/g, '') : '',
        stateRegistration,
        note,
        postalCode: postalCode ? postalCode.replace(/\D/g, '') : '',
        ibge,
        street,
        number,
        complement,
        district,
        city,
        state,
        country,
        fractionalQuantity,
        formIntegrations,
        daysOfPasswordValidity,
        daysWithoutUsing,
        quantityAttempt,
        passwordHistoryLimit,
        logoCompany: logoCompany64,
        logoCompanyLabel,
        stockControlType,
      });

      if (updCompany) {
        if (updCompany.success && updCompany.success === false) {
          await addNotification(
            'danger',
            I18n.t(
              'BEE107', /* Atualizar Empresa */
            ),
            I18n.t('BEE108', { 0: companyName } /* Erro ao atualizar a Empresa %{0}! */),
            'top-right',
          );
        } else {
          await addNotification(
            'success',
            I18n.t('BEE107' /* Atualizar Empresa */),
            I18n.t('BEE109', { 0: companyName } /* Atualização do Empresa %{0} efetuada com sucesso! */),
            'top-right',
          );

          this.props.history.push(ROUTES.COMPANY_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE107' /* Atualizar Empresa */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE107' /* Atualizar Empresa */),
            I18n.t('BEE108', { 0: companyName } /* Erro ao atualizar a Empresa %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE107' /* Atualizar Empresa */),
          I18n.t('BEE108', { 0: companyName } /* Erro ao atualizar a Empresa %{0}! */),
          'top-right',
        );
      }
      await addNotification(
        'danger',
        I18n.t('BEE107' /* Atualizar Empresa */),
        I18n.t('BEE108', { 0: companyName } /* Erro ao atualizar a Empresa %{0}! */),
        'top-right',
      );
    }
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      code, corporateName, companyName, email, phone, cnpj,
      stateRegistration, note, postalCode, street, fractionalQuantity, formIntegrations,
      number, complement, district, city, state, country, daysOfPasswordValidity,
      daysWithoutUsing, quantityAttempt, passwordHistoryLimit, stockControlType,
    } = this.state;

    const {
      mode, disableCode, disableCorporateName, disableCompanyName, logoCompany, disableLogoCompany,
      disableEmail, disablePhone, disableCNPJ, disableStateRegistration, disableLogoCompanyLabel,
      disableNote, disablePostalCode, disableStreet, disableNumber, logoCompany64, disableStockControlType,
      disableComplement, disableDistrict, disableCity, disableState, logoCompanyLabel,
      disableCountry, showCreate, showUpdate, showCancel, disableFractionalQuantity, disableFormIntegrations,
      disableDaysWithoutUsing, disableQuantityAttempt, disablePasswordHistoryLimit,
    } = this.state;

    let title = '';
    let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE104' /* Nova Empresa */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE110' /* Editar Empresa */);
      breadActive = `${code} - ${companyName}`;
    } else if (mode === 'detail') {
      title = I18n.t('BEE111' /* Detalhes Empresa */);
      breadActive = `${code} - ${companyName}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE15' /* Empresas */)}</li>
            <li className="breadcrumb-item active">{breadActive}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <Panel>
              <PanelHeader noButton />
              <PanelBody>
                <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab === '1' ? 'active' : ''}
                      onClick={() => {
                        this.toggleTab('1');
                      }}
                    >
                      <span className="d-sm-block d-none">{I18n.t('BEE370' /* Dados */)}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab === '2' ? 'active' : ''}
                      onClick={() => {
                        this.toggleTab('2');
                      }}
                    >
                      <span className="d-sm-block d-none">{I18n.t('BEE439' /* Parâmetros */)}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab === '3' ? 'active' : ''}
                      onClick={() => {
                        this.toggleTab('3');
                      }}
                    >
                      <span className="d-sm-block d-none">{I18n.t('BEE2555' /* Parâmetros de Segurança */)}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab === '4' ? 'active' : ''}
                      onClick={() => {
                        this.toggleTab('4');
                      }}
                    >
                      <span className="d-sm-block d-none">{I18n.t('BEE4032' /* Logotipo */)}</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Form
                      noPanel
                      setValue={this.setValue}
                      setValueDrop={this.setValueDrop}
                      inputs={(formContext) => ([
                        formContext.createInput(
                          code,
                          'code',
                          `${I18n.t('BEE82' /* Código */)}:`,
                          I18n.t('BEE3663' /* Informe o código da empresa */),
                          'text',
                          true,
                          disableCode,
                        ),
                        formContext.createInput(
                          corporateName,
                          'corporateName',
                          `${I18n.t('BEE84' /* Razão Social */)}:`,
                          I18n.t('BEE3666' /* Informe a razão social */),
                          'text',
                          true,
                          disableCorporateName,
                        ),
                        formContext.createInput(
                          companyName,
                          'companyName',
                          `${I18n.t('BEE83' /* Nome */)}:`,
                          I18n.t('BEE3667' /* Informe o nome da empresa */),
                          'text',
                          true,
                          disableCompanyName,
                        ),
                        formContext.createInputMask(
                          cnpj,
                          'cnpj',
                          `${I18n.t('BEE85' /* CNPJ */)}:`,
                          I18n.t('BEE3668' /* Informe o CNPJ */),
                          '99.999.999/9999-99',
                          true,
                          disableCNPJ,
                        ),
                        formContext.createInput(
                          stateRegistration,
                          'stateRegistration',
                          `${I18n.t('BEE117' /* Inscrição Estadual */)}:`,
                          I18n.t('BEE3669' /* Informe a inscrição estadual */),
                          'text',
                          true,
                          disableStateRegistration,
                        ),
                        formContext.createInputMask(
                          postalCode,
                          'postalCode',
                          `${I18n.t('BEE119' /* CEP */)}:`,
                          I18n.t('BEE3670' /* Informe o CEP */),
                          '99999-999',
                          true,
                          disablePostalCode,
                        ),
                        formContext.createInput(
                          street,
                          'street',
                          `${I18n.t('BEE121' /* Rua */)}:`,
                          I18n.t('BEE122' /* Informe a rua */),
                          'text',
                          true,
                          disableStreet,
                        ),
                        formContext.createInput(
                          number,
                          'number',
                          `${I18n.t('BEE123' /* Número */)}:`,
                          I18n.t('BEE124' /* Informe o número */),
                          'text',
                          false,
                          disableNumber,
                        ),
                        formContext.createInput(
                          complement,
                          'complement',
                          `${I18n.t('BEE125' /* Complemento */)}:`,
                          I18n.t('BEE126' /* Informe o complemento */),
                          'text',
                          false,
                          disableComplement,
                        ),
                        formContext.createInput(
                          district,
                          'district',
                          `${I18n.t('BEE127' /* Bairro */)}:`,
                          I18n.t('BEE128' /* Informe o bairro */),
                          'text',
                          true,
                          disableDistrict,
                        ),
                        formContext.createInput(
                          city,
                          'city',
                          `${I18n.t('BEE53' /* Cidade */)}:`,
                          I18n.t('BEE129' /* Informe a cidade */),
                          'text',
                          true,
                          disableCity,
                        ),
                        formContext.createInput(
                          state,
                          'state',
                          `${I18n.t('BEE87' /* Estado */)}:`,
                          I18n.t('BEE130' /* Informe o estado */),
                          'text',
                          true,
                          disableState,
                        ),
                        formContext.createInput(
                          country,
                          'country',
                          `${I18n.t('BEE88' /* País */)}:`,
                          I18n.t('BEE131' /* Informe o país */),
                          'text',
                          true,
                          disableCountry,
                        ),
                        formContext.createInput(
                          email,
                          'email',
                          `${I18n.t('BEE86' /* E-mail */)}:`,
                          I18n.t('BEE898' /* Informe o e-mail */),
                          'text',
                          false,
                          disableEmail,
                        ),
                        formContext.createInputMask(
                          phone,
                          'phone',
                          `${I18n.t('BEE133' /* Telefone */)}:`,
                          I18n.t('BEE134' /* Informe o telefone */),
                          phone && phone.length < 11 ? '(99) 9999-9999' : '(99) 99999-9999',
                          false,
                          disablePhone,
                        ),
                        formContext.createInput(
                          note,
                          'note',
                          `${I18n.t('BEE135' /* Observação */)}:`,
                          I18n.t('BEE136' /* Informe a observação */),
                          'text',
                          false,
                          disableNote,
                        ),
                      ])}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <Form
                      noPanel
                      setValue={this.setValue}
                      inputs={(formContext) => ([
                        formContext.createRadioButtons(
                          [{
                            label: I18n.t('BEE172' /* Sim */),
                            value: true,
                            disabled: disableFractionalQuantity,
                          }, {
                            label: I18n.t('BEE173' /* Não */),
                            value: false,
                            disabled: disableFractionalQuantity,
                          }],
                          fractionalQuantity,
                          'fractionalQuantity',
                          `${I18n.t('BEE2365' /* Quantidade Fracionada */)}:`,
                          undefined,
                          undefined,
                          'col-md-0',
                        ),
                        formContext.createRadioButtons(
                          [{
                            label: I18n.t('BEE172' /* Sim */),
                            value: true,
                            disabled: disableFormIntegrations,
                          }, {
                            label: I18n.t('BEE173' /* Não */),
                            value: false,
                            disabled: disableFormIntegrations,
                          }],
                          formIntegrations,
                          'formIntegrations',
                          `${I18n.t('BEE3929' /* Integração de Cadastros */)}:`,
                          undefined,
                          undefined,
                          'col-md-0',
                        ),
                        formContext.createRadioButtons(
                          [{
                            label: 'BeeStock',
                            value: 1,
                            disabled: disableStockControlType,
                          }, {
                            label: 'ERP',
                            value: 0,
                            disabled: disableStockControlType,
                          }],
                          stockControlType,
                          'stockControlType',
                          `${I18n.t('BEE708' /* Tipo de Controle de Estoque */)}:`,
                          undefined,
                          undefined,
                          'col-md-0',
                        ),
                      ])}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <Form
                      noPanel
                      setValue={this.setValue}
                      inputs={(formContext) => ([
                        formContext.createInput(
                          daysOfPasswordValidity,
                          'daysOfPasswordValidity',
                          `${I18n.t('BEE896' /* Validade Senha */)}:`,
                          '',
                          'number',
                          false,
                          disableNumber,
                          // 4,
                        ),
                        formContext.createInput(
                          passwordHistoryLimit,
                          'passwordHistoryLimit',
                          `${I18n.t('BEE3265' /* Número de Senhas Únicas */)}:`,
                          '',
                          'number',
                          false,
                          disablePasswordHistoryLimit,
                          // 4,
                        ),
                        formContext.createInput(
                          quantityAttempt,
                          'quantityAttempt',
                          `${I18n.t('BEE3260' /* Quantidade de Tentativas */)}:`,
                          '',
                          'number',
                          false,
                          disableQuantityAttempt,
                        ),
                        formContext.createInput(
                          daysWithoutUsing,
                          'daysWithoutUsing',
                          `${I18n.t('BEE3261' /* Dias Máximos Sem Uso */)}:`,
                          '',
                          'number',
                          false,
                          disableDaysWithoutUsing,
                        ),
                      ])}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <Form
                      noPanel
                      setValue={this.setValue}
                      inputs={(formContext) => ([
                        formContext.createInput(
                          logoCompanyLabel,
                          'logoCompanyLabel',
                          `${I18n.t('BEE4040' /* Logotipo da Empresa em formato de Etiqueta */)}:`,
                          '',
                          'text',
                          false,
                          disableLogoCompanyLabel,
                        ),
                        logoCompany64
                          ? formContext.customComponent(
                            (
                              <div
                                className="mw-50 mh-50 d-flex align-items-center justify-content-center"
                              >
                                <div
                                  className="border p-3 position-relative"
                                >
                                  {!disableLogoCompany && (
                                  <i
                                    className="fa fa-times position-absolute"
                                    style={{
                                      top: 10, right: 10, fontSize: '1rem', cursor: 'pointer',
                                    }}
                                    aria-hidden="true"
                                    onClick={() => { this.setState({ logoCompany: '', logoCompany64: '' }); }}
                                  />
                                  )}
                                  <img
                                    className="mw-100 mh-100"
                                    alt={I18n.t('BEE4032' /* Logotipo */)}
                                    src={logoCompany64}
                                  />
                                </div>
                              </div>
                            ),
                          )
                          : formContext.createInputFile(
                            logoCompany,
                            'logoCompany',
                            `${I18n.t('BEE4032' /* Logotipo */)}:`,
                            '',
                            'file',
                            false,
                            disableNumber,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            'image/png',
                          // 4,
                          ),
                      ])}
                    />
                  </TabPane>
                </TabContent>
              </PanelBody>
              <PanelFooter>
                <Link to={{ pathname: ROUTES.COMPANY_LIST, state: { dice: this.props.location.dice } }}>
                  {(showCancel
                    ? (
                      <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                        {I18n.t('BEE99' /* Cancelar */)}
                      </OneClickButton>
                    )
                    : (
                      <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                        {I18n.t('BEE137' /* Voltar */)}
                      </OneClickButton>
                    ))}
                </Link>
                {(showCreate
                  && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createCompanySubmit()}
                    >
                      {I18n.t('BEE138' /* Criar */)}

                    </OneClickButton>
                  )
                )}
                {(showUpdate
                  && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateCompanySubmit()}
                    >
                      {I18n.t('BEE139' /* Atualizar */)}

                    </OneClickButton>
                  )
                )}
              </PanelFooter>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  getCompany: (companyId) => dispatch(getCompany(companyId)),
  createCompany: (company) => dispatch(createCompany(company)),
  updateCompany: (company) => dispatch(updateCompany(company)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanyForm));
